/* open-sans-regular - cyrillic_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url("../fonts/open-sans-v15-cyrillic_latin-regular.eot"); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url("../fonts/open-sans-v15-cyrillic_latin-regular.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("../fonts/open-sans-v15-cyrillic_latin-regular.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/open-sans-v15-cyrillic_latin-regular.woff") format('woff'), /* Modern Browsers */
    url("../fonts/open-sans-v15-cyrillic_latin-regular.ttf") format('truetype'), /* Safari, Android, iOS */
    url("../fonts/open-sans-v15-cyrillic_latin-regular.svg#OpenSans") format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin_cyrillic */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: url("../fonts/open-sans-v15-latin_cyrillic-italic.eot"); /* IE9 Compat Modes */
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url("../fonts/open-sans-v15-latin_cyrillic-italic.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("../fonts/open-sans-v15-latin_cyrillic-italic.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/open-sans-v15-latin_cyrillic-italic.woff") format('woff'), /* Modern Browsers */
    url("../fonts/open-sans-v15-latin_cyrillic-italic.ttf") format('truetype'), /* Safari, Android, iOS */
    url("../fonts/open-sans-v15-latin_cyrillic-italic.svg#OpenSans") format('svg'); /* Legacy iOS */
}
/* open-sans-600 - cyrillic_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: url("../fonts/open-sans-v15-cyrillic_latin-600.eot"); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url("../fonts/open-sans-v15-cyrillic_latin-600.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("../fonts/open-sans-v15-cyrillic_latin-600.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/open-sans-v15-cyrillic_latin-600.woff") format('woff'), /* Modern Browsers */
    url("../fonts/open-sans-v15-cyrillic_latin-600.ttf") format('truetype'), /* Safari, Android, iOS */
    url("../fonts/open-sans-v15-cyrillic_latin-600.svg#OpenSans") format('svg'); /* Legacy iOS */
}
/* open-sans-700 - cyrillic_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url("../fonts/open-sans-v15-cyrillic_latin-700.eot"); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url("../fonts/open-sans-v15-cyrillic_latin-700.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
    url("../fonts/open-sans-v15-cyrillic_latin-700.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/open-sans-v15-cyrillic_latin-700.woff") format('woff'), /* Modern Browsers */
    url("../fonts/open-sans-v15-cyrillic_latin-700.ttf") format('truetype'), /* Safari, Android, iOS */
    url("../fonts/open-sans-v15-cyrillic_latin-700.svg#OpenSans") format('svg'); /* Legacy iOS */
}