@charset "UTF-8";
a {
  color: inherit;
  text-decoration: underline;
  outline: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

a:focus {
  outline: none;
}

a:hover {
  color: inherit;
}

.link--dotted {
  display: inline-block;
  border-bottom: 1px dotted;
  border-color: inherit;
  text-decoration: none !important;
}

img {
  max-width: 100%;
}

p {
  margin-top: 20px;
  margin-bottom: 0;
}

p:first-child {
  margin-top: 0;
}

h1, .h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: #3d5360;
  margin-top: 50px;
  margin-bottom: 0;
}

h1:first-child, .h1:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 25px;
  }
}

h1 + p, .h1 + p {
  margin-top: 30px;
}

h2, .h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: #3d5360;
  margin-top: 50px;
  margin-bottom: 0;
}

h2:first-child, .h2:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  h2, .h2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  h2, .h2 {
    font-size: 20px;
  }
}

h3, .h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #3d5360;
  margin-top: 20px;
  margin-bottom: 0;
}

h3:first-child, .h3:first-child {
  margin-top: 0;
}

h4, .h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #3d5360;
  margin-top: 50px;
  margin-bottom: 0;
}

h4:first-child, .h4:first-child {
  margin-top: 0;
}

.summary {
  font-size: 13px;
  line-height: 1.33;
  margin-top: 30px;
}

.summary:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .summary {
    font-size: 11px;
  }
}

.summary--small {
  font-size: 12px;
}

.summary--larger {
  font-size: 15px;
  line-height: 1.5;
}

@media (max-width: 767px) {
  .summary--larger {
    font-size: 12px;
  }
}

.summary--large {
  font-size: 18px;
  line-height: 1.33;
}

@media (max-width: 767px) {
  .summary--large {
    font-size: 14px;
  }
}

.icon {
  position: relative;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  letter-spacing: 0;
  line-height: 1;
}

.icon-plus {
  display: inline-block;
  line-height: 1;
  font-size: 20px;
  font-weight: 400;
}

.icon--camera {
  vertical-align: middle;
  top: -1px;
  font-size: 16px;
  margin-left: 4px;
}

.icon--camera:first-child {
  margin-left: 0;
}

.icon-btn-plus-minus {
  display: inline-block;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
}

.icon--chevron-left,
.icon--chevron-right {
  width: 0.55556em;
}

/*

Column Layout

*/
.cols {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cols--auto {
  table-layout: auto;
}

.cols__inner {
  display: table-row;
}

.col {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px;
}

.col:first-child {
  padding-left: 0;
}

.col:last-child {
  padding-right: 0;
}

.cols--compact .col {
  padding-left: 5px;
  padding-right: 5px;
}

.cols--compact .col:first-child {
  padding-left: 0;
}

.cols--compact .col:last-child {
  padding-right: 0;
}

.col--fit {
  width: 1px;
}

.col--1-4 {
  width: 25%;
}

.col--3-4 {
  width: 75%;
}

.col--1-3 {
  width: 33.33%;
}

.col--2-3 {
  width: 66.66%;
}

.col--1-2 {
  width: 50%;
}

.cols--floats {
  display: block;
  width: auto;
  margin-left: -10px;
  margin-right: -10px;
}

.cols--floats:after {
  content: "";
  display: table;
  clear: both;
}

.cols--floats > .cols__inner {
  display: block;
  float: left;
  width: 100%;
  margin-top: -10px;
  margin-bottom: -10px;
}

.cols--floats > .cols__inner > .col {
  float: left;
  display: block;
  padding: 10px;
}

.cols--floats > .cols__inner > .col--right {
  float: right;
}

.cols--inverse {
  direction: rtl;
}

.cols--inverse .col {
  direction: ltr;
  padding-left: 10px;
  padding-right: 10px;
}

.cols--inverse .col:first-child {
  padding-right: 0;
}

.cols--inverse .col:last-child {
  padding-left: 0;
}

/*

Expansible content

 */
.expand-it-container {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.expand-it-container.overflow-visible {
  overflow: visible;
}

.expand-it-container.active {
  max-height: 9999px;
}

/*

Form's layout

*/
.form {
  position: relative;
  margin-top: 20px;
}

.form:after {
  content: "";
  display: table;
  clear: both;
}

.form:first-child {
  margin-top: 0;
}

.form__items {
  margin-top: 35px;
}

.form__items:after {
  content: "";
  display: table;
  clear: both;
}

.form__items:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .form__items {
    margin-top: 25px;
  }
}

.form__item {
  position: relative;
  margin-top: 10px;
}

.form__item:after {
  content: "";
  display: table;
  clear: both;
}

.form__item:first-child {
  margin-top: 0;
}

.form__item-label {
  display: block;
  font-size: 12px;
  line-height: 1.33;
  color: #3d5360;
  letter-spacing: 0.1em;
  margin-top: 10px;
}

.form__item-label:first-child {
  margin-top: 0;
}

.col .form__item-label {
  padding-top: 4px;
}

.col .form__item-label--upper {
  padding-top: 0;
  margin-top: -4px;
}

.form__item-tip {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.form__item-tip:first-child {
  margin-top: 0;
}

.form__item-field {
  position: relative;
  margin-top: 8px;
}

.form__item-field:first-child {
  margin-top: 0;
}

.form__item--inline {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  margin-right: 10px;
}

.form__item--inline:first-child {
  margin-top: 10px;
}

.form__actions {
  position: relative;
  margin-top: 25px;
}

.form__actions:first-child {
  margin-top: 0;
}

.form__tip {
  margin: 8px 0 -5px;
}

.form__tip:first-child {
  margin-top: 0;
}

.form__error {
  padding: 2px 15px 0;
  color: #ff3006;
  font-size: 11px;
  font-weight: 400;
  -webkit-animation: 0.5s ease-out;
          animation: 0.5s ease-out;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.form__item-field--error-absolute .form__error {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}

.form__error.active {
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

@-webkit-keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

/*

Textfields

*/
.form-control {
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #3d5360 !important;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  height: 40px;
  padding: 9px 12px;
  text-decoration: none !important;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
  border-color: #acacac;
  overflow: auto;
}

.form-control[readonly] {
  background-color: #fff;
}

.form-control[disabled] {
  color: rgba(61, 83, 96, 0.5) !important;
}

.form-control::-webkit-input-placeholder {
  color: #3d5360;
}

.form-control::-moz-placeholder {
  color: #3d5360;
}

.form-control:-moz-placeholder {
  color: #3d5360;
}

.form-control:-ms-input-placeholder {
  color: #3d5360;
}

.form-control--w-arrow {
  padding-right: 30px;
}

textarea.form-control {
  resize: vertical;
  white-space: normal;
  overflow: auto;
  height: auto;
}

.form-control-arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  height: 9px;
  line-height: 9px;
  pointer-events: none;
  color: #b4b4b4;
  font-size: 9px;
}

.form-control-arrow--double {
  top: 0;
  bottom: 0;
  height: auto;
}

.form-control-arrow--double .icon {
  position: absolute;
  top: 9px;
  right: 0;
}

.form-control-arrow--double .icon + .icon {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 9px;
}

/*

Buttons

*/
.btn {
  position: relative;
  display: inline-block;
  outline: none !important;
  text-decoration: none !important;
  text-align: center;
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.42857143;
  letter-spacing: 0.01em;
  border: 1px solid;
  padding: 10px 15px;
  vertical-align: middle;
  white-space: nowrap;
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background: none;
  color: #fff !important;
  border-color: transparent !important;
  background-color: #3d5360 !important;
  -webkit-appearance: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.btn::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.btn:hover {
  color: #fff !important;
  background-color: #465c6a !important;
}

.btn:active {
  background-color: #465c6a !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn[disabled], .btn.disabled {
  background-color: rgba(61, 83, 96, 0.5);
  color: rgba(255, 255, 255, 0.5) !important;
  border-color: inherit;
  border-color: currentColor;
  opacity: 1;
  cursor: default;
  cursor: not-allowed;
}

.btn + .btn {
  margin-left: 15px;
}

.btn > input[type='file'] {
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 120px;
  position: absolute;
  top: -20px;
  right: -10px;
  vertical-align: top;
  z-index: 3;
  cursor: pointer;
}

.btn--file {
  overflow: hidden;
}

.btn--font-small {
  font-size: 12px;
}

.btn--small {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  height: 30px;
}

.btn--outline {
  background-color: transparent !important;
  border-color: #3d5360 !important;
  color: #3d5360 !important;
}

.btn--outline:hover {
  background-color: #3d5360 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.btn--outline:active {
  background-color: #3d5360 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.btn--outline[disabled], .btn--outline.disabled {
  background-color: transparent !important;
  border-color: rgba(61, 83, 96, 0.5) !important;
  color: rgba(61, 83, 96, 0.5) !important;
}

.btn--pink {
  background-color: #ef8fce !important;
}

.btn--pink:hover {
  background-color: #e57ec1 !important;
}

.btn--pink:active {
  background-color: #e57ec1 !important;
}

.btn--pseudo {
  background-color: transparent !important;
  color: #3d5360 !important;
  font-weight: 600;
  letter-spacing: 0.1em;
  cursor: inherit;
}

.btn--pseudo:hover {
  background-color: transparent !important;
  color: #3d5360 !important;
}

.btn--pseudo:active {
  background-color: transparent !important;
  color: #3d5360 !important;
}

.btn--link {
  background-color: transparent !important;
  color: #3d5360 !important;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.btn--link:hover {
  background-color: #f4f4f4 !important;
  color: #2f2f2f !important;
}

.btn--link:active {
  background-color: #f4f4f4 !important;
  color: #2f2f2f !important;
}

.btn--white {
  background-color: #fff !important;
  color: #3d5360 !important;
}

.btn--white:hover {
  background-color: #f4f4f4 !important;
  color: #2f2f2f !important;
}

.btn--white:active {
  background-color: #f4f4f4 !important;
  color: #2f2f2f !important;
}

.btn--white-outline {
  background-color: transparent !important;
  border-color: #fff !important;
}

.btn--white-outline:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: #fff !important;
}

.btn--white-outline:active {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: #fff !important;
}

.btn--grey {
  background-color: #f4f4f4 !important;
  color: #3d5360 !important;
}

.btn--grey:hover {
  background-color: #eee !important;
  color: #3d5360 !important;
}

.btn--grey:active {
  background-color: #eee !important;
  color: #3d5360 !important;
}

.btn--grey[disabled], .btn--grey.disabled {
  background-color: #f4f4f4 !important;
  color: rgba(61, 83, 96, 0.5) !important;
}

.btn--grey-dark {
  background-color: #e8e8e8 !important;
  color: #2f2f2f !important;
}

.btn--grey-dark:hover {
  background-color: #ddd !important;
  color: #2f2f2f !important;
}

.btn--grey-dark:active {
  background-color: #e8e8e8 !important;
  color: #2f2f2f !important;
}

.btn--grey-dark[disabled], .btn--grey-dark.disabled {
  background-color: #e8e8e8 !important;
  color: rgba(47, 47, 47, 0.5) !important;
}

.btn--grey-bordered {
  border-color: #d3d3d3 !important;
}

.btn--grey-bordered:hover {
  border-color: #acacac !important;
}

.btn--grey-bordered:active {
  border-color: #acacac !important;
}

.btn--grey-bordered[disabled], .btn--grey-bordered.disabled {
  border-color: #d3d3d3 !important;
}

.btn--hover-dark:hover {
  color: #fff !important;
  background-color: #465c6a !important;
}

.btn--hover-dark:active {
  background-color: #465c6a !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn--fullwidth {
  width: 100%;
}

.btn--w-icon {
  width: 47px;
}

/*

Selectboxes

*/
.selectbox-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  text-align: left;
}

.selectbox-wrapper:first-child {
  margin-left: 0;
}

select.selectbox {
  vertical-align: top;
  height: 40px;
  line-height: 38px;
  padding: 0 8px;
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.1em;
  border: 1px solid #d3d3d3;
  background: #fff;
  color: #3d5360;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
}

select.selectbox ::-webkit-scrollbar {
  display: none;
}

select.selectbox:hover {
  border-color: #d3d3d3;
}

select.selectbox.error {
  border-color: #e00;
}

select.selectbox.error + .select2-container .select2-selection {
  border-color: #e00;
}

select.selectbox[disabled] {
  background-color: #ebebeb;
  border-color: transparent;
}

select.selectbox option {
  padding: 8px 0;
}

select.selectbox--inline {
  width: auto;
}

select.selectbox--inline-width-auto + .select2-container {
  width: auto !important;
}

select.hidden + .select2-container {
  display: none;
}

select.selectbox--fullwidth + .select2 {
  width: 100% !important;
}

.select2-container--default {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-align: left;
}

.select2.select2-container--default {
  max-width: 100%;
}

.select2-container--default .select2-selection {
  border: 1px solid #d3d3d3;
  border-radius: 4px !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
}

.select2-container--default .select2-selection:hover {
  border-color: #d3d3d3;
}

.select2-container--focus .select2-selection,
.select2-container--open .select2-selection,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #d3d3d3;
  border-radius: 4px !important;
}

.select2-container--open.select2-container--above .select2-selection {
  border-radius: 0 0 4px 4px !important;
}

.select2-container--open .select2-selection {
  border-radius: 4px 4px 0 0 !important;
}

.select2-container--disabled .select2-selection {
  border-color: transparent;
  background-color: #ebebeb;
  cursor: default !important;
  cursor: not-allowed !important;
}

.select2-container--disabled .select2-selection:hover {
  border-color: transparent;
}

.select2-container .select2-selection {
  min-height: 40px;
  height: auto;
  outline: none;
}

.select2-container--default .select2-selection .select2-selection__rendered {
  color: #3d5360;
  line-height: 38px;
  letter-spacing: 0.1em;
}

.select2-container .select2-selection .select2-selection__rendered {
  padding-left: 12px;
  padding-right: 20px;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding-left: 10px;
  padding-bottom: 6px;
}

.select2-container--default .select2-selection .select2-selection__placeholder {
  color: #3d5360;
  visibility: hidden;
  display: none;
}

.select2-container--default .select2-selection--multiple {
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  text-indent: 0;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li:before {
  display: none;
}

.select2-placeholder {
  position: absolute;
  top: 11px;
  left: 9px;
  padding-right: 20px;
  max-width: 100%;
  color: #3d5360;
  line-height: 16px;
  letter-spacing: 0.1em;
  pointer-events: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  display: none;
}

.select2-placeholder__inner {
  position: relative;
  display: inline-block;
  padding: 0 3px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  z-index: 0;
}

.select2-placeholder__inner:before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: -1;
  bottom: 0;
  margin-top: -1px;
  background-color: transparent;
  border-radius: 0 0 3px 3px;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.select2-container--placeholder-top .select2-placeholder,
.select2-container--filled .select2-placeholder {
  /*top: -9px;
    font-size: 12px;
    color: $color2;*/
  display: none;
}

.textfield-placeholder ~ .select2-container--filled .select2-placeholder {
  opacity: 0;
}

.select2-container--disabled .select2-placeholder {
  color: #888;
}

.select2-container--placeholder-top .select2-placeholder__inner:before,
.select2-container--filled .select2-placeholder__inner:before {
  background-color: #fff;
}

/*
.select2-container--placeholder-always .select2-placeholder {
    opacity: 1!important;
}*/
.select2-container--default .select2-selection {
  position: relative;
}

.select2-container--default .select2-selection .select2-selection__arrow {
  position: absolute;
  top: 17px;
  right: 15px;
  line-height: 1;
  font-size: 9px;
  width: auto;
  height: auto;
  color: #b4b4b4;
}

.select2-container--focus .select2-selection .select2-selection__arrow,
.select2-container--open .select2-selection .select2-selection__arrow {
  color: #b4b4b4;
}

.select2-container--disabled .select2-selection {
  border-color: transparent;
  background-color: #ebebeb;
  cursor: default;
  cursor: not-allowed;
}

.select2-container--disabled .select2-selection:hover {
  border-color: transparent;
}

.select2-container--disabled .select2-selection .select2-selection__arrow:before, .select2-container--disabled .select2-selection .select2-selection__arrow:after {
  background-color: #c3c3c3 !important;
}

.select2-container--disabled .select2-placeholder__inner:before {
  background-color: transparent;
}

.select2-container--default .select2-selection .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-selection .select2-selection__clear {
  position: absolute;
  top: -1px;
  right: 10px;
  z-index: 1;
  width: 42px;
  height: 36px;
  margin: 0;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.select2-container--default .select2-selection .select2-selection__clear:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../img/icon-cross.png") center 12px no-repeat;
  opacity: 0.5;
  content: '';
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.select2-container--default .select2-selection .select2-selection__clear:hover:before {
  opacity: 1;
}

/*
.select2-container--default .select2-selection.select2-selection--with-clear .select2-selection__arrow{
    display: none;
}*/
.select2-container--default .select2-selection.select2-selection--with-clear .select2-selection__rendered {
  padding-right: 45px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  position: relative;
  background-color: rgba(70, 92, 106, 0.15);
  border-color: transparent;
  border-radius: 4px;
  white-space: nowrap;
  height: 26px;
  line-height: 25px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0 32px 0 9px;
  overflow: hidden;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 26px;
  margin: 0;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /*
    &:after {
        @include position(absolute, -500px -500px -500px -500px);
        content: '';
    }*/
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../img/icon-cross.png") 6px 6px no-repeat;
  opacity: 0.5;
  content: '';
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover:before {
  opacity: 1;
}

.select2-container .select2-selection--multiple.select2-selection--notags {
  cursor: pointer;
}

.select2-container .select2-selection--multiple.select2-selection--notags .select2-selection__rendered {
  display: block;
  padding-left: 18px;
  padding-bottom: 0;
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice {
  background-color: transparent;
  white-space: nowrap;
  float: none;
  height: auto;
  line-height: 1.2;
  margin-right: 0;
  border: none;
  margin-top: 0;
  padding: 0;
  overflow: hidden;
  display: inline;
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice:before {
  display: none;
  background: none;
  position: static;
  margin: 0;
  padding: 0;
  border-radius: 0;
  content: ",\00a0";
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice + .select2-selection__choice:before {
  display: inline;
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}

.select2-search--inline {
  display: none;
}

.select2-dropdown {
  border: 1px solid #d3d3d3;
  border-radius: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: 1px;
  /*
    &:before {
        @include position(absolute, null 12px 0 12px);
        border-top: 1px solid #ebebeb;
        content: '';
        z-index: 1;
    }*/
}

.select2-container--open .select2-dropdown--above {
  border-bottom: 1px solid #d3d3d3;
}

.select2-container--open .select2-dropdown--below {
  border-top: 1px solid #d3d3d3;
}

.select2-dropdown--below {
  margin-top: -1px;
  /*
    &:before {
        top: 0;
        bottom: auto;
    }*/
}

.select2-search--dropdown {
  padding: 15px 15px 0;
}

.select2-search--dropdown .select2-search__field {
  padding: 7px 12px;
  border-color: #d3d3d3;
  padding-right: 35px;
}

.select2-search--dropdown .select2-search__field:focus::-webkit-input-placeholder {
  opacity: 0;
}

.select2-search--dropdown .select2-search__field:focus::-moz-placeholder {
  opacity: 0;
}

.select2-search--dropdown .select2-search__field:focus:-moz-placeholder {
  opacity: 0;
}

.select2-search--dropdown .select2-search__field:focus:-ms-input-placeholder {
  opacity: 0;
}

.select2-container--default .select2-results {
  padding: 0;
}

.select2-container--default .select2-results__group {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  background-attachment: local;
  padding: 7px 12px;
}

.select2-results {
  /*.select2-results__options--scrollbar {
        padding-right: 5px;
        margin-right: -10px;
        margin-top: -5px;
        margin-bottom: -5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }*/
}

.select2-results ::-webkit-scrollbar:vertical {
  width: 5px;
}

.select2-results ::-webkit-scrollbar-track {
  border: none;
}

.select2-results ::-webkit-scrollbar-thumb {
  border: none;
}

.select2-container--default .select2-results > .select2-results__options {
  overflow-x: hidden;
}

.select2-container--default .select2-results__option {
  padding: 7px 12px;
  line-height: 1.2;
  text-indent: 0;
  margin-top: 0;
  -webkit-transition: background-color 0.2s, color 0.2s;
  -o-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
}

.select2-container--default .select2-results__option:before {
  display: none;
}

.select2-container--default .select2-results__option.select2-results__message {
  cursor: default;
  background-color: rgba(196, 201, 204, 0.25);
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #888;
  cursor: default;
  cursor: not-allowed;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(196, 201, 204, 0.25);
  color: #000;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(70, 92, 106, 0.15);
}

.select2-container--default [aria-multiselectable="true"] .select2-results__option[aria-selected=true] {
  position: absolute;
  left: -9999px;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option {
  padding-left: 35px;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option:before {
  display: block;
  position: absolute;
  top: 11px;
  left: 11px;
  width: 11px;
  height: 8px;
  border-radius: 0;
  background: url("../img/checkbox-tick.svg") center center no-repeat;
  background-size: contain;
  content: '';
  visibility: hidden;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__message {
  padding-left: 20px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected=true] {
  background-color: rgba(70, 92, 106, 0.15);
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option[aria-selected=true] {
  position: relative;
  left: auto;
  background-color: transparent;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option[aria-selected=true]:before {
  visibility: visible;
}

.select2-container--default .select2-dropdown--notags .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(70, 92, 106, 0.15);
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option--highlighted[aria-selected=true] {
  background-color: rgba(70, 92, 106, 0.15);
}

select.selectbox--small {
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}

.selectbox--small + .select2-container {
  font-size: 13px;
}

.selectbox--small + .select2-container .select2-selection {
  min-height: 28px;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__rendered {
  line-height: 25px;
  padding-top: 1px;
}

.selectbox--small + .select2-container--default .select2-placeholder {
  top: 6px;
}

.selectbox--small + .select2-container--placeholder-top .select2-placeholder,
.selectbox--small + .select2-container--filled .select2-placeholder {
  top: -8px;
  font-size: 11px;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__arrow {
  top: 8px;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__clear {
  height: 28px;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__clear:before {
  background-position: center 8px;
}

.select2-container--tags-destination .select2-selection__arrow {
  display: none;
}

.select2-container--tags-destination .select2-selection {
  cursor: default;
  border-color: #c3c3c3 !important;
}

.select2-container--tags-destination .select2-selection:hover {
  border-color: #c3c3c3 !important;
}

.select2-container--tags-destination .select2-selection .select2-selection__rendered {
  padding-right: 10px;
}

.select2-container--tags-destination + .select2-container--tags-destination {
  display: none !important;
}

.selectbox-tags__source {
  position: relative;
  margin-bottom: 20px;
}

.selectbox-tags__source .select2-container + .select2-container {
  position: relative !important;
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  margin-top: 10px;
}

.selectbox-tags__source .select2-container + .select2-container .select2-dropdown {
  position: relative !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  z-index: auto;
}

body > .select2-container--in-fancybox,
.wrap > .select2-container--in-fancybox {
  z-index: 99995;
}

.select2-container--dropdown {
  z-index: 1051;
}

.select2-container--in-fancybox {
  max-width: 410px;
}

.select2-container--dropdown-nowrap .select2-results__option {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}

.form-control--input-picker {
  max-width: none !important;
}

.inputpicker-div {
  overflow: hidden !important;
  width: 100% !important;
}

.inputpicker-div:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.inputpicker-div input {
  pointer-events: none;
}

.inputpicker-div .form-control {
  border-color: #d3d3d3;
  max-width: none !important;
}

.inputpicker-arrow {
  position: absolute;
  top: 17px;
  right: 15px;
  line-height: 1;
  font-size: 9px;
  width: auto;
  height: auto;
  color: #b4b4b4;
}

.inputpicker-arrow:before {
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  z-index: 1;
  content: '';
}

.inputpicker-wrapped-list {
  outline: none;
  min-height: 200px !important;
  max-height: 200px !important;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  margin-top: -1px;
}

.inputpicker-wrapped-list .table > tbody > tr > td, .inputpicker-wrapped-list .table > tbody > tr > th, .inputpicker-wrapped-list .table > tfoot > tr > td, .inputpicker-wrapped-list .table > tfoot > tr > th, .inputpicker-wrapped-list .table > thead > tr > td, .inputpicker-wrapped-list .table > thead > tr > th {
  border-top: none;
}

.inputpicker-overflow-hidden {
  overflow: visible !important;
}

.inputpicker-overflow-hidden + .inputpicker-wrapped-list {
  top: 100% !important;
  left: 0 !important;
}

.no-touchevents .inputpicker-wrapped-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.no-touchevents .inputpicker-wrapped-list::-webkit-scrollbar:vertical {
  width: 4px;
}

.no-touchevents .inputpicker-wrapped-list::-webkit-scrollbar:horizontal {
  height: 4px;
}

.no-touchevents .inputpicker-wrapped-list::-webkit-scrollbar-thumb {
  background-color: rgba(47, 47, 47, 0.1) !important;
  border-radius: 100px;
  border: none;
}

.no-touchevents .inputpicker-wrapped-list::-webkit-scrollbar-track {
  border-radius: 100px;
  margin: 4px 0;
  border: none;
  background-color: rgba(47, 47, 47, 0.05) !important;
}

.inputpicker-element {
  cursor: pointer;
}

.inputpicker-wrapped-list .inputpicker-active {
  background-color: rgba(196, 201, 204, 0.25) !important;
  color: #000;
}

.inputpicker-wrapped-list .table {
  border: none;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #3d5360;
  table-layout: fixed;
}

.inputpicker-wrapped-list .table--head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
}

.inputpicker-wrapped-list .table--head + .table {
  margin-top: -1px;
}

.inputpicker-wrapped-list .table thead {
  font-size: 12px;
  letter-spacing: 0.01em;
}

.inputpicker-wrapped-list .table thead th {
  border-bottom: none;
}

.inputpicker-wrapped-list .table thead th:first-child,
.inputpicker-wrapped-list .table td:first-child {
  width: 55%;
}

.inputpicker-wrapped-list .table > tbody > tr > td, .inputpicker-wrapped-list .table > tbody > tr > th, .inputpicker-wrapped-list .table > tfoot > tr > td, .inputpicker-wrapped-list .table > tfoot > tr > th, .inputpicker-wrapped-list .table > thead > tr > td, .inputpicker-wrapped-list .table > thead > tr > th {
  padding: 7px 12px;
  line-height: 1.2;
}

/*

Timer picker

*/
.time-picker {
  /*
    &::-moz-selection { background: #fff; color: $color2; text-shadow: none; }
    &::selection { background: #fff; color: $color2; text-shadow: none; }*/
}

.time-picker[readonly] {
  cursor: pointer;
}

.ui-timepicker-list li {
  padding: 6px 12px;
  letter-spacing: 0.1em;
  font-size: 13px;
}

li.ui-timepicker-selected, .ui-timepicker-list li:hover, .ui-timepicker-list .ui-timepicker-selected:hover {
  background-color: #3d5360;
}

.form__item .ui-timepicker-wrapper {
  width: 100%;
}

.ui-timepicker-wrapper {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.timeEntry-control {
  position: absolute;
  top: 0;
  right: 0;
}

/*

Date picker

*/
.date-picker[readonly] {
  cursor: pointer;
}

.datepick-popup {
  z-index: 100;
  margin-top: -99999px;
  opacity: 0;
  visibility: hidden;
}

.datepick-popup.active {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.datepick {
  position: relative;
  width: 100% !important;
  max-width: 280px;
  min-width: 280px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #000;
  cursor: default;
  z-index: 5;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.datepick a {
  border: none;
}

.datepick-nav {
  position: relative;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  padding: 24px 25px 6px;
}

.datepick-nav:after {
  content: "";
  display: table;
  clear: both;
}

.datepick-nav a {
  color: #000;
  text-decoration: none;
}

.datepick-nav .datepick-cmd {
  position: relative;
  top: 3px;
  float: right;
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-size: 12px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.datepick-nav .datepick-cmd:first-child {
  float: left;
}

.datepick-nav .datepick-cmd:hover, .datepick-nav .datepick-cmd:focus {
  opacity: 1;
  filter: alpha(opacity=100);
}

.datepick-nav .datepick-cmd:active {
  opacity: 1;
  filter: alpha(opacity=100);
}

.datepick-nav .datepick-cmd.datepick-disabled {
  cursor: default;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.datepick-nav .datepick-cmd:before {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: "";
}

.datepick-nav-month {
  margin-top: 15px;
}

.datepick-nav-month:first-child {
  margin-top: 0;
}

.datepick-nav-year {
  margin-top: 15px;
}

.datepick-nav-year:first-child {
  margin-top: 0;
}

.datepick-nav-month-name,
.datepick-nav-year-name {
  position: relative;
  display: inline-block;
  margin: 0 7px;
  min-width: 30px;
  font-weight: 400;
  text-align: center;
}

.datepick-month-header {
  display: none;
}

.datepick-cmd-today {
  display: none;
}

.datepick-month {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 13px;
}

.datepick-month table {
  margin: 0;
  border: none;
  width: 100%;
}

.datepick-month tr {
  background: transparent !important;
}

.datepick-month th {
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  text-transform: lowercase;
  border: none !important;
  color: #000;
  background-color: transparent !important;
  padding: 7px 0 11px;
  text-align: center;
}

.datepick-month td {
  font-size: 14px;
  font-weight: 300;
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  border: none;
  padding: 0;
  text-align: center;
}

.datepick-month td a, .datepick-month td span {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  color: #000;
}

.datepick-month td span {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default;
  cursor: not-allowed;
}

.datepick-month td a.datepick-selected {
  color: #000;
  background-color: rgba(70, 92, 106, 0.15);
}

.datepick-month td a:hover, .datepick-month td a:focus {
  color: #000;
  background-color: #f0f1f2;
}

.datepick-month td a:active {
  color: #000;
  background-color: #f0f1f2;
}

.datepick-month .datepick-dow-6,
.datepick-month .datepick-dow-0,
.datepick-month .datepick-weekend {
  color: #1c1e2d;
}

.datepick-month .datepick-today:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 6px;
  width: 6px;
  margin: 7px 0 0 -3px;
  border-radius: 50%;
  background-color: #3d5360;
  content: "";
}

.no-touchevents .datepick-month table tr:hover, .no-touchevents .datepick-month table tr.hover {
  background-color: transparent !important;
}

.datepick-ctrl {
  display: none;
}

.form__item-field .datepick-popup {
  position: absolute !important;
  top: 100% !important;
  left: 50% !important;
  margin-left: -140px;
  z-index: 5;
}

.form__item-field .datepick-popup.from-bottom {
  top: auto !important;
  bottom: 100% !important;
}

/*

Tooltips

 */
/*
.tooltip {
    cursor: default;
}

a.tooltip,
.btn.tooltip {
    cursor: pointer;
}*/
.tooltipster-default .tooltipster-content {
  position: relative;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  padding: 6px 10px;
  text-align: center;
}

.tooltipster-default .tooltipster-box {
  background-color: #3d5360;
  color: #fff;
  border-radius: 3px;
  border: 1px solid transparent;
  margin: 10px;
}

.tooltipster-default .tooltipster-box a {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.tooltipster-default .tooltipster-box a:hover {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.75);
}

.tooltipster-default .tooltipster-box p {
  margin-top: 10px;
}

.tooltipster-default .tooltipster-box p:first-child {
  margin-top: 0;
}

.tooltipster-compact .tooltipster-content {
  padding: 4px 8px;
}

.tooltipster-as-action .tooltipster-box:hover {
  background-color: #5b5b5b;
}

.tooltipster-as-action .tooltipster-box:active {
  background-color: #666;
}

.tooltipster-default.tooltipster-top .tooltipster-arrow {
  bottom: -1px;
}

.tooltipster-default.tooltipster-bottom .tooltipster-arrow {
  top: -1px;
}

.tooltipster-sidetip .tooltipster-arrow-background {
  display: none;
}

.tooltipster-default.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #3d5360;
}

.tooltipster-default .tooltipster-arrow-border {
  border-width: 9px;
}

.tooltipster-default.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -9px;
}

.tooltipster-default.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #3d5360;
}

.tooltipster-default.tooltipster-top .tooltipster-arrow-uncropped {
  top: 1px;
}

.tooltipster-default.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #3d5360;
}

.tooltipster-default.tooltipster-right .tooltipster-arrow-uncropped {
  left: -9px;
}

.tooltipster-default.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #3d5360;
}

.tooltipster-default.tooltipster-left .tooltipster-arrow-uncropped {
  left: 1px;
}

.tooltipster-header {
  font-size: 14px;
  line-height: 1.2857;
}

.touchevents .tooltipster-base.tooltipster-hide-on-mobiles {
  display: none;
}

/*

Tabs

 */
.tabs {
  position: relative;
  z-index: 0;
  margin-top: 20px;
  text-align: center;
}

.tabs:first-child {
  margin-top: 0;
}

.tabs:after {
  content: "";
  display: table;
  clear: both;
}

.tabs:before {
  position: absolute;
  top: 0;
  right: -2000px;
  bottom: 0;
  left: -2000px;
  z-index: -1;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #e2e5e7;
  content: '';
}

.tabs ul {
  display: table;
  width: 100%;
  height: 100%;
  font-size: 0;
  margin: 0;
  padding: 0;
}

.tabs li {
  position: relative;
  display: table-cell;
  vertical-align: bottom;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  margin: 0;
  padding: 0 45px;
}

@media (max-width: 1259px) {
  .tabs li {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1259px) {
  .tabs li {
    font-weight: 600;
  }
}

@media (max-width: 767px) {
  .tabs li {
    font-size: 14px;
  }
}

.tabs li.active a {
  color: #3d5360;
  cursor: default;
}

.tabs li.active a:before {
  opacity: 1;
}

.tabs li.disabled {
  cursor: default;
  cursor: not-allowed;
}

.tabs li.disabled:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  content: "";
}

.tabs li.disabled a {
  color: #ccc;
}

.tabs a {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  color: #2f2f2f;
  border: none;
  text-align: center;
  text-decoration: none;
}

@media (max-width: 767px) {
  .tabs a {
    padding: 25px 0;
  }
}

.tabs a:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  border-top: 2px solid #3d5360;
  opacity: 0;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.tabs a:hover {
  color: #3d5360;
}

.tabs__scroll {
  position: relative;
  float: left;
  width: 100%;
  font-size: 0;
  white-space: nowrap;
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.tabs__scroll-inner {
  position: relative;
  display: inline-block;
  min-width: 100%;
  vertical-align: top;
  font-size: 14px;
}

.tabs__inner {
  position: relative;
  margin: 0 -45px;
}

@media (max-width: 1259px) {
  .tabs__inner {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.tabs__inner:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(10%, #fff), color-stop(50%, rgba(255, 255, 255, 0.7)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, #fff 0%, #fff 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, #fff 0%, #fff 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, #fff 0%, #fff 10%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 100%);
  content: "";
  z-index: 2;
  pointer-events: none;
}

@media (max-width: 1023px) {
  .tabs__inner:before {
    width: 50px;
  }
}

.tabs__inner:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.7)), color-stop(90%, #fff), to(#fff));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 50%, #fff 90%, #fff 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 50%, #fff 90%, #fff 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 50%, #fff 90%, #fff 100%);
  content: "";
  z-index: 2;
  pointer-events: none;
}

@media (max-width: 1023px) {
  .tabs__inner:after {
    width: 50px;
  }
}

.tabs__inner.scroll-in-start:before {
  opacity: 0;
  visibility: hidden;
}

.tabs__inner.scroll-in-end:after {
  opacity: 0;
  visibility: hidden;
}

.tabs__arrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 15px;
  height: 36px;
  line-height: 36px;
  background: none;
  color: #3d5360;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 3;
}

.tabs__arrow:before {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: '';
}

.tabs__arrow:hover {
  color: #465c6a;
}

.tabs__arrow .icon {
  width: 15px;
  height: 27px;
  vertical-align: middle;
}

.tabs__arrow--prev {
  left: 0;
}

.scroll-in-start .tabs__arrow--prev {
  opacity: 0;
  pointer-events: none;
}

.tabs__arrow--next {
  right: 0;
}

.scroll-in-end .tabs__arrow--next {
  opacity: 0;
  pointer-events: none;
}

.touchevents .tabs__scroll {
  overflow-x: auto;
}

.touchevents .tabs__scroll::-webkit-scrollbar {
  display: none;
}

.tabs--equals ul {
  table-layout: fixed;
}

.tabs--inline ul {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: -10px;
}

.tabs--inline li {
  display: inline-block;
  margin-bottom: 10px;
}

.tabs--multiline .tabs__scroll {
  white-space: normal;
}

.tabs--flex ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.tabs-item-end {
  margin-left: auto;
}

.tabs--rounded:before {
  display: none;
}

.tabs--rounded .tabs__inner {
  margin-left: -15px;
  margin-right: -15px;
}

.tabs--rounded .tabs__arrow--prev {
  left: 15px;
}

.tabs--rounded .tabs__arrow--next {
  right: 15px;
}

@media (max-width: 767px) {
  .tabs--rounded .tabs__scroll-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.tabs--rounded li {
  padding: 0;
}

@media (max-width: 1259px) {
  .tabs--rounded li {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 767px) {
  .tabs--rounded li {
    letter-spacing: 0.01em;
    padding-left: 3px;
    padding-right: 3px;
  }
}

.tabs--rounded li.active {
  font-weight: 400;
}

.tabs--rounded li.active a {
  color: #2f2f2f;
  background-color: #f4f4f4;
}

@media (max-width: 767px) {
  .tabs--rounded li.active a {
    color: #fff !important;
    background-color: #3d5360 !important;
  }
}

.tabs--rounded a {
  padding: 15px 25px;
  color: #2f2f2f;
  background-color: transparent;
  border-radius: 15px;
}

@media (max-width: 1259px) {
  .tabs--rounded a {
    padding: 8px 15px;
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .tabs--rounded a {
    color: #2f2f2f !important;
    background-color: #f4f4f4 !important;
  }
}

.tabs--rounded a:before {
  display: none;
}

.tabs--rounded a:hover {
  color: #2f2f2f;
  background-color: #f4f4f4;
}

@media (max-width: 767px) {
  .tabs--rounded a:hover {
    color: #fff;
    background-color: #3d5360;
  }
}

.tabs--rounded a.tabs-link-more {
  color: #2f2f2f;
  background-color: transparent;
}

.tabs--rounded-grey ul {
  margin-right: -10px;
}

.tabs--rounded-grey li {
  margin-right: 10px;
}

.tabs--rounded-grey li.active a {
  color: #fff !important;
  background-color: #3d5360 !important;
}

.tabs--rounded-grey a {
  color: #2f2f2f !important;
  background-color: #f4f4f4 !important;
}

.tabs--rounded-grey a:hover {
  color: #2f2f2f !important;
  background-color: #f8f8f8 !important;
}

.tabs--oneline-shown .expand-it-container {
  max-height: 50px;
}

@media (max-width: 1259px) {
  .tabs--oneline-shown .expand-it-container {
    max-height: 32px;
  }
}

.tabs--oneline-shown .expand-it-container-height-default {
  position: absolute;
  height: 50px;
}

@media (max-width: 1259px) {
  .tabs--oneline-shown .expand-it-container-height-default {
    height: 32px;
  }
}

@media (max-width: 767px) {
  .tabs--oneline-on-mobiles .tabs-scroll-on-mobiles {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: -20px;
  }
  .tabs--oneline-on-mobiles .tabs-scroll-on-mobiles ul {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    padding-bottom: 20px;
  }
  .tabs--oneline-on-mobiles .tabs-scroll-on-mobiles li {
    display: inline-block !important;
  }
}

.tabs--ls0 li {
  letter-spacing: 0.01em;
}

.tab-panes {
  position: relative;
  margin-top: 40px;
}

.tab-panes:first-child {
  margin-top: 0;
}

.tab-pane {
  position: absolute;
  top: -9999px;
}

.tab-pane.active {
  position: relative;
  top: 0;
}

.tab-pane--smoothly {
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  -o-transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
}

.tab-pane--smoothly.active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
     transition-delay: 0s;
}

/*

Maps

 */
[class^=ymaps][class$=-copyright],
[class^=ymaps][class$=copyright_logo_no],
[class^=ymaps][class*=-copyright],
[class^=ymaps][class*=copyright_logo_no] {
  display: none;
}

[class^=ymaps][class$=map-lang-ru] {
  background: transparent;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.map-container {
  position: relative;
  margin-top: 15px;
}

.map-container:first-child {
  margin-top: 0;
}

.map-wrapper {
  position: relative;
  margin-top: 15px;
}

.map-wrapper:first-child {
  margin-top: 0;
}

.map-wrapper--rounded {
  border-radius: 5px;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.map {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 320px;
  background: #fafafa url("../img/loader.svg") center center no-repeat;
  background-size: 48px 48px;
  margin-top: 15px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.map:first-child {
  margin-top: 0;
}

.map:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  content: "";
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
  -o-transition: opacity 0.2s, visibility 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.no-svg .map {
  background-image: url("../img/loader.gif");
}

.map--rounded {
  border-radius: 5px;
  overflow: hidden;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.map--greyscale [class$='-ground-pane'] {
  -webkit-filter: grayscale(85%) hue-rotate(150deg);
  filter: grayscale(85%) hue-rotate(150deg);
}

.map--absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
}

.map--cropped {
  overflow: hidden;
}

.map [class$='-balloon'],
.map [class*='-balloon '] {
  /*transform: scale(0);
    transform-origin: 57px -11px;*/
  visibility: hidden;
}

.map [class$='-balloon'].balloon-bottom,
.map [class*='-balloon '].balloon-bottom {
  visibility: visible;
}

.map [class$='-balloon__close-button'] {
  margin-right: 0;
  height: 32px;
}

.map [class$='-balloon__content'] {
  min-height: 10px;
}

.map [class$='-balloon__content'] > * {
  height: auto !important;
}

.map [class$='-balloon__tail'] {
  position: absolute;
  bottom: 100%;
  margin-bottom: -25px;
}

.object-marker--pin {
  position: absolute;
  top: -32px;
  left: -12px;
  height: 32px;
  width: 24px;
}

.object-marker--pin:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: url("../img/marker-black.svg") center center no-repeat;
  background-size: contain;
  opacity: 0.4;
  -webkit-transform: skew(-35deg) scaleY(0.4) scaleX(0.8);
      -ms-transform: skew(-35deg) scaleY(0.4) scaleX(0.8);
          transform: skew(-35deg) scaleY(0.4) scaleX(0.8);
  -webkit-transform-origin: 50% 100%;
      -ms-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  content: '';
}

.object-marker--pin:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: url("../img/marker.svg") center center no-repeat;
  background-size: contain;
  content: '';
}

.map-balloon-content {
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1;
  max-width: 480px;
}

/*

Sliders

 */
.swiper-slider {
  position: relative;
  margin-top: 35px;
}

.swiper-slider:first-child {
  margin-top: 0;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  margin-top: -18px;
  margin-left: 0 !important;
  width: auto;
  height: 36px;
  line-height: 36px;
  background: none;
  color: #cdcdcd;
}

.swiper-button-prev:before,
.swiper-button-next:before {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: '';
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #3d5360;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.lt-ie9 .swiper-button-prev, .lt-ie9
.swiper-button-next {
  display: none;
}

.swiper-button-prev .icon,
.swiper-button-next .icon {
  width: 20px;
  height: 36px;
}

.swiper-button-prev {
  left: auto;
  right: 100%;
  margin-right: 10px !important;
}

.swiper-button-next {
  left: 100%;
  margin-left: 10px !important;
  right: auto;
}

.swiper-pagination-bullet-active {
  pointer-events: none;
  cursor: default;
}

html {
  -webkit-text-size-adjust: 100%;
  background-color: #fff;
  height: 100%;
}

body {
  position: relative;
  font-family: "Open Sans", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2857;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2f2f2f;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  height: 100%;
  background: #fff;
  min-width: 320px;
  overflow-x: auto;
}

@media (max-width: 319px) {
  body {
    overflow-x: auto;
  }
}

@media (max-width: 1259px) {
  body {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  body {
    padding-right: 0 !important;
  }
}

.notransition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.touchevents body {
  cursor: pointer;
}

.touchevents .touch-focused {
  cursor: pointer;
}

.no-touchevents .wrapall ::-webkit-scrollbar {
  -webkit-appearance: none;
}

.no-touchevents .wrapall ::-webkit-scrollbar:vertical {
  width: 4px;
}

.no-touchevents .wrapall ::-webkit-scrollbar:horizontal {
  height: 4px;
}

.no-touchevents .wrapall ::-webkit-scrollbar-thumb {
  background-color: rgba(47, 47, 47, 0.1) !important;
  border-radius: 100px;
  border: none;
}

.no-touchevents .wrapall ::-webkit-scrollbar-track {
  border-radius: 100px;
  margin: 4px 0;
  border: none;
  background-color: rgba(47, 47, 47, 0.05) !important;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.no-animation {
  -webkit-animation: none !important;
          animation: none !important;
}

.no-animation *, .no-animation *:before, .no-animation *:after {
  -webkit-animation: none !important;
          animation: none !important;
}

.no-transition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-transition *, .no-transition *:before, .no-transition *:after {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

::-moz-selection {
  background: rgba(0, 0, 0, 0.3);
  color: #000;
  text-shadow: none;
}

::selection {
  background: rgba(0, 0, 0, 0.3);
  color: #000;
  text-shadow: none;
}

/*
*
* Skeleton elements
*
*/
.wrapall {
  position: relative;
  float: left;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

@media (max-width: 1259px) {
  .wrapall {
    padding-top: 71px;
  }
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-x {
  overflow-y: hidden;
  overflow-x: auto;
}

/*

Responsive Helpers

 */
.respon-meter {
  position: absolute;
  z-index: 9999;
}

@media (max-width: 1259px) {
  .respon-meter {
    z-index: 1259;
  }
}

@media (max-width: 1023px) {
  .respon-meter {
    z-index: 1023;
  }
}

@media (max-width: 767px) {
  .respon-meter {
    z-index: 767;
  }
}

@media (max-width: 479px) {
  .respon-meter {
    z-index: 479;
  }
}

@media (max-width: 374px) {
  .respon-meter {
    z-index: 374;
  }
}

.only-for-notouch,
.only-for-touch {
  display: none !important;
}

.no-touchevents .only-for-notouch {
  display: block !important;
}

.touchevents .only-for-touch {
  display: block !important;
}

.tablet-show,
.tablet-show-inline-block,
.tablet-show-inline,
.tablet-show-table-row,
.tablet-show-table-cell {
  display: none !important;
}

@media (max-width: 1259px) {
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-show-table-row {
    display: table-row !important;
  }
  .tablet-show-table-cell {
    display: table-cell !important;
  }
}

.tablet-small-show,
.tablet-small-show-inline-block,
.tablet-small-show-inline,
.tablet-small-show-table-row,
.tablet-small-show-table-cell {
  display: none !important;
}

@media (max-width: 1023px) {
  .tablet-small-hide {
    display: none !important;
  }
  .tablet-small-show {
    display: block !important;
  }
  .tablet-small-show-inline-block {
    display: inline-block !important;
  }
  .tablet-small-show-inline {
    display: inline !important;
  }
  .tablet-small-show-table-row {
    display: table-row !important;
  }
  .tablet-small-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-show,
.mobile-show-inline-block,
.mobile-show-inline,
.mobile-show-table-cell {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-show-inline-block {
    display: inline-block !important;
  }
  .mobile-show-inline {
    display: inline !important;
  }
  .mobile-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-small-show,
.mobile-small-show-inline-block,
.mobile-small-show-inline,
.mobile-small-show-table-cell {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-small-hide {
    display: none !important;
  }
  .mobile-small-show {
    display: block !important;
  }
  .mobile-small-show-inline-block {
    display: inline-block !important;
  }
  .mobile-small-show-inline {
    display: inline !important;
  }
  .mobile-small-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-xsmall-show,
.mobile-xsmall-show-inline-block,
.mobile-xsmall-show-inline,
.mobile-xsmall-show-table-cell {
  display: none !important;
}

@media (max-width: 479px) {
  .mobile-xsmall-hide {
    display: none !important;
  }
  .mobile-xsmall-show {
    display: block !important;
  }
  .mobile-xsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xsmall-show-inline {
    display: inline !important;
  }
  .mobile-xsmall-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-xxsmall-show,
.mobile-xxsmall-show-inline-block,
.mobile-xxsmall-show-inline,
.mobile-xxsmall-show-table-cell {
  display: none !important;
}

@media (max-width: 374px) {
  .mobile-xxsmall-hide {
    display: none !important;
  }
  .mobile-xxsmall-show {
    display: block !important;
  }
  .mobile-xxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxsmall-show-table-cell {
    display: table-cell !important;
  }
}

.header-wrapper {
  position: static;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
}

@media (min-width: 1260px) {
  .header-wrapper {
    min-height: 203px;
  }
}

.header-wrapper-replace {
  display: none !important;
  min-height: 203px;
}

@media (max-width: 1259px) {
  .header-wrapper-replace {
    display: none !important;
  }
}

.topline {
  position: relative;
  z-index: 10;
  background-color: #f4f4f4;
  padding: 20px 0;
}

.topline--pink {
  background-color: #ef8fce;
  color: #fff;
}

.topline--pink a {
  color: #fff;
}

.topline--pink a:hover {
  color: #fff;
}

.topline--pink .stip {
  color: inherit !important;
}

.topline--pink .stip__icon {
  color: inherit !important;
}

@media (max-width: 767px) {
  .topline .stip__icon {
    display: none;
  }
}

@media (max-width: 767px) {
  .topline .stip__text {
    padding-left: 0;
  }
}

.topline__grid {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media (max-width: 767px) {
  .topline__grid {
    table-layout: auto;
  }
}

.topline__grid-wrapper {
  margin: 0 -5px;
}

.topline__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 5px;
  text-align: center;
}

.topline__cell:first-child {
  text-align: left;
}

.topline__cell:last-child {
  text-align: right;
}

.stip {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.5454;
  color: rgba(28, 30, 45, 0.45);
  text-decoration: none;
}

a.stip:hover {
  color: #3d5360;
  text-decoration: underline;
}

a.stip:hover .stip__icon {
  color: #3d5360;
}

.stip__icon {
  position: absolute;
  top: 8px;
  left: 0;
  width: 16px;
  font-size: 16px;
  line-height: 1;
  color: rgba(61, 83, 96, 0.3);
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.stip__icon--truck {
  top: 0;
  width: 31px;
  font-size: 26px;
}

.stip__icon--truck .icon {
  width: 31px;
}

.stip__icon--truck + .stip__text {
  padding-left: 48px;
}

.stip__text {
  padding-left: 30px;
}

.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  padding: 25px 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #e2e5e7;
  will-change: transform;
}

@media (min-width: 1260px) {
  .header {
    margin-right: 0 !important;
  }
  .header.hw-scrolled {
    position: fixed;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .header.hw-scrolled-up {
    position: fixed;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
}

@media (max-width: 1259px) {
  .header {
    position: fixed;
    padding: 0;
  }
  .header .htip__icon {
    position: relative;
    width: auto;
    line-height: 1;
  }
  .header .htip__icon .icon {
    vertical-align: top;
    top: 1px;
  }
  .header .htip__text {
    display: none;
  }
}

.header-replace {
  display: none;
  min-height: 123px;
}

@media (min-width: 1260px) {
  .hw-scrolled + .header-replace {
    display: block;
  }
  .hw-scrolled-up + .header-replace {
    display: block;
  }
}

@media (max-width: 1259px) {
  .header__offset-fixed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.header__grid {
  display: table;
}

.header__grid--fixed {
  width: 100%;
  table-layout: fixed;
}

.header__grid--inline {
  display: inline-table;
}

.header__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 25px;
  white-space: nowrap;
  text-align: center;
}

@media (max-width: 1259px) {
  .header__cell {
    height: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 374px) {
  .header__cell {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.header__cell:first-child {
  padding-left: 0;
  text-align: left;
}

.header__cell:last-child {
  padding-right: 0;
  text-align: right;
}

.header__cell--auto {
  width: auto;
}

.header__cell--logo {
  width: 200px;
}

@media (max-width: 1259px) {
  .header__cell--logo {
    width: 140px;
  }
}

.header__cell--phone {
  padding-left: 0;
}

.header__cell--phone .htip--phone {
  margin-left: -15px;
}

.header__cell--callback {
  padding: 0 5px;
  font-size: 13px;
}

.header-callback {
  letter-spacing: 0.165em;
  font-weight: 400;
}

.header-callback .header-link {
  color: #a3a3a3;
}

.msearch {
  position: fixed;
  top: 146px;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 11;
  padding: 10px 25px;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

@media (max-width: 1259px) {
  .msearch {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .msearch {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.msearch.active {
  visibility: visible;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
     transition-delay: 0s;
}

.omenu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 20;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.omenu-overlay.active {
  visibility: visible;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
     transition-delay: 0s;
}

.omenu-overlay__scroll {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 10px;
}

.omenu-overlay__close {
  position: absolute;
  top: 25px;
  right: 10px;
  font-size: 18px;
  z-index: 1;
  color: #3d5360;
}

.omenu {
  font-size: 15px;
  letter-spacing: 0.1em;
  margin: 0 -10px;
}

.omenu ul {
  padding: 0 10px;
  margin: 0;
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-top: 15px;
}

.omenu ul:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.omenu li {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
}

.omenu li:first-child {
  margin-top: 0;
}

.omenu a {
  display: inline-block;
  padding: 10px 0;
  text-decoration: none;
}

.htip {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #465c6a;
  text-decoration: none;
}

.htip__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  font-size: 16px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.htip__text {
  padding-left: 25px;
}

.htip__summary {
  font-size: 10px;
  color: #a3a3a3;
  letter-spacing: 0;
}

.htip__sticker {
  position: absolute;
  top: -8px;
  left: -8px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  font-size: 10px;
  font-weight: 700;
  background-color: #465c6a;
  color: #fff;
  border-radius: 50%;
  text-align: center;
}

@media (max-width: 1259px) {
  .htip__sticker {
    height: 17px;
    width: 17px;
    line-height: 17px;
    font-size: 12px;
  }
}

.htip__sticker.cart-counter--empty {
  display: none;
}

.htip--phone {
  font-weight: 600;
}

.htip--phone .htip__icon {
  top: 5px;
}

@media (max-width: 1259px) {
  .htip--camera .htip__icon {
    font-size: 24px;
  }
}

@media (max-width: 1259px) {
  .htip--search .htip__icon {
    font-size: 23px;
  }
}

.htip--favorites .htip__icon {
  color: #ef8fcd;
}

@media (max-width: 1259px) {
  .htip--favorites .htip__icon {
    font-size: 24px;
  }
}

.htip--favorites .htip__sticker {
  background-color: #ef8fcd;
}

.htip--cart .htip__icon {
  font-size: 18px;
}

@media (max-width: 1259px) {
  .htip--cart .htip__icon {
    font-size: 26px;
  }
}

.htip--cart .htip__icon .icon {
  top: -1px;
}

@media (max-width: 1259px) {
  .htip--cart .htip__icon .icon {
    top: 1px;
  }
}

.header-link {
  color: #3d5360;
}

.logo {
  color: #3d5360;
}

.logo .icon {
  font-size: 74px;
  width: 1.89189em;
}

@media (max-width: 1259px) {
  .logo .icon {
    font-size: 48px;
  }
}

.mtoggler {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 18px;
  margin-right: -6px;
  cursor: pointer;
}

@media (max-width: 374px) {
  .mtoggler {
    margin-right: 0;
  }
}

.mtoggler:before {
  position: absolute;
  top: -15px;
  right: -15px;
  bottom: -15px;
  left: -15px;
  content: '';
}

.mtoggler__line {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  height: 2px;
  margin-top: -1px;
  background-color: #3d5360;
}

.mtoggler__line:first-child {
  top: 0;
  margin-top: 0;
}

.mtoggler__line:last-child {
  top: auto;
  bottom: 0;
  margin-top: 0;
}

#search {
  position: relative;
  top: 3px;
}

.search-box {
  width: 185px;
  white-space: normal;
  text-align: left;
}

@media (max-width: 1259px) {
  .search-box {
    width: 100%;
  }
  .search-box:before {
    position: absolute;
    top: 0;
    right: -25px;
    bottom: 0;
    left: -25px;
    background: #fff;
    content: '';
    z-index: 2;
  }
  .search-box form {
    position: relative;
    z-index: 3;
  }
}

.search-box .input-group {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.search-box .form-control {
  width: 100%;
  font-size: 10px;
  border: none;
  border-bottom: 2px solid #465c6a;
  border-radius: 0;
  padding: 6px 40px 6px 0;
  height: 28px;
  color: #1c1e2d;
}

@media (max-width: 1259px) {
  .search-box .form-control {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.01em;
    height: 46px;
    border-color: rgba(70, 92, 106, 0.3);
  }
}

.search-box .form-control::-webkit-input-placeholder {
  color: rgba(28, 30, 45, 0.5);
}

@media (max-width: 1259px) {
  .search-box .form-control::-webkit-input-placeholder {
    color: rgba(61, 83, 96, 0.4);
  }
}

.search-box .form-control::-moz-placeholder {
  color: rgba(28, 30, 45, 0.5);
}

@media (max-width: 1259px) {
  .search-box .form-control::-moz-placeholder {
    color: rgba(61, 83, 96, 0.4);
  }
}

.search-box .form-control:-moz-placeholder {
  color: rgba(28, 30, 45, 0.5);
}

@media (max-width: 1259px) {
  .search-box .form-control:-moz-placeholder {
    color: rgba(61, 83, 96, 0.4);
  }
}

.search-box .form-control:-ms-input-placeholder {
  color: rgba(28, 30, 45, 0.5);
}

@media (max-width: 1259px) {
  .search-box .form-control:-ms-input-placeholder {
    color: rgba(61, 83, 96, 0.4);
  }
}

.search-box .input-group-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
}

.search-box .btn {
  color: #465c6a !important;
  height: 28px;
  line-height: 28px;
  padding: 0;
  min-width: 0;
  border: none !important;
  border-radius: 0;
  background: none !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

@media (max-width: 1259px) {
  .search-box .btn {
    color: #3d5360 !important;
    height: 46px;
    line-height: 46px;
  }
}

.search-box .btn .icon {
  height: 16px;
  width: 16px;
}

@media (max-width: 1259px) {
  .search-box .btn .icon {
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }
}

.searchclear {
  position: absolute;
  right: 25px;
  top: 3px;
  background: none;
  border: none;
  color: #999;
  padding: 0;
  z-index: 1000;
  outline: none;
}

@media (max-width: 1259px) {
  .searchclear {
    font-size: 18px;
    color: #3d5360;
    line-height: 1;
    right: 0;
    top: 15px;
  }
  .searchclear:before {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    z-index: -1;
    background: #fff;
    content: '';
  }
}

.searchclear:hover {
  color: #444;
}

.search-box .btn {
  margin-left: -1px;
  z-index: 2;
}

.search-box #searchButton {
  z-index: 3;
}

#search-ajax-loader {
  position: absolute;
  display: block;
  left: 100%;
  margin-left: 10px;
  background: none;
  border: none;
  color: #999;
  padding: 0;
  top: 3px;
  z-index: 10;
}

.addcontainer {
  position: absolute;
  width: 100%;
  z-index: 10;
  overflow: auto;
  background: #f4f4f4;
  font-size: 10px;
}

@media (max-width: 1259px) {
  .addcontainer {
    background: #fff;
    font-size: 12px;
    width: auto;
    left: -25px;
    right: -25px;
    padding: 0 25px;
    max-height: 70vh;
    max-height: calc(100vh - 127px);
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    z-index: 0;
  }
}

.search-block {
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #1c1e2d;
  padding: 15px;
}

@media (max-width: 1259px) {
  .search-block {
    font-size: 12px;
    letter-spacing: 0.01em;
    padding: 20px 0;
  }
}

.search-block + .search-block {
  margin-top: -15px;
}

.search-block-centered {
  display: block;
}

.search-block-header {
  color: rgba(28, 30, 45, 0.5);
  line-height: 1.2;
}

@media (max-width: 1259px) {
  .search-block-header {
    font-size: 12px;
    font-weight: 600;
    color: rgba(47, 47, 47, 0.5);
  }
}

.search-block-header:empty + .search-block-results {
  margin-top: 0;
}

.search-block-results {
  margin-top: 15px;
}

.search-block-results:first-child {
  margin-top: 0;
}

.search-block-item {
  display: table;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}

.search-block-item:first-child {
  margin-top: 0;
}

.search-block-item .apimage {
  display: table-cell;
  vertical-align: top;
  width: 30px;
  text-align: center;
}

.search-block-item .apimage + .apname {
  padding-left: 5px;
}

.apprice {
  margin-top: 8px;
  color: rgba(28, 30, 45, 0.5);
}

.apprice:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .apprice {
    margin-top: 0;
  }
}

.mmenu {
  position: relative;
  z-index: 13;
  cursor: default;
}

@media (max-width: 767px) {
  .mmenu {
    display: none;
  }
  .module-MainController .mmenu {
    display: block;
  }
}

.mmenu:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e2e5e7;
  content: '';
  z-index: 1;
}

.mmenu a {
  text-decoration: none;
}

.mmenu__inner {
  background-color: #fff;
  margin: 0 -25px;
  line-height: 80px;
}

@media (max-width: 1259px) {
  .mmenu__inner {
    overflow: hidden;
    line-height: 1;
    height: 75px;
  }
}

@media (max-width: 767px) {
  .mmenu__inner {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.mmenu__right {
  float: right;
  margin-right: 25px;
}

.mmenu__right .btn {
  top: -2px;
  min-width: 130px;
  font-weight: 600;
}

@media (max-width: 1259px) {
  .mmenu__grid {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: -20px;
    text-align: center;
  }
}

.mmenu__grid .btn {
  top: -2px;
  min-width: 130px;
  font-weight: 600;
}

.mmenu__grid-inner {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;
}

@media (max-width: 1259px) {
  .mmenu__grid-inner {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    padding: 22px 10px 42px;
  }
}

.mmenu__grid-item {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
}

@media (max-width: 1259px) {
  .mmenu__grid-item {
    margin-left: 7px;
  }
  .mmenu__grid-item:first-child {
    margin-left: 0;
  }
}

.mmenu__grid-item--category > a:before, .mmenu__grid-item--category > span:before {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  height: 15px;
  width: 16px;
  margin-right: 7px;
  background: center center no-repeat;
  background-size: contain;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjM2Q1MzYwIiBkPSJNMjQgNmgtMjR2LTRoMjR2NHptMCA0aC0yNHY0aDI0di00em0wIDhoLTI0djRoMjR2LTR6Ii8+PC9zdmc+);
  content: '';
}

.mmenu__grid-item-inner {
  text-decoration: none;
  display: block;
  color: #2f2f2f !important;
  padding: 31px 25px;
  min-height: 80px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 1259px) {
  .mmenu__grid-item-inner {
    background-color: #f4f4f4;
    color: #465c6a !important;
    border-radius: 10px;
    font-size: 14px;
    min-height: 0;
    padding: 8px 15px;
    letter-spacing: 0.01em;
  }
}

.mmenu__grid-item:hover .mmenu__submenu {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  pointer-events: auto;
  display: block;
  -webkit-transition-delay: 0.2s;
          -o-transition-delay: 0.2s;
     transition-delay: 0.2s;
}

.mmenu__grid-item.focused .mmenu__submenu {
  opacity: 1;
  visibility: visible;
  z-index: 2;
  pointer-events: auto;
  display: block;
  -webkit-transition-delay: 0.2s;
          -o-transition-delay: 0.2s;
     transition-delay: 0.2s;
}

.mmenu__submenu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  padding: 30px 0 50px;
  margin-top: -1px !important;
  line-height: 1.2857;
  font-size: 14px;
  color: #2f2f2f;
  letter-spacing: 0.1em;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}

@media (max-width: 1259px) {
  .mmenu__submenu {
    display: none !important;
  }
}

.mmenu__submenu:before {
  position: absolute;
  top: 0;
  right: -2000px;
  bottom: 0;
  left: -2000px;
  border-top: 1px solid #e2e5e7;
  border-bottom: 1px solid #e2e5e7;
  background-color: #fff;
  content: '';
  z-index: -1;
}

.mmenu__submenu a {
  display: inline-block;
  vertical-align: top;
  color: #2f2f2f;
}

.mmenu__submenu a:hover {
  color: #3d5360;
}

.mmenu__submenu ul {
  list-style: none;
}

.mmenu__submenu-inner {
  margin: 0 -30px;
}

.mmenu__submenu-list {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mmenu__submenu-item {
  display: table-cell;
  vertical-align: top;
  margin: 0;
  padding: 0 30px;
}

.mmenu__ssubmenu {
  position: relative;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}

.mmenu__ssubmenu:first-child {
  margin-top: 0;
}

.mmenu__ssubmenu-title {
  font-weight: 600;
  line-height: 1.2;
  font-size: 16px;
  color: #3d5360 !important;
}

.mmenu__ssubmenu-title:hover {
  color: #2f2f2f !important;
}

.mmenu__ssubmenu-item {
  max-width: 240px;
}

.mmenu__ssubmenu-item:hover > a, .mmenu__ssubmenu-item:hover > span, .mmenu__ssubmenu-item.hover > a, .mmenu__ssubmenu-item.hover > span {
  background-color: #f4f4f4;
}

.mmenu__ssubmenu-item > a, .mmenu__ssubmenu-item > span {
  display: block;
  padding: 15px 20px;
  border-radius: 4px;
}

.mmenu__ssubmenu-item > a .icon, .mmenu__ssubmenu-item > span .icon {
  font-size: 13px;
  top: 2px;
  float: right;
  margin-left: 7px;
  color: #aaa;
}

.mmenu__s2submenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 240px;
  padding: 0 30px 30px;
  min-height: 100%;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.mmenu__s2submenu:before {
  position: absolute;
  left: -9999px;
  content: '';
  right: -9999px;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #fff;
  pointer-events: none;
}

.mmenu__grid-item:hover .mmenu__ssubmenu-item:hover .mmenu__s2submenu,
.mmenu__grid-item:hover .mmenu__ssubmenu-item.hover .mmenu__s2submenu {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.mmenu__grid-item.focused .mmenu__ssubmenu-item:hover .mmenu__s2submenu,
.mmenu__grid-item.focused .mmenu__ssubmenu-item.hover .mmenu__s2submenu {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.mmenu__s2submenu-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -20px -30px;
  padding: 0;
}

.mmenu__s2submenu-item {
  margin: 0 0 30px;
  padding: 0 20px;
  width: 25%;
}

.mmenu__s2submenu-item--more a {
  color: #999;
}

.mmenu__s2submenu-item--more a:hover {
  color: #3d5360;
}

.mmenu__s3submenu {
  margin-top: 10px;
}

.mmenu__s3submenu:first-child {
  margin-top: 0;
}

.mmenu__s3submenu-list {
  margin: 0;
  padding: 0;
}

.mmenu__s3submenu-item {
  margin-top: 5px;
}

.mmenu__s3submenu-item:first-child {
  margin-top: 0;
}

/* Админская линия сверху начало */
.mininavbar {
  min-height: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 0;
  border-radius: 0;
  background: #fff;
  border: none;
}

.minicontainer {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 -15px;
}

.mininavbar ul.navbar-nav li:first-child a {
  padding-left: 0;
}

.mininavbar ul.pull-right li:last-child a {
  padding-right: 0;
}

a.logout span {
  text-decoration: underline;
}

a.logout:hover span {
  text-decoration: none;
}

.mininavbar ul.nav li a {
  padding: 5px 20px;
  color: #333333;
  text-decoration: none;
}

a.to-frontend span {
  text-decoration: underline;
}

.mininavbar .badge {
  opacity: 0.4;
}

.otkliki {
  margin: 7px 0;
}

@media (max-width: 767px) {
  .otkliki {
    display: none;
  }
}

.otkliki li a span {
  text-decoration: underline;
}

.otkliki li a {
  padding: 7px 8px 9px !important;
  margin-right: 7px;
  border-radius: 4px;
}

.otkliki li:last-child a {
  margin-right: 0;
}

.otkliki li a span.badge {
  text-decoration: none;
  font-weight: normal;
  background: #428bca;
  padding: 2px 5px;
  opacity: 0.7;
  line-height: 11px;
  font-size: 11px;
}

.orderspage span.badge {
  background: #d9534f !important;
  opacity: 1 !important;
}

.otkliki li a.orderspage {
  background-image: url("../../../system/templates/img/icons/topmenu/orders.png");
  background-position: 8px 9px;
  background-repeat: no-repeat;
  padding-left: 30px !important;
}

.otkliki li a.top-message {
  background-image: url("../../../system/templates/img/icons/topmenu/message.png");
  background-position: 8px 13px;
  background-repeat: no-repeat;
  padding-left: 30px !important;
}

.otkliki li a.faq {
  background-image: url("../../../system/templates/img/icons/topmenu/comment.png");
  background-position: 8px 13px;
  background-repeat: no-repeat;
  padding-left: 30px !important;
}

.otkliki li a:hover,
.otkliki li.active a {
  background-color: #f8f5ee !important;
}

.adminmenu {
  margin: 7px 0;
}

.adminmenu li a {
  padding: 7px 8px 9px 30px !important;
  margin-right: 7px;
  border-radius: 4px;
}

.adminmenu li:last-child a {
  margin-right: 0;
}

.adminmenu li a:hover,
.otkliki li.active a {
  background-color: #f8f5ee !important;
}

.adminmenu li a.usermenu {
  background-image: url("../../../system/templates/img/icons/topmenu/boss.png");
  background-position: 8px 9px;
  background-repeat: no-repeat;
  white-space: nowrap;
}

.to-backend {
  background-color: #fafafa;
  background-image: url("../../../system/templates/img/favicon.ico");
  background-position: 8px 9px;
  background-repeat: no-repeat;
  white-space: nowrap;
}

.to-backend span {
  text-decoration: underline;
}

.adminpanel {
  border-bottom: 1px solid #e0e0e0;
  background: #FFF;
}

@media (max-width: 1259px) {
  .adminpanel .row {
    margin: 0;
  }
}

.adminpanel .navbar {
  margin-bottom: 0;
}

.adminpanel .container {
  padding: 0;
}

/* Админская линия сверху конец */
.footer {
  background-color: #3d5360;
  color: #fff;
  padding: 35px 0 50px;
}

@media (max-width: 1259px) {
  .footer {
    padding-top: 40px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .footer {
    padding-bottom: 80px;
  }
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer__grid {
  margin: 0 -20px;
}

.footer__grid:after {
  content: "";
  display: table;
  clear: both;
}

.footer__grid-inner {
  display: table;
  width: 100%;
}

@media (max-width: 1259px) {
  .footer__grid-inner {
    display: block;
    float: left;
    width: 100%;
    margin: -15px 0;
  }
}

.footer__cell {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 14%;
  min-width: 150px;
  padding: 0 20px;
}

@media (max-width: 1259px) {
  .footer__cell {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.footer__cell--auto {
  width: auto;
  min-width: 0;
}

.footer__cell--w-social {
  padding-bottom: 40px;
}

.footer__cell--contacts {
  text-align: right;
}

@media (max-width: 1259px) {
  .footer__cell--contacts {
    text-align: center;
  }
}

.footer__social {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  z-index: 1;
}

@media (max-width: 1259px) {
  .footer__social {
    position: relative;
    left: auto;
    right: auto;
  }
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu:first-child {
  margin-top: 0;
}

.footer-menu__title {
  font-size: 15px;
  font-weight: 700;
  margin-top: 15px;
}

.footer-menu__title:first-child {
  margin-top: 0;
}

.footer-menu__content {
  font-size: 11px;
  line-height: 1.2857;
  margin-top: 15px;
}

.footer-menu__content:first-child {
  margin-top: 0;
}

.footer-menu .vertical-menu ol li {
  margin-top: 8px;
}

.footer-menu .vertical-menu ol li:first-child {
  margin-top: 0;
}

.footer-menu .vertical-menu ol li a {
  padding: 0;
  display: inline;
  font-size: 11px;
  line-height: 1.2857;
}

.footer-contacts {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 1.5;
}

.footer-contacts__title {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.footer-contacts__title:first-child {
  margin-top: 0;
}

.footer-contacts__phone {
  margin-top: 25px;
  font-size: 30px;
  line-height: 1;
}

.footer-contacts__phone:first-child {
  margin-top: 0;
}

.footer-contacts__mail {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
}

.footer-contacts__mail:first-child {
  margin-top: 0;
}

.footer-contacts__mail .icon {
  height: 14px;
  width: 16px;
  vertical-align: middle;
  margin-right: 10px;
  top: -1px;
}

.footer-copyright {
  margin-top: 30px;
  letter-spacing: 0.1em;
  font-size: 10px;
  line-height: 1.2;
}

.footer-copyright:first-child {
  margin-top: 0;
}

.fbar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  z-index: 50;
}

.fbar__inner {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.fbar__item {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 10px 20px;
  height: 50px;
  background-color: #f4f4f4;
  color: #3d5360;
  text-decoration: none;
}

.fbar__item--phone {
  background-color: #ef8fce;
  color: #fff;
}

.fbar__item--whatsapp {
  background-color: #01d55b;
  color: #fff;
}

.fbar__item--whatsapp .icon {
  font-size: 26px;
}

.fbar__item--telegram {
  background-color: #00abee;
  color: #fff;
}

.fbar__item--telegram .icon {
  font-size: 26px;
}

.fbar__item .icon {
  font-size: 24px;
  vertical-align: middle;
}

.totop {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 15;
  color: #3d5360;
  background-color: #ebebeb;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  line-height: 46px;
  text-align: center;
  font-size: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
  -o-transition: opacity 0.2s, visibility 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.totop:hover {
  opacity: 0.8;
}

.scrolled-screen .totop {
  visibility: visible;
  opacity: 0.6;
  -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
     transition-delay: 0s;
}

.scrolled-screen .totop:hover {
  opacity: 0.8;
}

@media (max-width: 767px) {
  .totop {
    display: none !important;
  }
}

/* Прилепленный к низу футер начало */
.wrap {
  margin: 0 auto;
}

.container {
  padding-left: 25px;
  padding-right: 25px;
  width: auto;
}

@media (min-width: 1260px) {
  .container {
    max-width: 1200px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row {
  margin-left: -25px;
  margin-right: -25px;
}

@media (min-width: 1260px) {
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

/* Немного поправляем стили Boostrap 3*/
label {
  font-weight: normal;
}

input, button, select, textarea {
  outline: none;
}

/* Дерево категорий начало */
.vertical-menu {
  /* описание шапки блока категорий */
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 15px;
}

.vertical-menu ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vertical-menu ol li {
  /* если эта категория имеет подкатегории у которых нет подкатегорий то отступ не показываем (ведь плюсика нет) */
  padding-left: 0;
}

.vertical-menu ol.havechilds,
.vertical-menu ol li ol li {
  /* если эта категория имеет подкатегории которые имеют свои подкатегории (то есть показывается плюсик) то ставим отступ */
  padding-left: 20px;
}

.vertical-menu ol li i {
  /* это иконка плюса, минуса и иконки загрузки */
  color: #888888;
  cursor: pointer !important;
  display: block;
  float: left;
  font-size: 14px;
  margin: 7px 0 0 -16px;
  position: absolute;
}

.vertical-menu ol li div {
  /* отступ чтобы показать количество товаров в категории, сейчас скрыто поскольку и количество товаров скрыто */
  /* padding-right: 23px; */
}

.vertical-menu ol li a {
  display: block;
  line-height: 1.15;
  padding: 5px 0;
  font-size: 15px;
}

.vertical-menu ol li a:hover {
  text-decoration: none;
}

.vertical-menu ol li a.active {
  font-weight: bold;
}

.vertical-menu ol li span {
  /* показываем количество товаров в категории */
  display: none;
  color: #C3C3C3;
  font-size: 12px;
  position: absolute;
  right: 24px;
  padding: 2px 0 0 0;
  line-height: 20px;
}

.vertical-menu ul li ul li a {
  /* стиль ссылок для подкатегорий */
}

.vertical-menu-heading {
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 6px;
  padding-bottom: 6px;
  margin-top: 2px;
  border-bottom: 1px solid #e7e7e7;
}

/* Дерево категорий конец */
/* Корзина начало */
.order-stat {
  display: block;
  text-decoration: none;
  float: right;
  height: 55px;
  width: 216px;
  padding: 14px 21px;
  color: #FFF;
  text-align: left;
  background-color: #c52411;
  border: 0;
  vertical-align: middle;
  line-height: normal;
  white-space: nowrap;
  border-radius: 0;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  background: url("../img/order.png") no-repeat 21px 16px;
}

.order-stat-header {
  font-size: 16px;
  font-weight: 500;
  display: block;
  padding-left: 44px;
  padding-top: 3px;
  line-height: 1;
  color: #FFF;
  text-transform: uppercase;
}

.order-text {
  font-size: 10px;
  color: #fff;
  opacity: 0.84;
  padding-top: 0px;
  padding-left: 44px;
  margin-top: 1px;
  display: block;
}

.order-stat:hover {
  background-color: #1d9ad5;
}

.fiz-ur {
  display: block;
  margin-bottom: 15px;
}

.checkout {
  line-height: 1.45 !important;
  color: #FFF !important;
  padding: 6px 0 !important;
  text-decoration: none;
}

.checkout:hover,
.checkout:focus {
  color: #FFF !important;
  background: #47A447 !important;
  border-color: #398439 !important;
}

.subtotal {
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  border-top: 1px solid #f5f5f5;
  display: block;
  padding: 8px;
}

.notopline {
  border-top: none !important;
  padding-top: 0 !important;
  margin-top: -8px !important;
}

/*
.delivery-header {
    font-size: 18px;
    font-weight: 500;
}

.delivery-text {
    font-size: 12px
}

.delivery-text ul {
    padding-left: 10px;
}

#delivery-settings {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #f5f5f5;
}

.delivery-text p:last-child {
    margin-bottom: 10px;
}

.delivery-select {
    display: block;
}

.delivery-select .radio {
    padding: 5px 8px 5px 8px;
    margin: 0;
}

.radio-separator {
    display: block;
    margin-top: 4px;
    padding-top: 3px;
    border-top: 1px dotted #f5f5f5;
}

.radio-separator:last-child {
    display: none;
}

.delivery-select .radio:hover {
    background: #f9f9f9
}

.delivery-select .radio.active {
    background: #f5f5f5
}

.delivery-select .radio {
    border-bottom: none;
    margin: 0;
    border-radius: 4px;
}

.delivery-select .radio input {
    margin-top: 6px
}

.payment-select {
    display: block;
    margin-right: -20px;
    padding: 0 0 4px 0;
}

.payment-select-header {
    font-size: 18px
}

.payment-select .radio {
    font-size: 13px
}

.payment-method {
    background: #FFF;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    margin: 0 8px 8px 0;
    padding: 0px 4px 0 8px;
    width: 174px;
    display: block;
    float: left;
    -webkit-transition: background 0.2s ease;
    transition: background 0.2s ease;
}

.payment-method:hover,
.payment-method.active {
    background: #f5f5f5
}

.payment-method .radio {
    margin: 5px 0
}

.payment-method .radio input {
    margin-top: 26px
}

.payment-image {
    text-align: center;
    height: 48px;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    vertical-align: central;
}

.payment-header {
    text-align: center;
    font-weight: 500;
}

.delivery-subtotal {
    font-size: 18px;
    text-align: right;
    display: table;
    width: 100%;
    padding: 4px 8px;
    background: #f9f9f9;
    border-radius: 4px;
    font-weight: 500;
}

.sub-price {
    width: 150px;
    display: inline-block;
    font-size: 22px;
}

.delivery-subtotal .sub-price {
    font-size: 26px
}

#delivery-section {
    display: table;
    width: 100%;
    clear: both;
}
*/
.userdata {
  display: block;
}

.userdata .form-group.userdata-nomargin {
  margin-bottom: 0px;
}

.help-block-error {
  /* Уведомление о неверно заполненом поле */
  color: #333;
  background-color: #f2dede;
  display: inline-block;
  margin-top: 5px;
  border-radius: 4px;
  letter-spacing: 0.01em;
  font-size: 12px;
  line-height: 1.42857143;
  padding: 10px 15px;
}

.help-block-error:before {
  /* Синяя стрелочка для уведомления */
  content: " ";
  color: #fff;
  float: left;
  position: relative;
  top: -9px;
  left: 14px;
  display: block;
  width: 9px;
  margin-left: -9px;
  height: 5px;
}

/*
.userdata .help-block {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
}

.userdata-link {
    font-size: 12px;
    color: #888;
    background: #f7f7f7;
    padding: 4px;
    border-radius: 4px;
    opacity: 0.6;
}

.enter-link {
    color: #428BCA;
    opacity: 1;
    background: none;
    padding: 0;
}

.userdata-link:hover {
    border-bottom: none
}

.userdata-link.on {
    color: #20A800;
    opacity: 1;
}

.userdata-link.off {
    color: #FF0000;
    opacity: 1;
}

.region {
    display: inline-block;
    padding-right: 5px;
}

.another-region {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 250px;
}

.yandex-map {
    margin: 5px -8px 10px;
    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

.enterphone {
    display: table;
    clear: both;
}

.phone-code {
    width: 85px;
    display: block;
    float: left;
}

.phone-code input {
    padding: 6px;
    border-radius: 4px !important;
}

.phone-number {
    width: 85px;
    display: block;
    float: left;
}

.phone-code .input-group-addon {
    background: #FFF;
    padding: 8px 3px;
    border: none;
}

.userdata-checkout {
    margin-bottom: 0px
}

.add-phone {
    display: table;
    clear: both;
}

#additional-phone {
}

.add-phone a {
    font-size: 12px;
    text-decoration: none;
    border-bottom: 1px dotted;
}

.add-phone:hover {
    border-bottom: none
}

.userdata .cart-ok {
    color: #47a447;
    font-size: 22px;
    position: absolute;
    right: -22px;
}

.userdata .cart-ok.phone-ok {
    left: 201px
}

.userdata .hint {
    padding-left: 30px
}

.userdata label span {
    color: #900
}

.cart-pod-zakaz {
    display: block;
    margin-top: 3px;
    display: none;
}

.cart-pod-zakaz span {
    display: inline-block;
    font-size: 12px;
    color: #666;
    background: #f5f5f5;
    padding: 2px 5px;
    border-radius: 2px;
}

.cart-offer {
    display: table;
    clear: both;
    width: 100%;
    background: #dff0d8;
    padding: 10px;
    color: #333;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.cart-offer p {
    margin-bottom: 0;
}

.cart-header {
    font-size: 21px;
    line-height: 1.05;
}

.cart-block {
    border-bottom: 1px solid #f8f5ee;
    margin-bottom: 13px;
    padding-bottom: 12px;
}

.cart-block-last {
    border: none !important;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cart-block-first {
}

.cart-block .col-xs-3 {
    width: 32%;
}

.cart-block .col-xs-9 {
    width: 68%;
}
*/
.hint {
  font-size: 12px;
}

.warning-blue {
  padding: 15px;
  background-color: #d9edf7;
  margin: 0 0 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
}

.cart-modifier {
  display: table;
  clear: both;
  width: 100%;
  margin-top: 2px;
}

.cart-modifier ul {
  margin: 0;
  padding: 0;
}

.cart-modifier ul li {
  display: block;
  list-style: none;
}

.cart-modifier li div {
  display: inline-block;
  font-size: 12px;
  color: #333;
  background: #fcf8e3;
  padding: 2px 5px;
  border-radius: 2px;
  margin-bottom: 2px;
}

/*
.delivery-external-module-block .col-md-6 {
    margin: 0;
    padding: 0;
}

.delivery-external-module-block label {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.delivery-external-module-block .form-group {
    width: 200px !important;
    display: table;
    clear: both;
    width: 100%;
    margin-bottom: 15px;
}

#extModuleMkadKilometers {
    margin: 0;
}

.delivery-external-module-block .input-group {
    width: 130px;
}

.delivery-external-module-block .input-group input {
    height: 31px;
}

.delivery-external-module-block .input-group input {
    text-align: right;
    border: 1px solid #CCCCCC !important;
    border-radius: 4px !important;
}

.delivery-external-module-block .input-group .btn-sm {
    color: #666;
    font-size: 14px;
    border: none;
    margin: 0 1px 0 1px !important;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: none !important;
}

.delivery-external-module-block .input-group .btn-sm.disabled i {
    color: #666 !important;
    box-shadow: none;
}

.delivery-external-module-block .input-group .btn-sm {
    background-color: #e6e6e6;
}

.delivery-external-module-block .input-group .btn-sm.focus {
}

.delivery-external-module-block .input-group .btn-sm i {
    color: #999
}

.delivery-external-module-block .input-group .btn-sm:hover i {
    color: #333
}
*/
/* Корзина конец */
/* Список товаров списка Избранного - начало */
.favotite-list {
  padding: 0;
  display: table;
  clear: both;
  margin-bottom: 20px;
}

.favorite-item {
  list-style: none;
  display: block;
  float: left;
  margin: 0 0 10px 0;
  width: 720px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #eeeeee;
}

.favorite-list-badges {
  display: table;
  clear: both;
  margin-bottom: 8px;
  padding: 0;
}

ol.favorite-list-badges li {
  list-style: none;
  display: inline-block;
  margin-right: 4px;
}

.favorite-image {
  line-height: 100px;
  display: block;
  text-align: center;
  margin-bottom: 4px;
}

.favorite-left {
  width: 100px;
  display: block;
  float: left;
  margin-right: 10px;
}

.favorite-center {
  width: 465px;
  display: block;
  float: left;
}

.favorite-right {
  width: 130px;
  display: block;
  float: right;
  padding: 9px;
  background: #ebe5d7;
  border-radius: 4px;
}

.favorite-description {
  font-size: 12px;
}

.favorite-name-block {
  margin: 2px 0 5px 0;
  display: block;
}

.favorite-name {
  font-size: 16px;
  display: inline;
  line-height: 1.15;
}

.favorite-status {
  display: block;
  font-size: 12px;
  margin-bottom: 3px;
  color: #666;
}

.favorite-price {
  font-size: 20px;
  line-height: 20px;
  width: 132px;
  display: block;
  float: left;
  margin-top: 3px;
}

.favorite-buy {
  margin-top: 9px;
}

.delete-from-favorite {
  text-decoration: none;
  color: #666;
  font-size: 14px;
  line-height: 14px;
  background: #f3f3f3;
  padding: 4px 7px 4px 6px;
  border-radius: 4px;
  display: block;
  float: right;
}

.delete-from-favorite:hover {
  background: #e6e6e6;
}

.delete-from-favorite:hover span {
  text-decoration: underline;
}

.favorite-old-price {
  /* Старая цена */
  text-decoration: line-through;
  color: #666;
}

.account-content .breadcrumb {
  background: none;
  padding: 0;
  font-size: 12px;
}

/* Блок загрузки при нажатии на кнопку Показать еще начало  */
.loading {
  display: block;
  height: 118px;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #767676;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Сортировка - начало */
.nav-pills > li + li {
  margin: 0;
}

/* Сортировка - конец */
/* Все всплывающие окна - начало */
.help-block.red {
  font-size: 12px;
  color: #DD0003;
}

.modal-complain {
  width: 350px;
}

#complain-form .modal-body {
  padding: 0 20px;
}

.modal-complain .alert {
  margin-top: 20px;
}

/* Все всплывающие окна - конец */
/* Слайдшоу начало */
.carousel {
  margin-bottom: 20px;
}

.carousel-control i {
  display: inline-block;
  position: absolute;
  top: 45%;
  z-index: 5;
}

.carousel-indicators {
  bottom: 10px;
  margin-bottom: 0px;
  opacity: 0.7;
}

.carousel-indicators li {
  border: 1px solid #CCC;
  width: 17px;
  height: 17px;
}

.carousel-indicators .active {
  background: #008ccd;
  border: none;
  width: 18px;
  height: 18px;
}

.carousel-control.left {
  width: 75px;
  text-align: left;
}

.carousel-control.right {
  width: 75px;
  text-align: left;
}

.carousel-control.left i {
  margin-left: 30px;
}

.carousel-control.right i {
  margin-left: 30px;
}

.carousel-control.left {
  background: none;
}

.carousel-control.right {
  background: none;
}

/* Слайдшоу конец */
/* Сравнение товаров начало */
.compare-head {
  margin-bottom: 10px;
}

.compare-params a {
  border-radius: 4px !important;
  text-decoration: none;
}

.compare-params a span {
  border-bottom: 1px dotted;
}

.compare-params a:hover {
  background: #EFEFEF;
  text-decoration: none;
}

.compare-params a.active {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #EFEFEF;
}

.compare-params a.active span {
  border: none;
}

.compare-params h3 {
  display: block;
  vertical-align: top;
}

.compare-head tr th {
  vertical-align: bottom;
}

.compare-product {
  font-weight: normal;
  vertical-align: top !important;
}

.compare-product-image {
  display: block;
  height: 120px;
  width: 120px;
  margin-bottom: 5px;
  clear: both;
}

.compare-product a {
  display: block;
}

.compare-delete {
  /* ссылка удалить */
  text-decoration: none;
  margin-top: 5px;
  line-height: 1.25;
  display: block !important;
  position: relative;
  bottom: 0px;
  left: 0px;
  background: #FFF;
  vertical-align: bottom;
}

.compare-delete span {
  border-bottom: 1px dotted;
}

.compare-delete:hover span {
  border-bottom: none;
}

.compare-delete .fa {
  color: #999;
}

.compare-body tr td {
  border-top: 0 !important;
  border-bottom: 1px solid #DDDDDD;
}

.compare-fistblock,
.compare-property-name {
  /* ширина первой колонки в сравнении - там где перечисление свойств */
  width: 300px;
}

.compare-property-value,
.compare-product {
  width: 220px;
}

.compare-property-name .fa {
  color: #666;
}

/* Отображение статуса заказа в сравнении товаров */
.compare-property-value .product-status {
  font-size: 14px;
  display: inline-block;
}

.compare-property-value hr {
  margin: 3px 0;
}

/* Сравнение товаров конец */
/* Отображение материалов начало */
.material-date {
  font-size: 12px;
  color: #666;
}

.material-separator {
  /* Разделитель, ставится после каждого материала */
  border-bottom: 1px dashed #E3E3E3;
  padding-bottom: 9px;
  margin: 0 0 10px;
  display: block;
  clear: both;
}

.category-materials {
  color: #333;
}

.material-readmore {
  text-decoration: none;
}

/* Отображение материлов конец */
/* Форма регистрации начало */
.input-ok {
  color: #47a447;
  font-size: 22px;
  position: absolute;
  right: -15px;
  margin-top: 0px;
}

.input-ok.phone-ok {
  left: 193px;
}

/* Форма регистрации конец */
/* Поиск начало */
#addRelatedModal .modal-dialog {
  width: 930px;
}

#addRelatedModal .modal-body {
  padding: 10px 20px;
}

.sky-id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4px;
}

.object-header {
  height: 18px !important;
  min-height: 15px !important;
  line-height: 14px;
  padding: 3px 3px 6px 3px;
  display: block;
  width: 100%;
  font-size: 11px;
  border-bottom: none !important;
}

.object-header span {
  padding: 0;
}

/* Личный кабинет начало */
.accountmenu a {
  text-decoration: none;
  padding-left: 8px;
}

.accountmenu a i {
  opacity: 0.8;
  display: inline-block;
  width: 20px;
  text-align: center;
}

.accountmenu a span {
  text-decoration: none;
}

.accountmenu a:hover span {
  text-decoration: none;
}

.order-details {
  display: block;
  margin-top: 10px;
}

.order-details-list p:last-child {
  margin-bottom: 0;
}

.orderlist {
  padding: 0;
}

.orderlist li {
  list-style: none;
}

.orderlist li.panel {
  margin-bottom: 30px;
}

.orderlist .list-group-item {
  border: 1px solid #f5f5f5;
}

.orderlist .ordertable td,
.orderlist .ordertable th {
  border-color: #f5f5f5;
}

.order-number {
  font-size: 18px;
}

.orders-status span {
  font-size: 14px;
  font-weight: normal !important;
}

.orderlist li:first-child {
  margin-top: -5px;
}

.order-head {
  border-left: 3px solid #9ecc92;
  padding: 0 0 0 6px;
}

.notify-block {
  display: table;
  width: 100%;
  clear: both;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #efefef;
}

.notify-block .mini-onoff {
  float: right;
}

.orders-repeat {
  text-align: right;
  display: block;
  margin: -2px -8px -3px 0;
}

.orders-repeat .btn i {
  font-size: 14px;
}

.orders-number {
  font-size: 18px;
}

.notorders {
  color: #D3D3D3 !important;
}

.orderlist-date {
  text-align: right;
  display: block;
  float: right;
}

.current-orders .orders-status {
  padding: 0;
}

.current-discount {
  /* Текущая скидка пользователя */
  display: block;
  border-radius: 4px;
  background: #f5f5f5;
  padding: 15px;
  font-size: 15px;
}

.current-discount span {
  font-weight: bold;
}

.review-controls {
  /* Удалине и статус отзыва в личном кабинете */
  display: table;
  width: 100%;
  clear: both;
  padding-top: 5px;
}

.delete-review {
  display: block;
  float: left;
  width: 180px;
  color: #828282;
  text-decoration: none;
}

.delete-review:hover {
  color: #9C0002;
}

.delete-review span {
  text-decoration: underline;
}

.review-status {
  display: block;
  float: right;
  width: 525px;
}

.review-moderate {
  color: #828282;
}

.review-published {
  color: #1f8321;
}

/* Личный кабинет конец */
/* Категория товаров начало */
.decription-category-top {
  /* верхнее описание категории */
  display: table;
  clear: both;
}

.decription-category-top table td {
  font-size: 13px;
}

.decription-category-bottom {
  margin-top: 35px;
}

.main-cetegory-image {
  background-color: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  display: block;
  clear: both;
  height: auto;
  line-height: 1.42857;
  margin-bottom: 8px;
  width: 345px;
  padding: 4px;
  text-align: center;
}

.main-cetegory-image:hover {
  border-color: #CCC;
}

.category-images-additional {
  padding: 0;
  width: 345px;
  margin-right: -10px;
}

.category-images-additional li {
  list-style: none;
  display: inline-block;
  padding: 0;
}

.category-images-additional li a {
  background-color: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 79px;
  width: 79px;
  line-height: 71px;
  margin: 0 4px 8px 0;
  padding: 2px;
  text-align: center;
  vertical-align: middle;
}

.category-images-additional li a:hover {
  border-color: #CCC;
}

/* Категория товаров конец */
/* Общее начало */
.w100 {
  width: 100%;
}

a.btn {
  text-decoration: none;
}

.mini-onoff {
  display: block;
}

.mini-onoff .btn-default.on.active {
  background-color: #5CB85C;
  border-color: #4CAE4C;
  color: #FFFFFF;
}

.userdata .help-block {
  font-size: 12px;
  margin: 0px 0 2px 0;
}

.userdata .form-group:last-child {
  margin-bottom: 0;
}

.alert {
  color: #333 !important;
}

.topnews {
  display: block;
  margin-bottom: 15px;
  border-radius: 4px;
  background: #f5f1e8;
}

.topnews:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.topnews p:last-child {
  margin-bottom: 0;
}

/* Общее конец */
/* Фильтр по цене */
.range {
  display: table;
  width: 100%;
  clear: both;
  font-size: 12px;
}

.rleft {
  display: block;
  float: left;
  width: 58%;
}

.rright {
  display: block;
  float: left;
  width: 42%;
}

.range .input-group-addon {
  padding: 0px 4px 0 4px;
  border: none;
  background: none;
  font-size: 13px;
}

.range .form-control {
  border-radius: 4px !important;
  padding: 5px;
  width: 68px;
}

.bs-callout-danger {
  background-color: #fdf7f7;
  border-color: #d9534f;
}

.bs-callout {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
}

/* Прелоадер */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #333;
  opacity: 0.45;
  line-height: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.14s;
  -o-transition: opacity 0.14s;
  transition: opacity 0.14s;
}

/* Текст главной страницы разбиваем на 2 колонки */
.main-text .material-full {
  display: block;
  margin-bottom: 25px;
}

.modal-title {
  font-size: 17px;
  font-weight: 500;
}

.external-modules {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#payments-methods-modal-form .modal-body img {
  display: none;
}

#delivery-price-modal-form .modal-body img {
  display: none;
}

.payment-options {
  margin-top: 40px;
}

.payment-options-2 {
  margin-top: 0px;
}

.payment-options-icons
.payment-icon {
  display: inline-block;
  margin: 10px 6px 5px 0px;
  float: left;
}

.payment-icon-2 {
  margin: 8px 0px 0px 0px;
  display: inline-block;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.05);
}

.payment-icon-2 img {
  height: 33px;
  border-radius: 2px;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

.payment-icon-check {
  display: inline-block;
  margin: 10px 0px 5px 0px !important;
}

.payment-icon img {
  height: 33px;
  border-radius: 2px;
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

.payment-icon img:hover {
  height: 33px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.15s;
  -o-transition: 0.15s;
  transition: 0.15s;
}

/* Adaptive styles */
.menu-switcher {
  position: relative;
  z-index: 2;
  float: left;
  margin-top: 7px;
  margin-right: 15px;
  display: none;
  height: 30px;
  width: 36px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-switcher:before {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  height: 5px;
  width: 36px;
  background-color: #d53535;
  content: "";
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-switcher:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 36px;
  background-color: #d53535;
  content: "";
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-switcher > span {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-switcher > span:first-child {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  height: 5px;
  width: 36px;
  margin-top: -3px;
  background-color: #d53535;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-switcher > span:first-child + span {
  position: absolute;
  top: -8px;
  right: -5px;
  bottom: -8px;
  left: -5px;
}

/*.slideout-open .menu-switcher > span:first-child*/
.menu-switcher.active > span:first-child {
  -webkit-transform: rotate(90deg) scaleX(0);
  -ms-transform: rotate(90deg) scaleX(0);
  transform: rotate(90deg) scaleX(0);
}

/*.slideout-open .menu-switcher:before*/
.menu-switcher.active:before {
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform: translate(5px, 0px) rotate(45deg);
  -ms-transform: translate(5px, 0px) rotate(45deg);
  transform: translate(5px, 0px) rotate(45deg);
}

/*.slideout-open .menu-switcher:after*/
.menu-switcher.active:after {
  -webkit-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform: translate(5px, 0px) rotate(-45deg);
  -ms-transform: translate(5px, 0px) rotate(-45deg);
  transform: translate(5px, 0px) rotate(-45deg);
}

.main-content {
  position: relative;
}

.section {
  padding: 50px 0;
}

@media (max-width: 767px) {
  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section__inner {
  position: relative;
  z-index: 1;
}

.section__bg {
  position: absolute;
  top: 0;
  right: -9999px;
  bottom: 0;
  left: -9999px;
}

.section--grey {
  background-color: #f4f4f4;
}

.section--grey .section__bg {
  background-color: #f4f4f4;
}

.section--grey .tabs__inner:before {
  background-image: -webkit-gradient(linear, left top, right top, from(#f4f4f4), to(rgba(244, 244, 244, 0)));
  background-image: -webkit-linear-gradient(left, #f4f4f4, rgba(244, 244, 244, 0));
  background-image: -o-linear-gradient(left, #f4f4f4, rgba(244, 244, 244, 0));
  background-image: linear-gradient(to right, #f4f4f4, rgba(244, 244, 244, 0));
}

.section--grey .tabs__inner:after {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(244, 244, 244, 0)), to(#f4f4f4));
  background-image: -webkit-linear-gradient(left, rgba(244, 244, 244, 0), #f4f4f4);
  background-image: -o-linear-gradient(left, rgba(244, 244, 244, 0), #f4f4f4);
  background-image: linear-gradient(to right, rgba(244, 244, 244, 0), #f4f4f4);
}

.breadcrumbs {
  position: relative;
  margin-top: 35px;
  z-index: 12;
}

@media (max-width: 1259px) {
  .breadcrumbs {
    margin-top: 30px;
  }
}

.breadcrumbs .dropdown > ul.dropdown-menu:before {
  position: absolute;
  top: -5px;
  right: 0;
  left: 0;
  height: 5px;
  content: '';
}

.breadcrumbs .dropdown:hover > ul.dropdown-menu {
  display: block;
}

.breadcrumbs .dropdown.focused > ul.dropdown-menu {
  display: block;
}

@media (min-width: 1260px) {
  .category-w-background_image .breadcrumbs {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
  .category-w-background_image .breadcrumbs .breadcrumb {
    color: rgba(255, 255, 255, 0.8);
  }
  .category-w-background_image .breadcrumbs .breadcrumb > li + li:before {
    color: rgba(255, 255, 255, 0.8);
  }
  .category-w-background_image .breadcrumbs .breadcrumb a {
    color: rgba(255, 255, 255, 0.8);
  }
  .category-w-background_image .breadcrumbs .breadcrumb a:hover {
    color: #fff;
  }
  .category-w-background_image .breadcrumbs .breadcrumb > .active {
    color: #fff;
  }
}

@media (max-width: 1259px) {
  .category-w-background_image .breadcrumbs {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .category-w-background_image .breadcrumbs {
    margin-top: 20px;
    padding-bottom: 0;
  }
  .category-w-background_image .breadcrumbs .breadcrumb {
    display: none;
  }
}

@media (min-width: 1260px) {
  .category-w-background_image--dark .breadcrumbs .breadcrumb {
    color: #737373;
  }
  .category-w-background_image--dark .breadcrumbs .breadcrumb > li + li:before {
    color: #737373;
  }
  .category-w-background_image--dark .breadcrumbs .breadcrumb a {
    color: #737373;
  }
  .category-w-background_image--dark .breadcrumbs .breadcrumb a:hover {
    color: #000;
  }
  .category-w-background_image--dark .breadcrumbs .breadcrumb > .active {
    color: #000;
  }
}

@media (max-width: 767px) {
  .module-ProductController .breadcrumbs {
    display: none;
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: transparent;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: #737373;
}

@media (min-width: 1260px) {
  .module-ProductController .breadcrumb {
    max-width: 620px;
  }
}

@media (max-width: 767px) {
  .breadcrumb {
    letter-spacing: 0.01em;
  }
}

.breadcrumb > li + li:before {
  letter-spacing: 0;
  padding: 0 3px 0 5px;
  color: #737373;
}

.breadcrumb a {
  color: #737373;
  text-decoration: none;
}

.breadcrumb > .active {
  color: #000;
}

.main-title {
  margin-top: 30px;
}

.main-title:first-child {
  margin-top: 0;
}

.decription-category-top {
  margin-top: 30px;
}

.decription-category-top:first-child {
  margin-top: 0;
}

.decription-category-top .expand-it-container {
  max-height: 34px;
}

.decription-category-top .more {
  margin-top: 0;
  margin-bottom: -10px;
}

.decription-category-top .more .btn {
  padding-right: 0;
  padding-left: 0;
  min-width: 0;
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  letter-spacing: 0.01em;
}

.category-top-block {
  padding: 35px 0;
}

@media (max-width: 767px) {
  .category-top-block {
    padding-bottom: 50px;
  }
}

.category-top-block .breadcrumbs {
  margin-top: 0;
}

.grey-top-block {
  padding: 35px 0 50px;
  background-color: #f4f4f4;
}

.grey-top-block .breadcrumbs {
  margin-top: 0;
}

.grey-top-block .tabs--rounded {
  margin-left: -10px;
  margin-right: -10px;
}

@media (max-width: 1259px) {
  .grey-top-block .tabs--rounded {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.grey-top-block .tabs--rounded li {
  padding: 0 10px;
}

@media (max-width: 1259px) {
  .grey-top-block .tabs--rounded li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.grey-top-block .tabs--rounded a:hover {
  background-color: #fff !important;
}

@media (max-width: 767px) {
  .grey-top-block .tabs--rounded a {
    background-color: #fff !important;
  }
  .grey-top-block .tabs--rounded a.tabs-link-more {
    background-color: transparent !important;
  }
}

.grey-top-block .btn--grey {
  background-color: #fff !important;
}

.section--subcategories {
  margin-top: 50px;
  position: relative;
  padding: 0;
  background-color: #fff;
}

.section--subcategories:first-child {
  margin-top: 0;
}

.section--subcategories:before {
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  height: 40px;
  background-color: #fff;
  content: '';
}

@media (max-width: 1259px) {
  .section--subcategories:before {
    display: none;
  }
}

.section--subcategories:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e2e5e7;
  content: '';
}

@media (max-width: 767px) {
  .section--subcategories {
    margin-top: 30px;
  }
  .section--subcategories:before {
    height: 30px;
  }
}

.floatbar-track-subcategories {
  position: relative;
  z-index: 11;
  margin-top: 50px;
  padding-bottom: 50px;
  margin-bottom: -50px;
}

.floatbar-track-subcategories:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .floatbar-track-subcategories {
    margin-top: 30px;
  }
}

.floatbar-subcategories {
  margin-top: -10px;
  margin-bottom: -10px;
  min-height: 69px;
}

@media (max-width: 1259px) {
  .floatbar-subcategories {
    min-height: 52px;
  }
}

.floatbar-subcategories.is-fixed .floatbar-inner-subcategories, .floatbar-subcategories.is-bottom .floatbar-inner-subcategories {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform: none !important;
      -ms-transform: none !important;
          transform: none !important;
}

@media (max-width: 1259px) {
  .floatbar-subcategories.is-fixed .floatbar-inner-subcategories, .floatbar-subcategories.is-bottom .floatbar-inner-subcategories {
    top: 71px;
  }
}

.floatbar-inner-subcategories {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 11;
  will-change: transform;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.floatbar-subcategories.is-bottom.is-hw-scrolled-up .floatbar-inner-subcategories,
.floatbar-subcategories.is-fixed.is-hw-scrolled-up .floatbar-inner-subcategories {
  -webkit-transform: translate3d(0, 123px, 0) !important;
          transform: translate3d(0, 123px, 0) !important;
}

@media (max-width: 1259px) {
  .floatbar-subcategories.is-bottom.is-hw-scrolled-up .floatbar-inner-subcategories,
  .floatbar-subcategories.is-fixed.is-hw-scrolled-up .floatbar-inner-subcategories {
    -webkit-transform: translate3d(0, 0, 0) !important;
            transform: translate3d(0, 0, 0) !important;
  }
}

.section--faq {
  padding-top: 60px;
}

@media (max-width: 767px) {
  .section--faq {
    padding-top: 0;
  }
  .section--faq .tabs {
    margin-top: 0;
  }
}

.section--faq .tab-panes {
  margin-top: 60px;
}

@media (max-width: 1259px) {
  .section--faq .tab-panes {
    margin-top: 40px;
  }
}

.section--faq .tgbs {
  min-height: 235px;
}

.summary--faq {
  margin-top: 60px;
  line-height: 1.48;
}

@media (max-width: 767px) {
  .section-faq-title {
    display: none;
  }
}

.tgbs {
  margin: 35px -10px 0;
}

.tgbs:after {
  content: "";
  display: table;
  clear: both;
}

.tgbs:first-child {
  margin-top: 0;
}

.tgbs__inner {
  float: left;
  width: 100%;
  margin: -25px 0;
  font-size: 0;
}

@media (max-width: 1023px) {
  .tgbs__inner {
    margin-top: -35px;
    margin-bottom: -35px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .tgbs__inner {
    margin-top: -25px;
    margin-bottom: -25px;
  }
}

.tgb {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 25px 10px;
  font-size: 13px;
  line-height: 1.48;
  text-align: center;
}

@media (max-width: 1023px) {
  .tgb {
    padding-top: 35px;
    padding-bottom: 35px;
    width: 50%;
  }
}

@media (max-width: 767px) {
  .tgb {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 12px;
  }
}

.tgb__inner {
  display: block;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.tgb__icon {
  position: relative;
  font-size: 57px;
  line-height: 70px;
  color: #3d5360;
  margin-top: 30px;
}

.tgb__icon:first-child {
  margin-top: 0;
}

.tgb__icon .icon {
  width: 100%;
  vertical-align: bottom;
}

.tgb__icon .icon--truck {
  font-size: 43px;
}

.tgb__icon .icon--truck-clock {
  font-size: 63px;
}

.tgb__icon .icon--gift {
  font-size: 68px;
}

.tgb__icon .icon--money-hand {
  font-size: 66px;
}

.tgb__icon .icon--money-phone {
  font-size: 76px;
}

.tgb__icon .icon--invoice {
  font-size: 76px;
}

.tgb__title {
  margin-top: 25px;
  font-size: 17px;
  line-height: 1.29412;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #3d5360;
}

.tgb__title:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .tgb__title {
    font-size: 15px;
  }
}

.tgb__summary {
  margin-top: 10px;
}

.tgb__summary:first-child {
  margin-top: 0;
}

.tgb a {
  color: inherit;
  text-decoration: underline;
}

.main-banner {
  position: relative;
  background-color: #ef8fce;
  color: #fff;
  overflow: hidden;
}

.main-banner--in-slider {
  background-color: #ccc;
}

.main-banner a {
  color: #fff;
}

.main-banner h2, .main-banner .h2 {
  color: #fff;
}

@media (max-width: 767px) {
  .main-banner h2, .main-banner .h2 {
    font-size: 19px;
    line-height: 1.26316;
  }
}

.main-banner__over-link {
  display: block;
  text-decoration: none !important;
}

.main-banner__over-link:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  content: '';
}

.category-w-background_image--dark .main-banner {
  color: #2f2f2f;
}

.category-w-background_image--dark .main-banner a {
  color: #2f2f2f;
}

.category-w-background_image--dark .main-banner h2, .category-w-background_image--dark .main-banner .h2 {
  color: #3d5360;
}

.main-banner__image {
  background: center center no-repeat;
  background-size: auto 100%;
}

@media (max-width: 767px) {
  .main-banner__image {
    min-height: 260px;
    padding-bottom: 0;
  }
}

.main-banner__image img {
  visibility: hidden;
}

.main-banner__bg {
  height: 380px;
}

@media (max-width: 1023px) {
  .main-banner__bg {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .main-banner__bg {
    height: 62.5vw;
    min-height: 200px;
    max-height: 300px;
  }
}

.main-banner__bg img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.main-banner__bg--desktop {
  display: none;
}

@media (min-width: 768px) {
  .main-banner__bg--desktop {
    display: block;
  }
}

.main-banner__bg--mobile {
  display: none;
}

@media (max-width: 767px) {
  .main-banner__bg--mobile {
    display: block;
  }
}

.main-banner__overlay {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  line-height: 500px;
  margin-top: -250px;
  text-align: right;
}

@media (max-width: 767px) {
  .main-banner__overlay {
    position: static;
    top: 0;
    margin-top: 0;
    line-height: 1;
    bottom: 0;
  }
}

.main-banner__overlay-middle {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  line-height: 1.2857;
}

.main-banner__overlay-inner {
  display: inline-block;
  vertical-align: top;
  width: 680px;
  padding: 0 50px;
  text-align: center;
}

@media (max-width: 1259px) {
  .main-banner__overlay-inner {
    width: 430px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .main-banner__overlay-inner {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .main-banner__content {
    position: absolute;
    top: 30px;
    left: 50%;
    width: 280px;
    margin-left: -140px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.6), 0 0 7px rgba(0, 0, 0, 0.6);
  }
}

.main-banner__actions {
  margin-top: 35px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 5px;
  font-size: 0;
}

.main-banner__actions:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .main-banner__actions {
    margin-top: 0;
    background-color: #f4f4f4;
  }
}

.main-banner__actions .btn {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 195px;
  position: relative;
  z-index: 2;
}

@media (max-width: 1259px) {
  .main-banner__actions .btn {
    min-width: 170px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .main-banner__actions .btn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .main-banner__actions .btn--white {
    color: #fff !important;
    background-color: #3d5360 !important;
    border-color: #3d5360 !important;
  }
}

@media (max-width: 767px) {
  .main-banner__actions .btn--white-outline {
    color: #3d5360 !important;
    border-color: #3d5360 !important;
  }
}

@media (max-width: 767px) {
  .container--material-header {
    margin-top: 20px;
  }
  .container--material-header:first-child {
    margin-top: 0;
  }
}

.hbanner {
  position: relative;
  background-color: #3d5360;
  color: #fff;
  overflow: hidden;
}

.hbanner a {
  color: #fff;
}

.hbanner h2, .hbanner .h2 {
  color: #fff;
}

.hbanner__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  z-index: 0;
}

@media (max-width: 767px) {
  .hbanner__image {
    background-size: cover;
  }
}

.hbanner__image img {
  visibility: hidden;
}

.hbanner__overlay {
  position: relative;
  padding: 70px 0 80px;
  z-index: 1;
}

@media (max-width: 767px) {
  .hbanner__overlay {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.hbanner__summary {
  margin-top: 20px;
}

.hbanner__summary:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .hbanner__summary {
    margin-top: 15px;
  }
}

.hbanner__actions {
  margin-top: 35px;
  margin-left: -5px;
  margin-right: -5px;
  font-size: 0;
}

.hbanner__actions:first-child {
  margin-top: 0;
}

.hbanner__actions .btn {
  margin-left: 5px;
  margin-right: 5px;
  min-width: 195px;
}

.section--ctiles {
  padding-top: 70px;
}

.ctiles {
  margin: 40px -10px 0;
}

.ctiles:after {
  content: "";
  display: table;
  clear: both;
}

.ctiles:first-child {
  margin-top: 0;
}

.ctiles__inner {
  float: left;
  width: 100%;
  margin: -10px 0;
  font-size: 0;
}

.ctile {
  float: left;
  width: 33.33%;
  padding: 10px;
  font-size: 14px;
}

.ctile--horz {
  width: 66.66%;
}

.ctile--horz .ctile__inner {
  padding-bottom: 40.4%;
}

.ctile--vert .ctile__inner {
  padding-bottom: 171.3%;
}

.ctile--right {
  float: right;
}

.ctile__inner {
  display: block;
  position: relative;
  padding-bottom: 83%;
  background-color: #f4f4f4;
  color: #2f2f2f;
}

.ctile__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: right bottom no-repeat;
  background-size: cover;
}

.ctile__image:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.ctile__image img {
  visibility: hidden;
}

.ctile__overlay {
  position: absolute;
  top: 20px;
  right: 25px;
  bottom: 20px;
  left: 25px;
}

.ctile__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.388;
  color: #3d5360;
}

a.ctile__inner:hover .ctile__image:before {
  background-color: rgba(239, 143, 206, 0.2);
}

.more {
  margin-top: 45px;
}

.more:first-child {
  margin-top: 0;
}

.more .btn {
  min-width: 160px;
}

.more--pager {
  margin-top: 50px;
}

.more--pager .btn {
  min-width: 240px;
}

.section--tabbed-products {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media (max-width: 1259px) {
  .section--tabbed-products {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .section--tabbed-products {
    padding-top: 30px;
  }
}

.section--tabbed-products .more {
  margin-top: 85px;
}

.section--tabbed-products .more:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .section--tabbed-products .more {
    margin-top: 35px;
  }
}

@media (max-width: 767px) {
  .section--tabbed-products .more {
    margin-top: 20px;
  }
}

.section--main-jarticles {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media (max-width: 767px) {
  .section--main-jarticles {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section--main-jarticles .tabs--rounded {
  margin-left: -10px;
  margin-right: -10px;
}

@media (max-width: 1259px) {
  .section--main-jarticles .tabs--rounded {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.section--main-jarticles .tabs--rounded li {
  padding: 0 10px;
}

@media (max-width: 1259px) {
  .section--main-jarticles .tabs--rounded li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.section--main-jarticles .tabs--rounded a:hover {
  background-color: #fff !important;
}

@media (max-width: 767px) {
  .section--main-jarticles .tabs--rounded a {
    background-color: #fff !important;
  }
  .section--main-jarticles .tabs--rounded a.tabs-link-more {
    background-color: transparent !important;
  }
}

.section--main-jarticles .btn--grey {
  background-color: #fff !important;
}

@media (max-width: 767px) {
  .section--main-jarticles .more {
    margin-top: 35px;
  }
}

.swiper-slider--main {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .swiper-slider--main {
    margin-top: 0;
  }
}

.swiper-slider--main .swiper-container {
  border-radius: 5px;
}

@media (max-width: 767px) {
  .swiper-slider--main .swiper-container {
    border-radius: 0;
  }
}

@media (max-width: 767px) {
  .swiper-slider--main .swiper-button-prev,
  .swiper-slider--main .swiper-button-next {
    -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
            transform: translateY(-30px);
  }
}

@media (max-width: 479px) {
  .swiper-slider--main .swiper-button-prev,
  .swiper-slider--main .swiper-button-next {
    top: 31.25vw;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
}

.section--certificates {
  padding: 80px 0 90px;
}

.section--inside-material {
  margin-top: 50px;
  margin-left: -5000px;
  margin-right: -5000px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1259px) {
  .section--inside-material {
    margin-top: 30px;
  }
}

.section--inside-material:first-child {
  margin-top: -50px;
}

@media (max-width: 1259px) {
  .section--inside-material:first-child {
    margin-top: -30px;
  }
}

.section--inside-material:last-child {
  margin-bottom: -50px;
}

@media (max-width: 1259px) {
  .section--inside-material {
    padding-top: 30px;
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media (max-width: 767px) {
  .section--inside-material {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.swiper-slider--certificates {
  margin-top: 55px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  /*
    &.swiper-slider--start .swiper-container {

        &:before {
            opacity: 0;
        }
    }

    &.swiper-slider--end .swiper-container {

        &:after {
            opacity: 0;
        }
    }*/
}

@media (max-width: 1259px) {
  .swiper-slider--certificates {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.swiper-slider--certificates .swiper-slide {
  padding: 20px 0;
}

.swiper-slider--certificates .swiper-container:before, .swiper-slider--certificates .swiper-container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  content: '';
  z-index: 5;
  display: block;
  pointer-events: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.swiper-slider--certificates .swiper-container:before {
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#f4f4f4), color-stop(50%, rgba(244, 244, 244, 0.5)), to(rgba(244, 244, 244, 0)));
  background-image: -webkit-linear-gradient(left, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
  background-image: -o-linear-gradient(left, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
  background-image: linear-gradient(to right, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
}

.swiper-slider--certificates .swiper-container:after {
  right: 0;
  background-image: -webkit-gradient(linear, right top, left top, from(#f4f4f4), color-stop(50%, rgba(244, 244, 244, 0.5)), to(rgba(244, 244, 244, 0)));
  background-image: -webkit-linear-gradient(right, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
  background-image: -o-linear-gradient(right, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
  background-image: linear-gradient(to left, #f4f4f4 0%, rgba(244, 244, 244, 0.5) 50%, rgba(244, 244, 244, 0) 100%);
}

.pframe {
  position: relative;
  z-index: 0;
  max-width: 230px;
  margin: 20px auto 0;
}

.pframe:first-child {
  margin-top: 0;
}

.pframe__inner {
  display: block;
  position: relative;
  padding-bottom: 134.7826%;
  background-color: #fff;
  color: #3d5360;
  z-index: 0;
}

.pframe__inner:hover {
  color: #2f2f2f;
}

.pframe__inner:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../img/photo-frame.png") center center no-repeat;
  background-size: cover;
  content: '';
}

.pframe__inner:after {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: -2;
  content: '';
  -webkit-box-shadow: 8px -6px 14px rgba(0, 0, 0, 0.25);
          box-shadow: 8px -6px 14px rgba(0, 0, 0, 0.25);
}

.pframe__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 17px solid #eee;
  background: center center no-repeat;
  background-size: cover;
  -webkit-box-shadow: inset 2px 2px 7px rgba(0, 0, 0, 0.5);
          box-shadow: inset 2px 2px 7px rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.pframe__image img {
  visibility: hidden;
}

.pframe__icon {
  position: absolute;
  right: 0;
  bottom: 40px;
  left: 0;
  text-align: center;
  font-size: 28px;
  line-height: 1;
}

.section--cooks {
  padding: 70px 0 60px;
}

@media (max-width: 1259px) {
  .section--cooks {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .section--cooks {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.swiper-slider--cooks {
  margin-top: 50px;
  max-width: 942px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1259px) {
  .swiper-slider--cooks {
    max-width: none;
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media (max-width: 767px) {
  .swiper-slider--cooks {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.swiper-slider--cooks .swiper-container:before, .swiper-slider--cooks .swiper-container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  content: '';
  z-index: 5;
  display: block;
  pointer-events: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 1259px) {
  .swiper-slider--cooks .swiper-container:before, .swiper-slider--cooks .swiper-container:after {
    display: none;
  }
}

.swiper-slider--cooks .swiper-container:before {
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(white), color-stop(50%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.swiper-slider--cooks .swiper-container:after {
  right: 0;
  background-image: -webkit-gradient(linear, right top, left top, from(white), color-stop(50%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(right, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(right, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.swiper-slider--cooks.swiper-slider--start .swiper-container:before {
  opacity: 0;
}

.swiper-slider--cooks.swiper-slider--end .swiper-container:after {
  opacity: 0;
}

.swiper-slider--cooks .swiper-slide {
  width: 314px;
}

@media (max-width: 1259px) {
  .swiper-slider--cooks .swiper-slide {
    width: 250px;
  }
}

@media (max-width: 767px) {
  .swiper-slider--cooks .swiper-slide {
    width: 220px;
  }
}

.swiper-slider--cooks .swiper-button-prev,
.swiper-slider--cooks .swiper-button-next {
  margin-top: 40px;
}

.swiper-slider--cooks .cook {
  -webkit-transform: scale(0.77) translateY(8%) translateZ(0);
          transform: scale(0.77) translateY(8%) translateZ(0);
  opacity: 0.7;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

@media (max-width: 1259px) {
  .swiper-slider--cooks .cook {
    -webkit-transform: scale(1) translateZ(0);
            transform: scale(1) translateZ(0);
    opacity: 1;
  }
}

.swiper-slider--cooks .cook:hover {
  opacity: 0.9;
}

@media (max-width: 1259px) {
  .swiper-slider--cooks .cook:hover {
    opacity: 1;
  }
}

.swiper-slider--cooks .swiper-slide-active .cook, .swiper-slider--cooks .swiper-slide-duplicate-active .cook {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  cursor: default;
}

.cooks {
  margin-top: 70px;
  margin-left: -10px;
  margin-right: -10px;
}

.cooks:first-child {
  margin-top: 0;
}

.cooks:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .cooks {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .cooks {
    margin-top: 40px;
  }
}

.cooks__inner {
  float: left;
  width: 100%;
  margin-top: -20px;
  margin-bottom: -20px;
  font-size: 0;
}

.cook {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 20px 10px;
  font-size: 14px;
}

@media (max-width: 1023px) {
  .cook {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .cook {
    width: 100%;
  }
}

.cook__inner {
  text-align: center;
}

.cook__image {
  height: 360px;
  line-height: 360px;
}

@media (max-width: 1259px) {
  .cook__image {
    height: 270px;
    line-height: 270px;
  }
}

@media (max-width: 767px) {
  .cook__image {
    height: 240px;
    line-height: 240px;
  }
}

.cook__image img {
  display: inline-block;
  vertical-align: bottom;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid #efefef;
}

.cook__content {
  margin-top: 25px;
}

.cook__content:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .cook__content {
    margin-top: 15px;
    font-size: 13px;
  }
}

.cook__content a {
  color: #fff;
  text-decoration: underline;
}

.cook__content a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.cook__title {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 700;
}

.cook__title:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .cook__title {
    font-size: 16px;
  }
}

.cook__subtitle {
  font-size: 13px;
  font-style: italic;
  color: #01505f;
  margin-top: 15px;
}

.cook__subtitle:first-child {
  margin-top: 0;
}

.cook__summary {
  margin-top: 20px;
  line-height: 1.5;
}

.cook__summary:first-child {
  margin-top: 0;
}

.cook--in-slider {
  display: block;
  width: auto;
  padding: 0;
}

@media (max-width: 1259px) {
  .cook--in-slider {
    padding: 0 15px;
  }
}

.cook--in-slider .cook__content {
  margin-top: 0;
  padding: 25px 30px;
  background-color: #3d5360;
  color: #fff;
  font-size: 13px;
  line-height: 1.2;
}

@media (max-width: 1259px) {
  .cook--in-slider .cook__content {
    padding: 25px 10px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .cook--in-slider .cook__content {
    padding: 15px 10px;
  }
}

.cook--in-slider .cook__content a {
  color: #fff;
  text-decoration: underline;
}

.cook--in-slider .cook__content a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.cook--in-slider .cook__image img {
  border: none;
}

.cook--in-slider .cook__title {
  font-size: 20px;
}

@media (max-width: 1259px) {
  .cook--in-slider .cook__title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .cook--in-slider .cook__title {
    font-size: 16px;
  }
}

.cook--in-slider .cook__subtitle {
  color: inherit;
  font-size: inherit;
  margin-top: 5px;
}

.cook--in-slider .cook__subtitle:first-child {
  margin-top: 0;
}

.cook--in-slider .cook__summary {
  line-height: 1.2;
  margin-top: 5px;
}

.cook--in-slider .cook__summary:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .cook--in-slider .cook__summary {
    display: none;
  }
}

.section--order-map {
  padding: 75px 0 0;
}

@media (max-width: 1259px) {
  .section--order-map {
    padding-top: 50px;
  }
}

.section--order-map .tabs {
  margin-top: 40px;
}

.section--order-map .tabs:first-child {
  margin-top: 0;
}

.section--order-map .tabs li.active a {
  background-color: #fff;
}

.section--order-map .map {
  min-height: 280px;
  max-height: 700px;
  height: 80vh;
  margin-top: 30px;
}

.section--order-map .map:first-child {
  margin-top: 0;
}

.map-wrapper--contacts {
  margin-left: -2000px;
  margin-right: -2000px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.map-wrapper--contacts:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .map-wrapper--contacts {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.map-wrapper--contacts:last-child {
  margin-bottom: 0;
}

.map-wrapper--contacts .map {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 500px;
  max-height: 80vh;
}

.swiper-slider--steps {
  margin: 0;
  background-color: #111;
  color: #fff;
  overflow: hidden;
}

.swiper-slider--steps .swiper-slide {
  background: center center no-repeat;
  background-size: cover;
  text-align: center;
  max-height: 940px;
  padding-top: 66.66%;
}

@media (max-width: 1259px) {
  .swiper-slider--steps .swiper-slide {
    padding-bottom: 130px;
    min-height: 750px;
  }
}

@media (max-width: 900px) {
  .swiper-slider--steps .swiper-slide {
    padding-bottom: 280px;
  }
}

.swiper-slider--steps .swiper-slide:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
  content: '';
}

.swiper-slider--steps .swiper-slide > img {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: auto;
}

.swiper-slider--steps .swiper-slide.img-to-bg > img {
  visibility: hidden;
}

@media (max-width: 1259px) {
  .swiper-slider--steps .swiper-slide.img-to-bg > img {
    display: none;
  }
}

.swiper-slider--steps .swiper-slide__overlay {
  position: absolute;
  top: 400px;
  right: 0;
  left: 0;
}

@media (max-width: 1259px) {
  .swiper-slider--steps .swiper-slide__overlay {
    top: 150px;
  }
}

.swiper-slider--steps .swiper-slide__overlay-inner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  line-height: 1.47;
}

.swiper-slider--steps .swiper-slide-active .bstep__number,
.swiper-slider--steps .swiper-slide-active .bstep__title,
.swiper-slider--steps .swiper-slide-active .bstep__summary {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0.3s, 0s;
          -o-transition-delay: 0.3s, 0s;
     transition-delay: 0.3s, 0s;
}

.swiper-slider--steps .swiper-slide-active .bstep__title {
  -webkit-transition-delay: 0.3s, 0s;
          -o-transition-delay: 0.3s, 0s;
     transition-delay: 0.3s, 0s;
}

.swiper-slider--steps .swiper-slide-active .bstep__summary {
  -webkit-transition-delay: 0.4s, 0s;
          -o-transition-delay: 0.4s, 0s;
     transition-delay: 0.4s, 0s;
}

.swiper-slider--steps .bstep__inner {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

.swiper-slider--steps .bstep__number, .swiper-slider--steps .bstep__title, .swiper-slider--steps .bstep__summary {
  opacity: 0;
  -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-transition: all 0.8s, color 0.2s;
  -o-transition: all 0.8s, color 0.2s;
  transition: all 0.8s, color 0.2s;
  -webkit-transition-delay: 0.5s, 0s;
          -o-transition-delay: 0.5s, 0s;
     transition-delay: 0.5s, 0s;
  will-change: opacity, transform;
}

.step-pagination-title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 100px 0 0;
  z-index: 2;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
}

@media (max-width: 1259px) {
  .step-pagination-title {
    padding-top: 70px;
  }
}

.step-pagination-title h2, .step-pagination-title .h2 {
  color: #fff;
}

.step-pagination {
  position: absolute;
  top: 105px;
  right: 0;
  left: 0;
  padding: 100px 0 0;
  z-index: 2;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
}

@media (max-width: 1259px) {
  .step-pagination {
    top: auto;
    bottom: 60px;
    padding: 0;
  }
}

.step-pagination__inner {
  margin-top: 60px;
  margin-left: 60px;
  margin-right: 60px;
}

.step-pagination__inner:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .step-pagination__inner {
    margin-left: -25px;
    margin-right: -25px;
    height: 200px;
    overflow: hidden;
  }
}

.step-pagination__inner2 {
  position: relative;
  padding-top: 20px;
}

@media (max-width: 1259px) {
  .step-pagination__inner2 {
    padding-left: 110px;
    padding-right: 110px;
    overflow-y: hidden;
    overflow-x: auto;
    height: 220px;
  }
}

.step-pagination__inner2:before {
  position: absolute;
  top: 35px;
  right: -20px;
  left: -20px;
  z-index: -1;
  height: 20px;
  background-color: #ef8fce;
  border-radius: 100px;
  content: '';
}

@media (max-width: 1259px) {
  .step-pagination__inner2:before {
    display: none;
  }
}

.step-pagination .swiper-pagination {
  position: static;
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media (max-width: 1259px) {
  .step-pagination .swiper-pagination {
    display: inline-block;
    vertical-align: top;
    width: auto;
    white-space: nowrap;
  }
  .step-pagination .swiper-pagination:before {
    position: absolute;
    top: 15px;
    right: -20px;
    left: -20px;
    z-index: -1;
    height: 20px;
    background-color: #ef8fce;
    border-radius: 100px;
    content: '';
  }
}

.step-pagination .swiper-pagination-bullet {
  display: table-cell;
  vertical-align: top;
  height: auto;
  background: none;
  border-radius: 0;
  opacity: 1;
}

@media (max-width: 1259px) {
  .step-pagination .swiper-pagination-bullet {
    display: inline-block;
    width: 190px;
    white-space: normal;
  }
}

.step-pagination .swiper-pagination-bullet-active .swiper-pagination-name,
.step-pagination .swiper-pagination-bullet-active .swiper-pagination-summary {
  color: #fff;
}

.step-pagination .swiper-pagination-bullet-active .swiper-pagination-name:before {
  -webkit-transform: scale(1.4);
      -ms-transform: scale(1.4);
          transform: scale(1.4);
}

.step-pagination .swiper-pagination-bullet:hover .swiper-pagination-name:before {
  -webkit-transform: scale(1.4);
      -ms-transform: scale(1.4);
          transform: scale(1.4);
}

.step-pagination .swiper-pagination-name {
  position: relative;
  z-index: 0;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  font-size: 25px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.step-pagination .swiper-pagination-name:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ef8fce;
  border-radius: 50%;
  content: '';
  z-index: -1;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.step-pagination .swiper-pagination-summary {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  margin: 35px auto 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.step-pagination .swiper-pagination-summary:first-child {
  margin-top: 0;
}

.bstep {
  text-align: center;
}

.bstep__inner {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  line-height: 1.2;
  letter-spacing: 0.01em;
  text-decoration: none;
}

.bstep__number {
  font-size: 124px;
  font-weight: 600;
}

@media (max-width: 1259px) {
  .bstep__number {
    font-size: 100px;
  }
}

.bstep__title {
  font-size: 34px;
  font-weight: 600;
}

@media (max-width: 1259px) {
  .bstep__title {
    font-size: 24px;
    margin-top: 5px;
  }
  .bstep__title:first-child {
    margin-top: 0;
  }
}

.bstep__summary {
  font-size: 18px;
  font-weight: 600;
  margin-top: 35px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.bstep__summary:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .bstep__summary {
    font-size: 15px;
    margin-top: 30px;
  }
}

.social {
  margin-top: 10px;
  margin-right: -25px;
  font-size: 0;
  white-space: nowrap;
}

.social:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .social {
    margin-right: -35px;
  }
}

.social__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 27px;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 25px;
}

@media (max-width: 1259px) {
  .social__item {
    margin-right: 35px;
  }
}

.social__item .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.social__item .icon + .icon {
  opacity: 0;
}

.social__item:hover .icon {
  opacity: 0;
}

.social__item:hover .icon + .icon {
  opacity: 1;
}

.plitka {
  display: block;
  margin: 20px 0 0;
  font-size: 0;
}

.plitka:after {
  content: "";
  display: table;
  clear: both;
}

.plitka:first-child {
  margin-top: 0;
}

.plitka-inner {
  float: left;
  width: 100%;
  margin: -10px 0;
}

.plitka--more-space .plitka-inner {
  margin-top: -20px;
  margin-bottom: -20px;
}

.plitka-separator {
  display: none;
}

.plitka--4 .plitka-item {
  width: 33.33%;
}

@media (max-width: 1259px) {
  .plitka--4 .plitka-item {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .plitka--4 .plitka-item {
    width: 50%;
  }
}

.plitka--4 .plitka-item.to-hidden {
  display: none;
  /*
            &:nth-child(3) {

                @media (max-width: $mobile) {
                    display: none;
                }
            }*/
}

.plitka--4 .plitka-item.to-hidden:nth-child(1), .plitka--4 .plitka-item.to-hidden:nth-child(2), .plitka--4 .plitka-item.to-hidden:nth-child(3), .plitka--4 .plitka-item.to-hidden:nth-child(4), .plitka--4 .plitka-item.to-hidden:nth-child(5), .plitka--4 .plitka-item.to-hidden:nth-child(6) {
  display: inline-block;
}

@media (max-width: 767px) {
  .plitka--4 .plitka-item.to-hidden:nth-child(3) {
    display: none;
  }
}

@media (max-width: 1259px) {
  .plitka--4 .plitka-item.to-hidden:nth-child(4) {
    display: none;
  }
}

@media (max-width: 767px) {
  .plitka--4 .plitka-item.to-hidden:nth-child(4) {
    display: inline-block;
  }
}

.plitka--4.to-hidden-active .plitka-item.to-hidden {
  display: inline-block;
}

.plitka-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 10px 0;
  margin: 0;
  list-style: none;
  text-align: center;
  color: #465c6a;
}

.plitka-item--more-space {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 1259px) {
  .plitka-item {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .plitka-item {
    width: 50%;
  }
}

.swiper-slide .plitka-item {
  display: block;
  width: auto;
  max-width: 300px !important;
  margin-left: auto;
  margin-right: auto;
}

.plitka-item--actions-on-hover {
  height: 510px;
}

@media (max-width: 1259px) {
  .plitka-item--actions-on-hover {
    height: auto;
  }
}

.plitka-item--actions-on-hover .plitka-actions {
  display: none;
}

@media (max-width: 1259px) {
  .plitka-item--actions-on-hover .plitka-actions {
    display: block;
  }
}

.plitka-item--actions-on-hover .plitka-item-inner:hover .plitka-actions {
  display: block;
}

.plitka-item-inner {
  position: relative;
  padding: 15px;
  font-size: 13px;
  line-height: 1.3;
  z-index: 0;
  background-color: #fff;
}

@media (max-width: 767px) {
  .plitka-item-inner {
    padding: 15px;
  }
}

@media (max-width: 374px) {
  .plitka-item-inner {
    padding: 10px;
  }
}

.plitka-item-inner:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid transparent;
  content: "";
  z-index: -1;
}

.plitka-item-inner:hover {
  z-index: 1;
}

.plitka-item-inner:hover:before {
  border-color: #d9d9d9;
}

@media (max-width: 1259px) {
  .plitka-item-inner:hover:before {
    border-color: transparent;
  }
}

.plitka-item-inner:hover .plitka-description {
  display: block;
}

@media (max-width: 1259px) {
  .plitka-item-inner:hover .plitka-description {
    display: none;
  }
}

.plitka-item-stickers {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.plitka-item-favorites {
  font-size: 23px !important;
  color: #cfcfcf !important;
}

.plitka-item a {
  color: #465c6a;
  text-decoration: none;
}

.plitka-item a:hover {
  color: #3d5360;
}

.plitka-name-block {
  margin-top: 15px;
}

.plitka-name-block:first-child {
  margin-top: 0;
}

.btn--favorite .btn__over {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.btn--favorite .btn__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  text-align: center;
}

.btn--favorite .btn__inner.inactive-state {
  -webkit-transition: all 0.2s, visibility 0.2s;
  -o-transition: all 0.2s, visibility 0.2s;
  transition: all 0.2s, visibility 0.2s;
}

.btn--favorite .btn__inner.active-state {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.btn--favorite:hover .btn__inner.inactive-state {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.btn--favorite:hover .btn__inner.active-state {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
     transition-delay: 0s;
}

.btn--favorite .btn__over--add {
  z-index: 1;
  opacity: 1;
}

.btn--favorite.fav-added .btn__over--add {
  opacity: 0;
}

.btn--favorite.fav-added .btn__over--remove {
  z-index: 2;
  opacity: 1;
}

/*

.saveForLater {
    position: absolute;
    bottom: calc(var(--save-for-later-bottom-pos) + var(--save-for-later-gutter));
    right: var(--save-for-later-gutter);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    color: var(--black);
    border: none;
    background-color: color(var(--white) a(80%));
    padding: 10px 8px 8px;
    cursor: default;
    outline: none;

    & svg {
        pointer-events: none;
    }

    &:focus,
    &:hover {
        box-shadow: 0 2px 4px 0 color(var(--black) a(14%));
    }

    & span:first-child {
        width: 20px;
        height: 18px;
    }

    & use {
        fill: transparent;
    }

    &:hover use {
        fill: var(--black);
    }
}

.saveForLater__saved {
    & svg {
        animation: bounce cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.2s forwards 1;
    }

    & use {
        fill: var(--black);
    }

    & .flutter:nth-of-type(2) {
        animation: float ease-out 2s forwards 1;
    }

    & .flutter:nth-of-type(3) {
        animation: float2 ease-out 2s forwards 1;
    }

    & .flutter:nth-of-type(4) {
        animation: float3 ease-out 2s forwards 1;
    }
}

@keyframes bounce {
    30% {
        transform: scale(1.4);
    }

    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes float {
    0% {
        transform: scale(0.5);
        visibility: visible;
    }

    100% {
        transform:
            translate(24px, -26px)
            scale(0.5);
        opacity: 0;
    }
}

@keyframes float2 {
    0% {
        transform: scale(0.5);
        visibility: visible;
    }

    100% {
        transform:
            translate(8px, -31px)
            scale(0.5);
        opacity: 0;
    }
}

@keyframes float3 {
    0% {
        transform: scale(0.5);
        visibility: visible;
    }

    100% {
        transform:
            translate(28px, -30px)
            scale(0.5);
        opacity: 0;
    }
}

@supports (color: var(--black, blue)) {
    @keyframes float {
        0% {
            transform: scale(0.5);
            visibility: visible;
        }

        100% {
            transform:
                translate(
                        calc(var(--translateX) * -1px),
                        calc(var(--translateY) * -1px)
                )
                scale(0.5);
            opacity: 0;
        }
    }

    @keyframes float2 {
        0% {
            transform: scale(0.5);
            visibility: visible;
        }

        100% {
            transform:
                translate(
                        calc(var(--translateX) * -1px),
                        calc(var(--translateY) * -1px)
                )
                scale(0.5);
            opacity: 0;
        }
    }

    @keyframes float3 {
        0% {
            transform: scale(0.5);
            visibility: visible;
        }

        100% {
            transform:
                translate(
                        calc(var(--translateX) * -1px),
                        calc(var(--translateY) * -1px)
                )
                scale(0.5);
            opacity: 0;
        }
    }
}*/
.plitka-image {
  position: relative;
  display: block;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f8f8f8;
  text-align: center;
}

.plitka-image--transparent {
  background-color: transparent;
}

.plitka-image__inner {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
}

.plitka-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  line-height: 1;
}

img[data-lazy-src] {
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

img[data-lazy-src].lazy-load-inited {
  opacity: 1;
}

.plitka-name {
  display: block;
  font-size: 15px;
  line-height: 1.233;
  color: #333;
  height: 36px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

@media (max-width: 1259px) {
  .plitka-name {
    font-size: 13px;
    height: 32px;
  }
}

.plitka-badges {
  position: absolute;
  top: 15px;
  left: 15px;
  pointer-events: none;
}

.plitka-badge {
  display: block;
  clear: both;
  float: left;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #3d5360;
  background-color: #f4f4f4;
  border-radius: 0;
  padding: 4px 10px;
  margin-top: 5px;
}

.plitka-badge:first-child {
  margin-top: 0;
}

.plitka-badge.label-primary {
  color: #ef8fce;
}

.plitka-price {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #465c6a;
  margin-top: 5px;
}

.plitka-price:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .plitka-price {
    font-size: 15px;
  }
}

.plitka-price.have-oldprice-tile {
  color: #ef8fce;
  margin-right: 10px;
}

.plitka-old-price {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  color: #666;
  text-decoration: line-through;
}

.plitka-actions {
  margin-top: 20px;
  font-size: 0;
}

.plitka-actions:first-child {
  margin-top: 0;
}

.plitka-actions .btn + .btn {
  margin-left: 10px;
}

.plitka-description {
  display: none;
  margin-top: 15px;
  letter-spacing: 0.1em;
}

.plitka-description:first-child {
  margin-top: 0;
}

.plitka-description--always {
  display: block;
}

.plitka-item-edit {
  margin-top: 20px;
}

.btn .icon--heart {
  color: #ef8fce;
  font-size: 16px;
  vertical-align: middle;
}

.btn .icon--cart {
  font-size: 18px;
}

.btn--plitka {
  min-width: 90px;
  height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.bcontainer {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1259px) {
  .bcontainer {
    padding-top: 30px;
  }
}

.module-MainController .bcontainer {
  padding-top: 0;
  padding-bottom: 0;
}

.module-ProductsController .bcontainer {
  padding-top: 50px;
  padding-bottom: 25px;
}

@media (max-width: 1259px) {
  .module-ProductsController .bcontainer {
    padding-top: 30px;
  }
}

.module-ProductController .bcontainer {
  padding-top: 25px;
  padding-bottom: 25px;
}

.page-404 .bcontainer {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1259px) {
  .page-404 .bcontainer {
    padding-top: 30px;
  }
}

.category-top-block + .bcontainer {
  padding-top: 0;
}

.bwrapper {
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media (max-width: 9999px) {
  .bwrapper {
    display: block;
  }
}

@media (max-width: 9999px) {
  .module-ProductsController .bwrapper--w-filter {
    padding-top: 30px;
  }
}

.bsidebar {
  display: table-cell;
  vertical-align: top;
  width: 320px;
  padding-right: 60px;
}

@media (max-width: 9999px) {
  .bsidebar {
    display: block;
    position: absolute;
    top: -1px;
    top: -55px;
    left: 0;
    width: 300px;
    padding-right: 0;
    z-index: 10;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media (max-width: 1259px) {
  .bsidebar {
    width: 260px;
  }
}

@media (max-width: 767px) {
  .bsidebar {
    width: 100%;
    top: -31px;
  }
}

.bcontent {
  display: table-cell;
  vertical-align: top;
}

@media (max-width: 9999px) {
  .bcontent {
    display: block;
  }
}

.sortbar {
  margin-left: 250px;
  margin-top: 50px;
  text-align: right;
}

.sortbar:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .sortbar {
    display: none;
  }
}

.sortlinks {
  font-size: 0;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.sortlinks:first-child {
  margin-top: 0;
}

.sortlinks li {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.sortlinks .btn {
  font-weight: 400;
  padding: 7px 12px;
  border-radius: 10px;
  height: auto;
}

.sortlinks .btn.current {
  font-weight: 600;
  background-color: #f4f4f4 !important;
  cursor: default;
  pointer-events: none;
}

.sortlinks__title .btn {
  font-weight: 600;
  padding-left: 0;
  padding-right: 6px;
}

@media (max-width: 1259px) {
  .sortlinks__title .btn {
    margin-right: 20px;
  }
}

.sortlinks i {
  display: none;
}

.section--products-top {
  padding-top: 60px;
  padding-bottom: 0;
}

@media (max-width: 1259px) {
  .section--products-top {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .section--products-top ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

@media (max-width: 767px) {
  .section--products-top .tabs {
    font-size: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 32px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .section--products-top .tabs ul {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: -20px;
    padding-bottom: 20px;
    white-space: nowrap;
    height: auto;
    width: auto;
    display: block;
    padding-left: 7px;
    padding-right: 7px;
  }
  .section--products-top .tabs li {
    margin-bottom: 0;
    vertical-align: top;
  }
}

.more--categories {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .more--categories {
    display: none;
  }
}

.categories {
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: 50px;
}

.categories:first-child {
  margin-top: 0;
}

.categories__title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
}

.categories__title:first-child {
  margin-top: 0;
}

.categories__content {
  margin-top: 20px;
  margin-right: -30px;
  font-size: 0;
  letter-spacing: 0;
}

.categories__content:first-child {
  margin-top: 0;
}

.categories__content i {
  display: none !important;
}

.categories__content ol {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
}

.categories__content > ol {
  /*display: inline-block;
            vertical-align: top;
            column-count: 5;
            column-gap: 30px;*/
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  margin: 0;
  padding: 0 30px 0 0;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.categories__content li {
  margin: 0 0 10px;
  padding: 0;
  /*
                        -webkit-column-break-inside: avoid;
                        column-break-inside: avoid;
                        page-break-inside: avoid;
                        break-inside: avoid-column;*/
}

.categories__content li.menu-category-toggler {
  padding-top: 10px;
}

.categories__content a {
  color: #3d5360;
  text-decoration: none;
}

.categories__content a:hover {
  color: #2f2f2f;
}

.categories__footer {
  margin-top: 20px;
}

.categories__footer:first-child {
  margin-top: 0;
}

.pagination-cols {
  margin-top: 80px;
  display: table;
  width: 100%;
  direction: rtl;
}

.pagination-cols:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .pagination-cols {
    margin-top: 35px;
  }
}

@media (max-width: 767px) {
  .pagination-cols {
    display: block;
    text-align: center;
  }
}

.pagination-col {
  display: table-cell;
  vertical-align: top;
  direction: ltr;
}

@media (max-width: 767px) {
  .pagination-col {
    display: block;
    margin-top: 30px;
  }
  .pagination-col:first-child {
    margin-top: 0;
  }
}

.pagination-list-wrapper {
  margin-top: 80px;
  text-align: right;
}

.pagination-list-wrapper:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .pagination-list-wrapper {
    margin-top: 35px;
  }
}

@media (max-width: 767px) {
  .pagination-list-wrapper {
    text-align: center;
  }
}

.pagination {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

.pagination > li {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  letter-spacing: 0.1em;
}

.pagination > li > a, .pagination > li > span {
  float: none;
  display: inline;
  padding: 5px;
  border: none;
  color: #3d5360 !important;
  background: none !important;
}

.pagination > li.active {
  pointer-events: none;
  font-weight: 700;
}

.farticle {
  margin-top: 40px;
}

.farticle:first-child {
  margin-top: 0;
}

.farticle .expand-it-container {
  position: relative;
  max-height: 170px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.farticle .expand-it-container:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 70px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0), white);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  content: '';
  pointer-events: none;
  -webkit-transition: all 0.4s, visibility 0.4s;
  -o-transition: all 0.4s, visibility 0.4s;
  transition: all 0.4s, visibility 0.4s;
}

.farticle .expand-it-container.active:before {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s, visibility 0s 0.4s;
  -o-transition: all 0.4s, visibility 0s 0.4s;
  transition: all 0.4s, visibility 0s 0.4s;
}

.farticle .expand-it-inner:after {
  content: "";
  display: table;
  clear: both;
}

.farticle__blocks {
  margin: 0 -30px;
}

.farticle__blocks:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1259px) {
  .farticle__blocks {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.farticle__blocks-inner {
  float: left;
  width: 100%;
  margin: -20px 0;
  padding-top: 30px;
  font-size: 0;
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
}

@media (max-width: 1259px) {
  .farticle__blocks-inner {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    margin-top: -10px;
    margin-bottom: -10px;
  }
}

@media (max-width: 767px) {
  .farticle__blocks-inner {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
}

.farticle__block {
  padding: 20px 30px;
  font-size: 13px;
  line-height: 1.385;
  color: #666;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  page-break-inside: avoid;
       break-inside: avoid-column;
}

@media (max-width: 1259px) {
  .farticle__block {
    font-size: 12px;
    padding: 10px 20px;
  }
}

.farticle__title {
  position: relative;
  padding-left: 15px;
}

.farticle__title:before {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: -6px;
  border-left: 6px solid #ef8fce;
  content: '';
}

.farticle__title .farticle__subtitle {
  margin-top: -27px;
}

.farticle__subtitle {
  font-size: 15px;
  font-weight: 700;
  color: #ef8fce;
}

@media (max-width: 1259px) {
  .farticle__subtitle {
    font-size: 14px;
  }
}

.farticle__subtitle + * {
  margin-top: 7px;
}

.farticle__more {
  margin-top: 30px;
  text-align: center;
}

.farticle__more:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .farticle__more {
    margin-top: 10px;
  }
}

.farticle h2, .farticle .h2 {
  color: #3d5360;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
}

@media (max-width: 1259px) {
  .farticle h2, .farticle .h2 {
    font-size: 16px;
  }
}

.alert {
  border-color: transparent !important;
  border-radius: 4px;
  letter-spacing: 0.01em;
  font-size: 12px;
  line-height: 1.42857143;
  padding: 10px 15px;
}

.product__top {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-top: -40px;
}

@media (max-width: 1259px) {
  .product__top {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .product__top {
    margin-top: 10px;
    display: block;
  }
}

.product__top-left {
  display: table-cell;
  vertical-align: top;
  padding-top: 40px;
}

@media (max-width: 1259px) {
  .product__top-left {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .product__top-left {
    display: block;
    padding-top: 0;
  }
}

.product__top-right {
  display: table-cell;
  vertical-align: top;
  width: 540px;
  padding-left: 70px;
}

@media (max-width: 1259px) {
  .product__top-right {
    padding-left: 25px;
    width: 310px;
  }
}

@media (max-width: 767px) {
  .product__top-right {
    display: block;
    padding-left: 0;
    width: auto;
    margin-top: 20px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.product__top-right .alert {
  margin: 15px 0 0 30px;
  max-width: 302px;
}

.product__top-right .alert:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__top-right .alert {
    margin-left: 0;
  }
}

.product__image {
  margin-top: 20px;
}

.product__image:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .product__image {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.product__image .swiper-slider {
  margin-top: 0;
}

.product__previews {
  margin-top: 20px;
}

.product__previews:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .product__previews {
    display: none;
  }
}

.product__nav {
  display: inline-block;
  color: #3d5360;
  font-size: 0;
}

.product__nav:hover .product__nav-icon {
  opacity: 1;
}

.product__nav:hover .product__nav-image {
  opacity: 0.8;
}

.product__nav--video {
  opacity: 0.23;
}

.product__nav--video:hover {
  opacity: 0.5;
}

.product__nav--video:hover .product__nav-image {
  opacity: 1;
}

.product__nav--video img {
  height: 84px;
  width: 84px;
}

@media (max-width: 419px) {
  .product__nav--video img {
    height: 64px;
    width: 64px;
  }
}

.product__nav-wrapper {
  margin-top: 20px;
}

.product__nav-wrapper:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__nav-wrapper {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1259px) {
  .product__nav-wrapper {
    max-width: 400px;
  }
}

.product__nav-list {
  display: table;
  width: 100%;
}

.product__nav-item {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.product__nav-item:last-child {
  text-align: right;
}

.product__nav-item:first-child {
  text-align: left;
}

.product__nav-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  margin-left: 105px;
  opacity: 0.6;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 1259px) {
  .product__nav-icon {
    margin-left: 20px;
  }
}

@media (max-width: 419px) {
  .product__nav-icon {
    margin-left: 10px;
  }
}

.product__nav-icon:first-child {
  margin-left: 0;
}

.product__nav-image {
  display: inline-block;
  vertical-align: middle;
  height: 96px;
  width: 96px;
  line-height: 96px;
  text-align: center;
  margin-left: 105px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 1259px) {
  .product__nav-image {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .product__nav-image {
    height: 84px;
    width: 84px;
    line-height: 84px;
  }
}

@media (max-width: 419px) {
  .product__nav-image {
    height: 64px;
    width: 64px;
    line-height: 64px;
    margin-left: 10px;
  }
}

.product__nav-image:first-child {
  margin-left: 0;
}

.product__title h1, .product__title .h1 {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 1259px) {
  .product__title h1, .product__title .h1 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .product__title h1, .product__title .h1 {
    font-size: 25px;
  }
}

.product__edit {
  margin-top: 20px;
}

.product__edit:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .product__edit {
    text-align: center;
  }
}

.product__price {
  margin-top: 10px;
}

.product__price:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__price {
    padding-top: 10px;
  }
}

.product__price-tip {
  margin-top: 5px;
}

.product__price-tip:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .product__price-tip {
    display: none;
  }
}

.product__price .buy-price,
.product__price .product-buy-price {
  display: inline-block;
  color: #3d5360;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 1259px) {
  .product__price .buy-price,
  .product__price .product-buy-price {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .product__price .buy-price,
  .product__price .product-buy-price {
    font-size: 25px;
  }
}

.product__price .buy-price-with-amount,
.product__price .product-buy-price-with-amount {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
}

@media (max-width: 1259px) {
  .product__price .buy-price-with-amount,
  .product__price .product-buy-price-with-amount {
    font-size: 15px;
  }
}

.product__price .buy-oldprice,
.product__price .product-buy-oldprice {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #2f2f2f;
  opacity: 0.5;
  text-decoration: line-through;
  margin-left: 15px;
}

@media (max-width: 1259px) {
  .product__price .buy-oldprice,
  .product__price .product-buy-oldprice {
    font-size: 15px;
    margin-left: 5px;
  }
}

.product__osections {
  margin-top: 30px;
}

.product__osections:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .product__osections {
    margin-top: 25px;
  }
}

.product__osection {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 30px;
}

.product__osection:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__osection {
    padding-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .product__osection {
    margin-top: 25px;
  }
}

.product__osection-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #3d5360;
  margin-top: 10px;
  margin-bottom: 0;
}

.product__osection-title:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__osection-title {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .product__osection-title {
    font-size: 18px;
    padding-left: 30px;
  }
}

.product__osection-sticker {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  background-color: #3d5360;
  color: #fff;
}

@media (max-width: 1259px) {
  .product__osection-sticker {
    display: none;
  }
}

@media (max-width: 767px) {
  .product__osection-sticker {
    display: block;
  }
}

.product__osection-tip {
  margin-top: 10px;
}

.product__osection-tip:first-child {
  margin-top: 0;
}

.product__osection-content {
  position: relative;
  margin-top: 15px;
}

.product__osection-content:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__osection-content {
    margin-right: -10px;
  }
}

@media (max-width: 767px) {
  .product__osection-content {
    margin-right: 0;
  }
}

.product__osection-item {
  margin-top: 10px;
  margin-bottom: 0;
}

.product__osection-item:first-child {
  margin-top: 0;
}

.product__osection .form-control {
  display: inline-block;
  vertical-align: top;
  width: 240px;
}

@media (max-width: 767px) {
  .product__osection .form-control {
    width: 100%;
  }
}

.product__osection .inputpicker-div {
  width: 240px !important;
}

@media (max-width: 767px) {
  .product__osection .inputpicker-div {
    width: 100% !important;
  }
  .product__osection .inputpicker-div .form-control {
    max-width: none !important;
  }
}

@media (max-width: 767px) {
  .product__osection .inputpicker-wrapped-list {
    width: 100% !important;
    max-height: 300px !important;
    height: auto !important;
    overflow: hidden !important;
    overflow-y: auto !important;
  }
}

@media (max-width: 767px) {
  .product__osection .form-control--input-picker {
    max-width: none !important;
  }
}

.product__osection .select2-container--default {
  max-width: 240px;
}

@media (max-width: 767px) {
  .product__osection .select2-container--default {
    width: 100% !important;
    max-width: none;
  }
}

@media (max-width: 767px) {
  .product__osection .form-control--w-btn {
    width: calc(100% - 53px);
  }
}

.product__actions {
  margin-top: 15px;
  padding-left: 30px;
}

.product__actions:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__actions {
    margin-right: -10px;
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .product__actions {
    margin-right: 0;
  }
}

.product__actions .btn + .btn {
  margin-left: 12px;
}

@media (max-width: 1259px) {
  .product__actions .btn + .btn {
    margin-left: 3px;
  }
}

.product__action {
  margin-top: 10px;
}

.product__action--favorite .btn {
  display: block;
  margin: 0 !important;
}

.product__action--favorite .btn--remove-from-fav {
  display: none;
}

.product__action--favorite.fav-added .btn--add-to-fav {
  display: none;
}

.product__action--favorite.fav-added .btn--remove-from-fav {
  display: block;
}

.product__action .btn {
  max-width: 240px;
}

@media (max-width: 767px) {
  .product__action .btn {
    max-width: none;
  }
}

.product__blocks {
  margin-top: 25px;
  padding-left: 30px;
}

.product__blocks:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .product__blocks {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .product__blocks {
    text-align: center;
  }
}

.product__blocks-more {
  margin-top: 15px;
}

.product__blocks-more:first-child {
  margin-top: 0;
}

.product__block {
  margin-top: 20px;
  font-size: 13px;
  line-height: 1.46;
}

.product__block:first-child {
  margin-top: 0;
}

.product__block-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 15px;
}

.product__block-title:first-child {
  margin-top: 0;
}

.product__block-content {
  margin-top: 10px;
}

.product__block-content:first-child {
  margin-top: 0;
}

.product__tabs {
  margin-top: 60px;
  padding-bottom: 60px;
}

.product__tabs:first-child {
  margin-top: 0;
}

.product__tabs:last-child {
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .product__tabs .tabs__inner {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (max-width: 767px) {
  .product__tabs .tabs li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .product__tabs .tabs a {
    width: 180px;
    white-space: normal;
  }
}

.pimage {
  position: relative;
  display: block;
  width: 100%;
  color: #3d5360;
}

.pimage__inner {
  position: relative;
  background: center center no-repeat;
  background-size: contain;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.pimage__inner.swiper-lazy {
  opacity: 0;
}

.pimage__inner.swiper-lazy img {
  display: none;
}

.pimage__inner.swiper-lazy-loaded {
  opacity: 1;
}

.pimage__inner.img-to-bg img {
  display: none;
}

.pimage__middle {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.pimage__icon {
  position: absolute;
  right: 0;
  bottom: 30px;
  left: 0;
  text-align: center;
  font-size: 28px;
  line-height: 1;
  color: #3d5360;
}

.pimage--large .pimage__inner {
  line-height: 600px;
  height: 600px;
}

.pimage--large .pimage__inner img {
  max-width: 100%;
  max-height: 600px;
}

@media (max-width: 1259px) {
  .pimage--large .pimage__inner {
    line-height: 420px;
    height: 420px;
  }
}

@media (max-width: 767px) {
  .pimage--large .pimage__inner {
    line-height: 1;
    line-height: 100vw;
    height: auto;
    height: 100vw;
    min-height: 320px;
    max-height: 420px;
  }
}

@media (max-width: 1259px) {
  .pimage--large .pimage__inner img {
    max-height: 420px;
  }
}

@media (max-width: 767px) {
  .pimage--large .pimage__inner img {
    max-height: 100%;
  }
}

@media (max-width: 767px) {
  .pimage--large .pimage__middle {
    display: block !important;
    height: 100%;
  }
}

@media (max-width: 767px) {
  .pimage--large .pimage__middle .zoomImg {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .pimage--large .pimage__icon {
    display: none;
  }
}

.pimage--preview {
  max-width: 96px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.pimage--preview .pimage__inner {
  line-height: 96px;
  height: 96px;
}

.pimage--preview:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 2px solid transparent;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.is-selected .pimage--preview {
  cursor: default;
  pointer-events: none;
}

.is-selected .pimage--preview:before {
  border-color: #3d5360;
}

.zoom-container {
  display: inline-block;
}

.swiper-slider--image {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.swiper-slider--previews {
  text-align: center;
}

@media (max-width: 1259px) {
  .swiper-slider--previews {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.swiper-slider--previews .swiper-container {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slider--previews .swiper-button-prev, .swiper-slider--previews .swiper-button-next {
  height: 22px;
  line-height: 22px;
  margin-top: -11px;
  color: rgba(61, 83, 96, 0.6);
}

.swiper-slider--previews .swiper-button-prev .icon, .swiper-slider--previews .swiper-button-next .icon {
  width: 12px;
  height: 22px;
}

.swiper-slider--previews .swiper-button-prev:hover, .swiper-slider--previews .swiper-button-next:hover {
  color: #3d5360;
}

.swiper-slider--previews .swiper-button-prev:before, .swiper-slider--previews .swiper-button-next:before {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: '';
}

.swiper-slider--previews .swiper-button-prev {
  left: 0;
  right: auto;
  margin-right: 0 !important;
}

.swiper-slider--previews .swiper-button-next {
  right: 0;
  left: auto;
  margin-left: 0 !important;
}

.link-bordered {
  font-size: 12px;
  font-weight: 600;
  color: #b7b7b7;
  border-bottom: 1px dashed;
  border-bottom-color: inherit;
  text-decoration: none !important;
}

.link-bordered:hover {
  color: #2f2f2f;
}

.filling-group.active .filling-label {
  display: none;
}

.filling-group.active .filling-name-selected {
  display: block;
}

.filling-group.active .filling-image-preview {
  display: inline-block;
}

.filling-group.active .filling-actions {
  opacity: 0;
  margin-left: -44px;
}

@media (max-width: 1259px) {
  .filling-group.active .filling-actions {
    margin-left: -51px;
  }
}

.filling-group > .form-control {
  display: inline-block;
}

.filling-actions {
  display: inline-block;
  vertical-align: top;
  margin-left: 12px;
}

@media (max-width: 1259px) {
  .filling-actions {
    margin-left: 3px;
  }
}

@media (max-width: 767px) {
  .filling-actions {
    float: right;
    margin-left: 0;
  }
}

.filling-name-selected {
  display: none;
}

.filling-image-preview {
  display: none;
  vertical-align: top;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 12px;
  width: 47px;
  height: 40px;
}

@media (max-width: 1259px) {
  .filling-image-preview {
    margin-left: 3px;
  }
}

.filling-image-preview img {
  display: block;
  height: auto;
  width: 100%;
}

.filling-name-selected {
  display: none;
}

#reviews-list {
  min-height: 42px;
}

.rating {
  min-height: 30px;
}

.vote-block {
  position: relative;
}

.vote-active {
  position: absolute;
  top: 0;
  left: 0;
}

.vote-stars, .vote-active {
  background-image: url("../img/stars.png") !important;
}

.vote-revote a {
  cursor: pointer;
}

.review-images {
  margin: 0;
  padding: 0;
}

.review-images li {
  display: inline-block;
  vertical-align: top;
  list-style: none;
  margin-top: 10px;
}

.review-images li a {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  display: block;
  height: 68px;
  width: 68px;
  line-height: 62px;
  padding: 2px;
  border-radius: 2px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

.review-images li a:hover {
  border: 1px solid #CCC;
}

.review-images li a img {
  max-width: 62px;
  max-height: 62px;
  margin-top: -2px;
}

.remove-review-image {
  border: none;
  border: none;
  background-color: #13131b;
  color: #fff;
  position: absolute;
  line-height: 1;
  display: block;
  margin-top: 1px;
  font-size: 12px;
  margin: 0px 0 0 47px;
  opacity: 0.5;
  padding: 1px 3px 2px;
  z-index: 300;
  cursor: pointer;
}

.remove-review-image:hover {
  opacity: 0.7;
}

.btn--product-add-image {
  margin-left: 12px;
}

@media (max-width: 1259px) {
  .btn--product-add-image {
    margin-left: 3px;
  }
}

@media (max-width: 767px) {
  .btn--product-add-image {
    float: right;
    margin-left: 0;
  }
}

.btn--product-add-image .icon {
  top: -1px;
  vertical-align: middle;
  font-size: 15px;
}

.tabs--product:before {
  border-top-color: #e2e5e7;
}

.modal-backdrop {
  min-height: 100%;
  min-height: 100vh;
  height: auto;
}

.modal-backdrop.in {
  opacity: 0.65;
}

.modal-sm {
  width: 340px;
}

.modal-md {
  width: 580px;
}

.modal-product {
  max-width: 940px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.modal-product .modal-body {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media (max-width: 767px) {
  .modal-product .modal-body {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 479px) {
  .modal-product .modal-body {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .modal {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.modal-dialog {
  z-index: 1050;
}

@media (max-width: 767px) {
  .modal-dialog {
    max-width: 345px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.modal-title {
  font-size: 18px;
  font-weight: 700;
  color: #3d5360;
  text-align: center;
}

.modal-title + .summary {
  margin-top: 10px;
}

.modal-content {
  border-color: transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 30px rgba(1, 67, 86, 0.4);
          box-shadow: 0 0 30px rgba(1, 67, 86, 0.4);
}

.modal-body {
  padding: 30px 50px;
}

@media (max-width: 767px) {
  .modal-body {
    padding: 20px 30px;
  }
}

.close {
  position: absolute;
  top: 14px;
  right: 17px;
  font-size: 11px;
  opacity: 0.3;
  z-index: 1;
}

.close:hover {
  opacity: 0.5;
}

.close:before {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  content: '';
}

.modal-header .close {
  position: static;
}

.modal--large .modal-dialog {
  width: 1180px;
}

@media (max-width: 1259px) {
  .modal--large .modal-dialog {
    width: 100%;
  }
}

.modal--large .modal-title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

@media (max-width: 767px) {
  .modal--large .modal-title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.01em;
  }
}

.modal--large .modal-content {
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.modal--large .modal-body {
  padding: 60px 100px;
}

@media (max-width: 767px) {
  .modal--large .modal-body {
    padding: 30px 30px;
  }
}

.modal--large .close {
  top: 32px;
  right: 43px;
  font-size: 14px;
  color: #3d5360;
  opacity: 1;
}

@media (max-width: 767px) {
  .modal--large .close {
    top: 20px;
    right: 20px;
  }
}

.itgbs {
  margin-top: 50px;
  margin-left: -10px;
  margin-right: -10px;
}

.itgbs:first-child {
  margin-top: 0;
}

.itgbs:after {
  content: "";
  display: table;
  clear: both;
}

.itgbs__inner {
  float: left;
  width: 100%;
  margin: -20px 0;
  font-size: 0;
}

.itgb {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 20px 10px;
  font-size: 14px;
  line-height: 1.464;
  text-align: center;
}

@media (max-width: 1259px) {
  .itgb {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .itgb {
    width: 50%;
  }
}

@media (max-width: 639px) {
  .itgb {
    display: block;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.itgb--2 {
  width: 50%;
}

@media (max-width: 767px) {
  .itgb--2 {
    width: 100%;
  }
}

.itgb__image {
  margin-top: 25px;
}

.itgb__image:first-child {
  margin-top: 0;
}

.itgb__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.333;
  margin-top: 25px;
  letter-spacing: 0.1em;
}

.itgb__title:first-child {
  margin-top: 0;
}

.itgb__title--bold {
  letter-spacing: 0.01em;
  font-weight: 700;
}

@media (max-width: 1259px) {
  .itgb__title {
    font-size: 15px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .itgb__title {
    margin-top: 20px;
  }
}

.itgb__content {
  margin-top: 25px;
}

.itgb__content:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .itgb__content {
    margin-top: 10px;
  }
}

.itgbs--faq .itgb {
  width: 100%;
  text-align: left;
  max-width: none;
}

.itgbs--faq .itgb__content {
  margin-top: 20px;
}

.itgbs--faq .itgb__content:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .itgbs--faq .itgb__content {
    margin-top: 15px;
  }
}

.itgbs--c {
  text-align: center;
}

.itgbs--c .itgb__content {
  margin-top: 20px;
}

.itgbs--c .itgb__content:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .itgbs--c .itgb__content {
    margin-top: 10px;
  }
}

.itgbs--tiers .itgbs__inner {
  text-align: justify;
}

@media (max-width: 767px) {
  .itgbs--tiers .itgbs__inner {
    text-align: center;
  }
}

.itgbs--tiers .itgbs__inner:after {
  display: inline-block;
  width: 100%;
  content: '';
}

@media (max-width: 767px) {
  .itgbs--tiers .itgbs__inner:after {
    display: none;
  }
}

.itgbs--tiers .itgb {
  width: auto;
}

@media (max-width: 1259px) {
  .itgbs--tiers .itgb {
    width: 20%;
  }
}

@media (max-width: 767px) {
  .itgbs--tiers .itgb {
    width: 100%;
  }
}

.itgbs--tiers .itgb__image {
  height: 300px;
  line-height: 300px;
}

@media (max-width: 1259px) {
  .itgbs--tiers .itgb__image {
    height: 250px;
    line-height: 250px;
  }
}

@media (max-width: 767px) {
  .itgbs--tiers .itgb__image {
    height: auto;
    line-height: 1;
  }
}

.itgbs--tiers .itgb__image img {
  display: inline-block;
  vertical-align: bottom;
  max-width: 154px;
}

@media (max-width: 1259px) {
  .itgbs--tiers .itgb__image img {
    max-width: 120px;
  }
}

.itgbs--tiers .itgb__title {
  font-size: 15px;
  line-height: 1.6;
  color: #3d5360;
  letter-spacing: 0.01em;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
}

.itgbs--tiers .itgb__title:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .itgbs--tiers .itgb__title {
    font-size: 13px;
    margin-left: -5px;
    margin-right: -5px;
  }
}

@media (max-width: 1023px) {
  .itgbs--tiers .itgb__title {
    font-size: 12px;
  }
}

.tgb-tiers-summary + .itgbs--tiers {
  margin-top: -25px;
}

@media (max-width: 767px) {
  .tgb-tiers-summary + .itgbs--tiers {
    margin-top: 30px;
  }
}

.tgb-tiers-summary {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.6;
  color: #3d5360;
  letter-spacing: 0.01em;
  margin-top: 25px;
}

.tgb-tiers-summary:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .tgb-tiers-summary {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .tgb-tiers-summary {
    text-align: center;
  }
}

.itgbs--price {
  color: #3d5360;
}

.itgbs--price .itgbs__inner {
  text-align: justify;
}

@media (max-width: 1259px) {
  .itgbs--price .itgbs__inner {
    text-align: center;
  }
}

.itgbs--price .itgbs__inner:after {
  display: inline-block;
  width: 100%;
  content: '';
}

.itgbs--price .itgb {
  width: auto;
}

@media (max-width: 1259px) {
  .itgbs--price .itgb {
    width: 33.33%;
  }
}

@media (max-width: 1023px) {
  .itgbs--price .itgb {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .itgbs--price .itgb {
    width: 100%;
  }
}

.itgbs--price .itgb__inner {
  max-width: 300px;
  text-align: left;
}

@media (max-width: 1259px) {
  .itgbs--price .itgb__inner {
    margin-left: auto;
    margin-right: auto;
  }
}

.itgbs--price .itgb__image {
  height: 215px;
  line-height: 215px;
  text-align: center;
}

.itgbs--price .itgb__image img {
  display: inline-block;
  vertical-align: bottom;
  max-width: 154px;
}

.itgbs--price .itgb__title {
  margin-top: 55px;
}

.itgbs--price .itgb__title:first-child {
  margin-top: 0;
}

.itgbs--price .itgb__content {
  margin-top: 15px;
}

.itgbs--price .itgb__content:first-child {
  margin-top: 0;
}

.itgbs--price .itgb strong {
  font-weight: 600;
}

.itgbs--price-2col {
  margin-left: -30px;
  margin-right: -30px;
}

.itgbs--price-2col .itgbs__inner {
  text-align: center;
}

.itgbs--price-2col .itgb {
  padding-left: 30px;
  padding-right: 30px;
}

.itgbs--price-2col .itgb__inner {
  max-width: 350px;
}

@media (max-width: 1259px) {
  .itgbs--price-2col .itgb__inner {
    max-width: 300px;
  }
}

.lfillings {
  margin-top: 80px;
  margin-left: -60px;
  margin-right: -60px;
}

.lfillings:first-child {
  margin-top: 0;
}

.lfillings:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1259px) {
  .lfillings {
    margin-top: 40px;
  }
}

.lfillings__inner {
  float: left;
  width: 100%;
  margin: -20px 0;
  font-size: 0;
}

.lfilling {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 20px 60px;
  font-size: 14px;
  line-height: 1.5;
}

@media (max-width: 1259px) {
  .lfilling {
    width: 100%;
  }
}

.lfilling__inner {
  display: table;
  width: 100%;
}

@media (max-width: 767px) {
  .lfilling__inner {
    display: block;
    text-align: center;
  }
}

.lfilling__inner:hover .lfilling__image-overlay {
  opacity: 1;
}

.lfilling__left {
  display: table-cell;
  vertical-align: middle;
  width: 1%;
}

@media (max-width: 767px) {
  .lfilling__left {
    display: block;
    width: auto;
  }
}

.lfilling__right {
  display: table-cell;
  vertical-align: middle;
  padding-left: 25px;
}

@media (max-width: 767px) {
  .lfilling__right {
    display: block;
    padding-left: 0;
    margin-top: 15px;
  }
}

.lfilling__image {
  position: relative;
  height: 125px;
  width: 125px;
  line-height: 125px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
}

@media (max-width: 767px) {
  .lfilling__image {
    margin-left: auto;
    margin-right: auto;
  }
}

.lfilling__image img {
  display: block;
}

.lfilling__image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.33);
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 767px) {
  .lfilling__image-overlay {
    opacity: 1;
  }
}

.lfilling__image-overlay-inner {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

.lfilling__title {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

.lfilling__title:first-child {
  margin-top: 0;
}

.lfilling__summary {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.lfilling__summary:first-child {
  margin-top: 0;
}

.lmessage {
  padding: 50px 0;
  text-align: center;
}

.lmessage__title {
  margin-top: 20px;
  font-size: 37px;
  font-weight: 600;
  color: #3d5360;
}

.lmessage__title:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .lmessage__title {
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.02em !important;
  }
}

.lmessage__summary {
  margin-top: 20px;
}

.lmessage__summary:first-child {
  margin-top: 0;
}

.lmessage__summary p {
  margin-top: 10px;
}

.lmessage__summary p:first-child {
  margin-top: 0;
}

.order-wrapper {
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
}

.ordertable {
  width: 100%;
  letter-spacing: 0.1em;
  margin-top: 10px;
}

.ordertable:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .ordertable {
    display: block;
  }
}

@media (max-width: 767px) {
  .ordertable thead {
    display: none;
  }
}

.ordertable th {
  padding: 2px 5px 13px;
  font-weight: 400;
  vertical-align: bottom;
  border-bottom: 1px solid #d9d9d9;
}

.ordertable th:first-child {
  padding-left: 0;
}

.ordertable th:last-child {
  padding-right: 0;
}

@media (max-width: 767px) {
  .ordertable tbody {
    display: block;
  }
}

@media (max-width: 767px) {
  .ordertable tr {
    display: block;
    padding: 25px 0 30px;
    border-bottom: 1px solid #d9d9d9;
  }
  .ordertable tr:after {
    content: "";
    display: table;
    clear: both;
  }
}

.ordertable td {
  vertical-align: top;
  padding: 25px 5px 30px;
  border-bottom: 1px solid #d9d9d9;
}

@media (max-width: 767px) {
  .ordertable td {
    display: block;
    padding: 0;
    border: none;
  }
}

.ordertable td:first-child {
  padding-left: 0;
}

.ordertable td:last-child {
  padding-right: 0;
}

.ordertable-label {
  display: none;
  font-size: 13px;
}

@media (max-width: 767px) {
  .ordertable-label {
    display: block;
  }
}

@media (max-width: 767px) {
  .ordertable-count {
    float: left;
    margin-left: 100px;
    margin-top: 20px;
  }
}

@media (max-width: 479px) {
  .ordertable-count {
    margin-left: 70px;
  }
}

@media (max-width: 767px) {
  .ordertable-priceall {
    float: right;
    margin-top: 20px;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .ordertable-product:after {
    content: "";
    display: table;
    clear: both;
  }
}

.ordertable-product .ordertable-label {
  display: none;
}

.ordertable-product-image {
  float: left;
  width: 90px;
}

@media (max-width: 479px) {
  .ordertable-product-image {
    width: 60px;
  }
}

.ordertable-product-content {
  margin-left: 125px;
}

@media (max-width: 1023px) {
  .ordertable-product-content {
    margin-left: 100px;
  }
}

@media (max-width: 479px) {
  .ordertable-product-content {
    margin-left: 70px;
  }
}

.ordertable-product-title {
  font-size: 17px;
  font-weight: 600;
  color: #3d5360;
  margin-top: 10px;
}

.ordertable-product-title:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .ordertable-product-title {
    font-size: 15px;
  }
}

.ordertable-product-title a {
  text-decoration: none;
}

.ordertable-product-summary {
  margin-top: 10px;
}

.ordertable-product-summary:first-child {
  margin-top: 0;
}

.ordertable-like-title {
  font-size: 17px;
}

@media (max-width: 1023px) {
  .ordertable-like-title {
    font-size: 15px;
  }
}

.orderdata-wrapper {
  margin-top: 15px;
}

.orderdata-wrapper:first-child {
  margin-top: 0;
}

.orderdata {
  width: 100%;
  letter-spacing: 0.1em;
}

.orderdata td {
  position: relative;
  padding: 13px 0 0;
  z-index: 0;
  background: #fff;
}

.orderdata td:before {
  position: absolute;
  right: 0;
  bottom: 3px;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(left, transparent, transparent 3px, #2f2f2f 4px, #2f2f2f 5px);
  background: -o-repeating-linear-gradient(left, transparent, transparent 3px, #2f2f2f 4px, #2f2f2f 5px);
  background: repeating-linear-gradient(90deg, transparent, transparent 3px, #2f2f2f 4px, #2f2f2f 5px);
  z-index: -1;
  content: '';
}

.orderdata td:first-child .orderdata-item {
  padding-left: 0;
}

.orderdata td:last-child {
  text-align: right;
}

.orderdata td:last-child .orderdata-item {
  padding-right: 0;
}

.orderdata-item {
  background-color: inherit;
  padding: 0 3px;
}

.order-permalink {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #d9d9d9;
}

.order-permalink:first-child {
  margin-top: 0;
}

.ctfilling {
  text-align: left;
  line-height: 1.2857;
}

.ctfilling__title {
  font-size: 13px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.1em;
  margin-top: 5px;
  padding: 0 15px;
}

.ctfilling__title:first-child {
  margin-top: 0;
}

.ctfilling__content {
  position: relative;
  margin-top: 5px;
}

.ctfilling__content:first-child {
  margin-top: 0;
}

.input-group-spinner {
  width: 125px;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}

.input-group-spinner .btn {
  padding-left: 10px;
  padding-right: 10px;
}

.input-group-spinner .btn:hover {
  z-index: 3;
}

.input-group-spinner .form-control {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.input-group-spinner .icon-btn-plus-minus {
  position: relative;
  top: -1px;
}

.input-group-spinner .input-group-btn:first-child .icon-btn-plus-minus {
  top: -2px;
}

.cart-wrapper {
  padding-top: 40px;
}

@media (max-width: 1259px) {
  .cart-wrapper {
    padding-top: 0;
  }
}

.cart-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .cart-title {
    font-size: 25px;
    font-weight: 700;
    color: #3d5360;
  }
}

.carttable {
  width: 100%;
  letter-spacing: 0.1em;
}

@media (max-width: 1259px) {
  .carttable {
    margin-top: 20px;
  }
  .carttable:first-child {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .carttable {
    display: block;
  }
  .carttable tbody, .carttable th, .carttable td {
    display: block;
  }
  .carttable thead {
    display: none;
  }
  .carttable tr {
    position: relative;
    display: block;
    border-top: 1px solid #d9d9d9;
    letter-spacing: 0;
    font-size: 0;
    padding: 13px 0;
  }
  .carttable .input-group-spinner {
    margin-left: 0;
  }
  .carttable .btn--product-add-image {
    float: none;
  }
}

.carttable th {
  padding: 0 0 13px;
  font-weight: 400;
  border-bottom: 1px solid #d9d9d9;
  vertical-align: top;
  text-align: center;
}

.carttable th:first-child {
  text-align: left;
}

.carttable td {
  vertical-align: middle;
  padding: 35px 25px;
  border-top: 1px solid #d9d9d9;
  text-align: center;
  width: 1%;
  white-space: nowrap;
}

@media (max-width: 1259px) {
  .carttable td {
    vertical-align: top;
    line-height: 40px;
    padding: 25px 20px;
    white-space: normal;
  }
}

@media (max-width: 767px) {
  .carttable td {
    width: auto;
    padding: 7px 0;
    line-height: 1.2;
    text-align: left;
    border: none;
    font-size: 13px;
  }
}

.carttable td:first-child {
  padding-left: 0;
  text-align: left;
}

.carttable td:last-child {
  padding-right: 0;
}

@media (max-width: 1259px) {
  .carttable tr:first-child td {
    border-top: none;
  }
}

.carttable-label {
  display: none;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.5;
}

@media (max-width: 1259px) {
  .carttable-label {
    display: block;
  }
}

@media (max-width: 767px) {
  .carttable-label {
    font-weight: 600;
    color: #3d5360;
    letter-spacing: 0.1em;
    margin-bottom: 5px;
  }
}

.carttable .ctfilling {
  width: 125px;
  margin-top: -21px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .carttable .ctfilling {
    margin-top: 0;
    width: 100%;
  }
}

.carttable .ctfilling + .ctfilling {
  margin-top: 10px;
}

.carttable .btn--product-add-image {
  margin-left: 0;
}

.carttable-product-tname {
  font-size: 15px;
  line-height: 1.5;
}

td.carttable-product {
  font-size: 15px;
  padding-right: 10px;
  width: auto;
  white-space: normal;
}

@media (max-width: 767px) {
  td.carttable-product {
    font-size: 16px;
    letter-spacing: 0.01em;
    font-weight: 600;
  }
}

td.carttable-product a {
  text-decoration: none;
}

@media (max-width: 1259px) {
  td.carttable-weight {
    padding-left: 0;
    text-align: left;
  }
  td.carttable-weight .input-group-spinner {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  td.carttable-weight {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  td.carttable-tiers {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-left: 10px;
  }
}

@media (max-width: 1259px) {
  td.carttable-fillings {
    text-align: left;
  }
  td.carttable-fillings .carttable-label {
    padding-left: 15px;
  }
  td.carttable-fillings .ctfilling {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  td.carttable-fillings .carttable-label {
    padding-left: 0;
  }
}

td.carttable-note {
  width: auto;
  white-space: normal;
}

@media (max-width: 767px) {
  td.carttable-priceone {
    display: inline-block !important;
    vertical-align: top;
    width: 50%;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  td.carttable-count {
    display: inline-block !important;
    vertical-align: top;
    width: 50%;
    padding-left: 10px;
  }
}

td.carttable-remove {
  white-space: nowrap;
}

td.carttable-remove .icon {
  vertical-align: middle;
  top: -1px;
  font-size: 16px;
}

@media (max-width: 767px) {
  td.carttable-remove .icon {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  td.carttable-remove {
    position: absolute;
    top: 13px;
    right: 0;
  }
  td.carttable-remove .carttable-label {
    display: none;
  }
}

td.carttable-remove a {
  color: #d01b41 !important;
}

.carttable-trow {
  font-size: 14px;
}

.carttable-trow td {
  vertical-align: middle;
  border-top-width: 3px;
}

.carttable-trow .carttable-tiers {
  font-size: 15px;
}

.carttable-trow .carttable-fillings {
  padding-left: 35px;
}

@media (max-width: 767px) {
  .carttable-trow .carttable-fillings {
    padding-left: 0;
  }
}

.carttable-trow .carttable-remove {
  text-align: right;
  font-size: 15px;
}

@media (max-width: 767px) {
  .carttable-trow .carttable-remove {
    text-align: left;
  }
}

.cartdata {
  margin-top: 60px;
  border-top: 1px solid #d9d9d9;
  padding-top: 35px;
  margin-left: -25px;
  margin-right: -25px;
}

.cartdata:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .cartdata {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .cartdata {
    margin-top: 0;
  }
}

.cartdata__inner {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media (max-width: 767px) {
  .cartdata__inner {
    display: block;
  }
}

.cartdata__col {
  display: table-cell;
  width: 50%;
  padding: 0 25px;
  vertical-align: top;
}

@media (max-width: 1259px) {
  .cartdata__col {
    padding: 0 10px;
  }
  .cartdata__col:first-child {
    padding-left: 0;
    width: 60%;
  }
}

@media (max-width: 1259px) and (max-width: 767px) {
  .cartdata__col:first-child {
    width: auto;
  }
}

@media (max-width: 1259px) {
  .cartdata__col:last-child {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .cartdata__col {
    display: block;
    width: auto;
    margin-top: 45px;
    padding: 0;
  }
  .cartdata__col:first-child {
    margin-top: 0;
  }
}

.cartdata__osections {
  margin-top: 30px;
}

.cartdata__osections:first-child {
  margin-top: 0;
}

.cartdata__osection {
  position: relative;
  margin-top: 45px;
  margin-bottom: 0;
  padding-left: 30px;
}

.cartdata__osection:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .cartdata__osection {
    padding-left: 0;
  }
}

.cartdata__osection-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #3d5360;
  margin-top: 10px;
  margin-bottom: 0;
}

.cartdata__osection-title:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .cartdata__osection-title {
    padding-left: 30px;
  }
}

.cartdata__osection-sticker {
  position: absolute;
  top: 1px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  background-color: #3d5360;
  color: #fff;
}

.cartdata__osection-content {
  margin-top: 20px;
}

.cartdata__osection-content:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .cols--cartdata {
    display: block;
  }
}

@media (max-width: 1259px) {
  .cols--cartdata > .col {
    display: block;
  }
  .cols--cartdata > .col .form__item-label {
    margin-top: 5px;
    padding-bottom: 7px;
  }
}

.cols--cartdata > .col + .col {
  margin-top: 10px;
  padding-left: 0;
}

.cols--cartdata .col {
  padding-left: 5px;
  padding-right: 5px;
}

.cols--cartdata .col:first-child {
  padding-left: 0;
}

.cols--cartdata .col:last-child {
  padding-right: 0;
}

@media (max-width: 767px) {
  .delivery-text {
    font-size: 12px;
    letter-spacing: 0.01em;
  }
}

.cart-actions {
  margin-top: 20px;
  border-top: 1px solid #d9d9d9;
  padding-top: 35px;
}

.cart-actions:first-child {
  margin-top: 0;
}

.form__item-field--cartdatamw {
  max-width: 255px;
}

@media (max-width: 767px) {
  .form__item-field--cartdatamw {
    max-width: none;
  }
}

.cradio {
  position: relative;
  letter-spacing: 0.1em;
  padding-left: 28px;
  font-size: 14px;
  margin-top: 5px;
}

.cradio:first-child {
  margin-top: 0;
}

.cradio label {
  padding: 0;
}

.cradio .radio {
  position: static;
}

.cradio__icon {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #3d5360;
  border-radius: 50%;
}

.cradio__icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -5px 0 0 -5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #3d5360;
  visibility: hidden;
  content: '';
}

.cradio__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.cradio.active .cradio__icon:before {
  visibility: visible;
}

.delivery-external-module-block {
  margin-top: 10px;
}

.delivery-external-module-block .input-group-spinner {
  display: inline-table;
  vertical-align: middle;
  margin-right: 10px;
}

.mproduct {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-top: 30px;
}

.mproduct:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .mproduct {
    display: block;
  }
}

.mproduct__left {
  display: table-cell;
  vertical-align: top;
  text-align: center;
  padding-right: 10px;
}

@media (max-width: 767px) {
  .mproduct__left {
    display: block;
    padding-right: 0;
  }
}

.mproduct__right {
  display: table-cell;
  vertical-align: middle;
  width: 350px;
  padding-left: 10px;
}

@media (max-width: 1259px) {
  .mproduct__right {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .mproduct__right {
    display: block;
    padding-left: 0;
    width: auto;
  }
}

.mproduct__right .alert {
  margin: 15px 0 0 30px;
  max-width: 302px;
}

.mproduct__right .alert:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .mproduct__right .alert {
    margin-left: 0;
  }
}

.mproduct__title {
  font-size: 15px;
  margin-top: 10px;
}

.mproduct__title:first-child {
  margin-top: 0;
}

.mproduct__price {
  font-size: 18px;
  font-weight: 700;
  color: #3d5360;
  margin-top: 10px;
}

.mproduct__price:first-child {
  margin-top: 0;
}

.mproduct__summary {
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 1.31;
  margin-top: 20px;
}

.mproduct__summary:first-child {
  margin-top: 0;
}

.mproduct__quantity {
  margin-top: 15px;
}

.mproduct__quantity:first-child {
  margin-top: 0;
}

.mproduct .product__osection {
  margin-top: 25px;
}

.mproduct .product__osection:first-child {
  margin-top: 0;
}

.mproduct .product__actions {
  margin-top: 25px;
}

.mproduct .product__actions:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .mproduct .product__title {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .mproduct .product__price {
    text-align: center;
  }
}

.mseparator {
  border-top: 1px solid #d6d6d6;
  margin: 30px -50px 0;
}

@media (max-width: 767px) {
  .mseparator {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.form--add-to-cart {
  margin-top: 50px;
}

.modal--after-buy .form__actions .btn {
  min-width: 185px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .modal--after-buy .form__actions .btn + .btn {
    margin-top: 10px;
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .modal--buy1click .cols {
    display: block;
  }
}

@media (max-width: 767px) {
  .modal--buy1click .col {
    display: block;
    padding: 0;
  }
  .modal--buy1click .col + .col {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .modal--buy1click .form__item-label {
    text-align: center;
  }
}

.c-added__icon {
  float: left;
  width: 40px;
  font-size: 40px;
  margin: -5px 0;
  color: #2ab78d;
}

.c-added__content {
  margin-left: 50px;
}

.c-added__title {
  margin-top: 5px;
  font-size: 13px;
}

.c-added__title:first-child {
  margin-top: 0;
}

.c-added__summary {
  margin-top: 5px;
  font-size: 11px;
  color: rgba(47, 47, 47, 0.5);
}

.c-added__summary:first-child {
  margin-top: 0;
}

.sproducts {
  margin-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
}

.sproducts:first-child {
  margin-top: 0;
}

.sproducts:after {
  content: "";
  display: table;
  clear: both;
}

.sproducts__inner {
  float: left;
  width: 100%;
  margin: -15px 0;
  font-size: 0;
}

.sproduct {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  font-size: 14px;
  text-align: center;
}

.sproduct__inner {
  padding: 15px 10px;
}

.sproduct__image {
  margin-top: 5px;
}

.sproduct__image:first-child {
  margin-top: 0;
}

.sproduct__title {
  margin-top: 5px;
}

.sproduct__title:first-child {
  margin-top: 0;
}

.products-related {
  margin-top: 50px;
}

.products-related:first-child {
  margin-top: 0;
}

.swiper-slider--products {
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
  /*
    &.swiper-slider--start .swiper-container {

        &:before {
            opacity: 0;
        }
    }

    &.swiper-slider--end .swiper-container {

        &:after {
            opacity: 0;
        }
    }*/
}

.swiper-slider--products:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .swiper-slider--products {
    margin-left: 0;
    margin-right: 0;
  }
}

.swiper-slider--products .swiper-slide--with-desc {
  padding-bottom: 80px;
}

@media (max-width: 1259px) {
  .swiper-slider--products .swiper-slide {
    padding-bottom: 0;
  }
}

.swiper-slider--products .swiper-container:before, .swiper-slider--products .swiper-container:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  content: '';
  z-index: 5;
  display: block;
  pointer-events: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.swiper-slider--products .swiper-container:before {
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(white), color-stop(50%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.swiper-slider--products .swiper-container:after {
  right: 0;
  background-image: -webkit-gradient(linear, right top, left top, from(white), color-stop(50%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(right, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(right, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

@media (max-width: 1259px) {
  .swiper-slider--products .swiper-button-prev {
    margin-right: -10px !important;
  }
}

@media (max-width: 767px) {
  .swiper-slider--products .swiper-button-prev {
    margin-right: -20px !important;
  }
}

@media (max-width: 1259px) {
  .swiper-slider--products .swiper-button-next {
    margin-left: -10px !important;
  }
}

@media (max-width: 767px) {
  .swiper-slider--products .swiper-button-next {
    margin-left: -20px !important;
  }
}

.banners-separator {
  height: 50px;
}

.section--bez-gmo {
  position: relative;
  padding-top: 80px;
  height: 1000px;
  background: #fff center -60px no-repeat;
}

@media (max-width: 1023px) {
  .section--bez-gmo {
    padding-top: 40px;
    height: 570px;
    background-position: center bottom;
    background-size: auto 100%;
  }
  .section--bez-gmo .summary {
    font-size: 11px;
    letter-spacing: 0.01em;
    margin-top: 15px;
  }
}

.bez-gmo-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  visibility: hidden;
}

.bez-gmo-mint-leaf {
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: 225px;
  height: 118px;
  width: 82px;
  background: url("../img/bg-mint-leaf.png") center center no-repeat;
  background-size: contain;
}

.bgmos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bgmo {
  position: absolute;
  max-width: 250px;
  text-align: left;
  line-height: 1.45;
}

@media (max-width: 1023px) {
  .bgmo {
    font-size: 10px;
    color: #3d5360;
    max-width: 150px;
  }
}

.bgmo--1 {
  top: 250px;
  right: 50%;
  margin-right: -210px;
}

@media (max-width: 1023px) {
  .bgmo--1 {
    margin-right: -130px;
    top: 200px;
  }
}

.bgmo--1 .bgmo__line-inner {
  padding-left: 100px;
}

@media (max-width: 1023px) {
  .bgmo--1 .bgmo__line-inner {
    padding-left: 15px;
  }
}

.bgmo--2 {
  top: 340px;
  left: 50%;
  margin-left: 300px;
  max-width: 300px;
}

@media (max-width: 1023px) {
  .bgmo--2 {
    top: 225px;
    max-width: 150px;
    margin-left: 150px;
  }
}

.bgmo--2 .btmo__content {
  max-width: 240px;
}

.bgmo--2 .bgmo__line-inner {
  padding-left: 80px;
}

@media (max-width: 1023px) {
  .bgmo--2 .bgmo__line-inner {
    padding-left: 60px;
  }
}

.bgmo--3 {
  top: 590px;
  left: 50%;
  margin-left: -330px;
  max-width: 240px;
}

@media (max-width: 1023px) {
  .bgmo--3 {
    top: 315px;
    margin-left: -200px;
    max-width: 150px;
  }
}

.bgmo--3 .bgmo__line-inner {
  padding-left: 110px;
}

@media (max-width: 1023px) {
  .bgmo--3 .bgmo__line-inner {
    padding-left: 100px;
  }
}

.bgmo--4 {
  top: 650px;
  left: 50%;
  margin-left: 200px;
  max-width: 240px;
}

@media (max-width: 1023px) {
  .bgmo--4 {
    top: 370px;
    margin-left: 120px;
    max-width: 180px;
  }
}

.bgmo--4 .bgmo__line-inner {
  padding-left: 60px;
}

@media (max-width: 1023px) {
  .bgmo--4 .bgmo__line-inner {
    padding-left: 20px;
  }
}

.bgmo--right {
  text-align: right;
}

.bgmo--right .bgmo__line-inner {
  float: right;
}

.bgmo--right .bgmo__line-inner:before {
  right: auto;
  left: 0;
}

.bgmo__line {
  margin-top: 10px;
  height: 1px;
}

.bgmo__line:first-child {
  margin-top: 0;
}

.bgmo__line:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .bgmo__line {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.bgmo__line-inner {
  position: relative;
  float: left;
  width: 100%;
  height: 1px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  background-color: #3d5360;
}

.bgmo__line-inner:before {
  position: absolute;
  top: -8px;
  right: 0;
  height: 17px;
  width: 17px;
  background-color: #3d5360;
  border-radius: 50%;
  content: '';
}

@media (max-width: 1023px) {
  .bgmo__line-inner:before {
    top: -4px;
    height: 9px;
    width: 9px;
  }
}

.bgmo__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  color: #3d5360;
  margin-top: 20px;
}

.bgmo__title:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .bgmo__title {
    font-size: 13px;
    margin-top: 12px;
  }
}

.bgmo__subtitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.2;
  color: #3d5360;
  margin-top: 15px;
}

.bgmo__subtitle:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .bgmo__subtitle {
    font-size: 12px;
    margin-top: 13px;
  }
}

.bgmo__content {
  margin-top: 10px;
}

.bgmo__content:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .bgmo__content {
    margin-top: 2px;
  }
  .bgmo__content p {
    margin-top: 10px;
  }
  .bgmo__content p:first-child {
    margin-top: 0;
  }
}

.tabs--fillings {
  margin-top: 50px;
}

.dfillings-title {
  text-align: center;
}

.dfillings {
  position: relative;
  margin-top: 50px;
  margin-left: -10px;
  margin-right: -10px;
  padding-bottom: 100px;
  text-align: center;
  /*
    @media (max-width: $tablet) {
        margin-left: -25px;
        margin-right: -25px;

        &:before,
        &:after {
            @include position(absolute, 0 null 0 null);
            width: 90px;
            content: '';
            z-index: 1;
            pointer-events: none;
        }

        &:before {
            left: -2px;
            background-image: linear-gradient(to right, rgba(#fff, 1) 0%, rgba(#fff, 0.95) 20%, rgba(#fff, 0) 100%);
        }

        &:after {
            right: -2px;
            background-image: linear-gradient(to left, rgba(#fff, 1) 0%, rgba(#fff, 0.95) 20%, rgba(#fff, 0) 100%);
        }

        &.hscroll-state-start {

            &:before {
                display: none;
            }
        }

        &.hscroll-state-end {

            &:after {
                display: none;
            }
        }
    }

    @media (max-width: $mobile) {
        margin-left: -10px;
        margin-right: -10px;

        &:before,
        &:after {
            width: 70px;
        }
    }*/
}

.dfillings:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .dfillings {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .dfillings {
    padding-bottom: 50px;
  }
}

.dfillings__scroll {
  /*
        @media (max-width: $tablet) {
            overflow-x: auto;
            overflow-y: hidden;
            -webkit-overflow-scrolling: touch;
        }*/
}

.dfillings__scroll:after {
  content: "";
  display: table;
  clear: both;
}

.dfillings__scroll::-webkit-scrollbar {
  display: none;
}

.dfillings__inner {
  float: left;
  width: 100%;
  margin: -20px 0;
  font-size: 0;
  /*
        @media (max-width: $tablet) {
            display: inline-block;
            vertical-align: top;
            float: none;
            width: auto;
            white-space: nowrap;
            margin: 0;
        }*/
}

@media (min-width: 1260px) {
  .dfillings__inner {
    margin-top: -25px;
    margin-bottom: -25px;
  }
}

.dfilling {
  display: inline-block;
  vertical-align: top;
  width: 14.28571%;
  min-width: 170px;
  padding: 20px 10px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}

@media (min-width: 1260px) {
  .dfilling {
    width: 33.33333%;
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.dfilling__image {
  position: relative;
  height: 125px;
  width: 125px;
  line-height: 125px;
  border-radius: 50%;
  overflow: hidden;
  margin: 20px auto 0;
}

@media (min-width: 1260px) {
  .dfilling__image {
    height: auto;
    width: auto;
    border-radius: 0;
    line-height: 1;
    padding-bottom: 75%;
  }
  .dfilling__image img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.dfilling__image img {
  display: block;
}

.dfilling__image:first-child {
  margin-top: 0;
}

.dfilling__stickers {
  position: absolute;
  top: 15px;
  left: 20px;
  right: 20px;
  text-align: left;
  z-index: 1;
}

.dfilling__sticker {
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 15px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.dfilling__title {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

.dfilling__title:first-child {
  margin-top: 0;
}

@media (min-width: 1260px) {
  .dfilling__title {
    font-size: 18px;
    color: #3d5360;
    letter-spacing: 0.01em;
    margin-top: 25px;
  }
}

.dfilling__summary {
  margin-top: 10px;
}

.dfilling__summary:first-child {
  margin-top: 0;
}

.afillings {
  position: relative;
  margin-top: 65px;
  margin-left: -30px;
  margin-right: -30px;
  text-align: center;
}

.afillings:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .afillings {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .afillings {
    margin-top: 40px;
  }
}

.afillings__scroll:after {
  content: "";
  display: table;
  clear: both;
}

.afillings__scroll::-webkit-scrollbar {
  display: none;
}

.afillings__inner {
  float: left;
  width: 100%;
  margin: -20px 0;
  font-size: 0;
  padding: 0 60px;
}

@media (max-width: 1023px) {
  .afillings__inner {
    padding: 0;
  }
}

.afilling {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  min-width: 170px;
  padding: 20px 30px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}

.afilling__image {
  position: relative;
  height: 150px;
  width: 150px;
  line-height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 20px auto 0;
}

@media (max-width: 1023px) {
  .afilling__image {
    height: 125px;
    width: 125px;
    line-height: 125px;
  }
}

.afilling__image:first-child {
  margin-top: 0;
}

.afilling__image img {
  display: block;
}

.afilling__title {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.1em;
}

.afilling__title:first-child {
  margin-top: 0;
}

.section--calc {
  padding-top: 80px;
}

@media (max-width: 1259px) {
  .section--calc {
    padding-top: 40px;
  }
}

.section--calc .h2, .section--calc h2 {
  position: relative;
  z-index: 1;
}

.section--calc .summary {
  position: relative;
  z-index: 1;
}

.calc {
  margin-top: 40px;
}

.calc:first-child {
  margin-top: 0;
}

@media (max-width: 1259px) {
  .calc {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1023px) {
  .calc {
    margin-top: 65px;
  }
}

.calc__inner {
  display: table;
  width: 100%;
}

.calc__image {
  display: table-cell;
  vertical-align: top;
  width: 1%;
  padding-left: 26px;
  padding-right: 20px;
}

@media (max-width: 1259px) {
  .calc__image {
    padding-left: 0;
  }
}

@media (max-width: 1023px) {
  .calc__image {
    padding-left: 40px;
    padding-right: 0;
  }
}

.calc__image-inner {
  display: inline-block;
  vertical-align: top;
  width: 426px;
  margin-top: -60px;
  margin-bottom: -20px;
}

@media (max-width: 1023px) {
  .calc__image-inner {
    margin-left: -40px;
  }
}

.calc__content {
  display: table-cell;
  vertical-align: top;
  padding-left: 50px;
  padding-right: 160px;
}

@media (max-width: 1259px) {
  .calc__content {
    padding-right: 50px;
    padding-left: 0;
  }
}

@media (max-width: 1023px) {
  .calc__content .cols {
    display: block;
  }
  .calc__content .col {
    display: block;
    padding: 0;
  }
  .calc__content .col + .col {
    margin-top: 10px;
  }
  .calc__content .calc__price {
    display: none;
  }
}

.calc__price {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
  margin-top: 20px;
}

.calc__price:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .calc__price {
    margin-top: 30px;
  }
}

.calc__price-value {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin-top: 10px;
}

.calc__price-value:first-child {
  margin-top: 0;
}

.calc .filling-group-closest-hide {
  display: none;
}

.calc .filling-group-closest-hide.filling-group-visible {
  display: block;
}

.calc .filling-group-closest .filling-group-col.filling-group-visible {
  display: block;
}

.page-404 .breadcrumbs {
  display: none;
}

.page-404 .main-content {
  background-color: #fafafa;
}

.b404 {
  display: table;
  width: 100%;
  table-layout: fixed;
  direction: rtl;
  text-align: center;
}

@media (max-width: 767px) {
  .b404 {
    display: block;
  }
}

.b404__image {
  display: table-cell;
  vertical-align: middle;
  direction: ltr;
}

@media (max-width: 1023px) {
  .b404__image {
    width: 35%;
  }
}

@media (max-width: 767px) {
  .b404__image {
    display: block;
    width: auto;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
  .b404__image:first-child {
    margin-top: 0;
  }
}

.b404__content {
  display: table-cell;
  vertical-align: middle;
  direction: ltr;
  letter-spacing: 0.1em;
}

@media (max-width: 767px) {
  .b404__content {
    display: block;
    margin-top: 60px;
  }
  .b404__content:first-child {
    margin-top: 0;
  }
}

.jarticles {
  margin: 50px -10px 0;
}

.jarticles:after {
  content: "";
  display: table;
  clear: both;
}

.jarticles:first-child {
  margin-top: 0;
}

.jarticles__inner {
  float: left;
  width: 100%;
  margin: -20px 0;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.jarticles__inner .swiper-slider {
  width: 100%;
}

.jarticles__inner .swiper-button-prev,
.jarticles__inner .swiper-button-next {
  color: #fff;
  top: 50%;
  margin-top: -48px;
}

.jarticles__inner .swiper-button-prev {
  left: 30px;
  margin-right: auto;
  right: auto;
}

.jarticles__inner .swiper-button-next {
  left: auto;
  margin-left: auto !important;
  right: 30px;
}

.jarticle {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  font-size: 14px;
  padding: 20px 10px;
}

.swiper-slide .jarticle {
  width: 100%;
}

@media (max-width: 1259px) {
  .jarticle {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .jarticle {
    width: 100%;
  }
}

.jarticle--2 {
  width: 66.66%;
}

@media (max-width: 1259px) {
  .jarticle--2 {
    width: 100%;
  }
}

.jarticle--2 .jarticle__image {
  padding-bottom: 40.45%;
  padding-bottom: calc((83% - 20px*0.83)/2);
}

@media (max-width: 767px) {
  .jarticle--2 .jarticle__image {
    padding-bottom: 83%;
  }
}

.jarticle__inner {
  position: relative;
  display: block;
  text-decoration: none;
}

.jarticle__inner:focus {
  text-decoration: none;
}

.jarticle__inner:hover {
  text-decoration: none;
}

.jarticle__inner:hover .jarticle__image {
  opacity: 0.8;
}

.jarticle__image {
  display: block;
  padding-bottom: 83%;
  border-radius: 4px;
  background: #f3f3f3 center center no-repeat;
  background-size: cover;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.jarticle__image img {
  display: none;
  border-radius: 4px;
}

.jarticle__badges {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
  max-width: 90%;
  pointer-events: none;
}

.jarticle__badge {
  float: right;
  clear: both;
  padding: 12px 15px;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: #ef8fce;
  text-align: right;
  border-radius: 4px 0 0 4px;
  color: #fff;
  margin-top: 5px;
}

.jarticle__badge:first-child {
  margin-top: 0;
}

.jarticle__content {
  display: block;
  margin-top: 15px;
}

.jarticle__content:first-child {
  margin-top: 0;
}

.jarticle__title {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: #3d5360;
  margin-top: 7px;
}

.jarticle__title:first-child {
  margin-top: 0;
}

.jarticle__title a {
  text-decoration: none;
  color: #3d5360;
}

.jarticle__meta {
  font-size: 0;
  margin: 15px -15px 0 0;
}

.jarticle__meta:first-child {
  margin-top: 0;
}

.jarticle__meta-grid {
  display: table;
  width: 100%;
}

.jarticle__meta-item {
  display: table-cell;
  vertical-align: top;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  padding-right: 15px;
  color: #666;
  text-decoration: none;
}

.jarticle__meta-item:first-child {
  text-align: left;
}

.jarticle__meta-item--compact {
  width: 1%;
  white-space: nowrap;
}

.jarticle__meta-item-image {
  float: left;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #eee;
  overflow: hidden;
  margin: 0 10px 0 0;
}

.jarticle__meta-item-image.img-to-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.jarticle__meta-item-image.img-to-bg > img {
  display: none;
}

.jarticle__meta-item-image + .jarticle__meta-item-content {
  margin-left: 42px;
}

.jarticle__meta-item-content {
  line-height: 32px;
}

.jarticle__meta-item-content-inner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  line-height: 1.2857;
  overflow: hidden;
  max-height: 30px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.jarticle__meta-item a {
  color: #545d63;
}

.jarticle__meta-item .icon {
  margin-right: 3px;
  font-size: 13px;
  vertical-align: -0.15em;
}

.jarticle--medium .jarticle__title {
  line-height: 1.25;
}

.jarticle--small .jarticle__image {
  display: block;
  padding-bottom: 0;
  background: none !important;
}

.jarticle--small .jarticle__image img {
  display: block;
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}

.jarticle--small .jarticle__title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  color: #3d5360;
  max-width: 320px;
}

.jarticle--small .jarticle__title a {
  color: #3d5360;
}

.jarticle--xsmall .jarticle__image {
  display: block;
  padding-bottom: 0;
  background: none !important;
}

.jarticle--xsmall .jarticle__image img {
  display: block;
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 140px;
}

.jarticle--xsmall .jarticle__title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #666;
  max-width: 200px;
}

.jarticle--xsmall .jarticle__title a {
  color: #000;
}

.jfarticle {
  font-size: 18px;
}

@media (max-width: 1023px) {
  .jfarticle {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .jfarticle {
    font-size: 14px;
  }
}

.jfarticle h2, .jfarticle .h2 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 50px;
}

.jfarticle h2:first-child, .jfarticle .h2:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .jfarticle h2, .jfarticle .h2 {
    font-size: 22px;
  }
}

.jfarticle h3, .jfarticle .h3 {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 50px;
}

.jfarticle h3:first-child, .jfarticle .h3:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .jfarticle h3, .jfarticle .h3 {
    font-size: 18px;
  }
}

.jfarticle h4, .jfarticle .h4 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 50px;
}

.jfarticle h4:first-child, .jfarticle .h4:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .jfarticle h4, .jfarticle .h4 {
    font-size: 16px;
  }
}

.jfarticle ul, .jfarticle ol {
  margin-top: 20px;
}

.jfarticle ul:first-child, .jfarticle ol:first-child {
  margin-top: 0;
}

.jfarticle li {
  margin-top: 10px;
}

.jfarticle li:first-child {
  margin-top: 0;
}

.jfarticle blockquote {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  padding: 35px 80px;
  background-color: #f3f3f3;
  border-radius: 4px;
  color: #3d5360;
  border: none;
}

.jfarticle blockquote:first-child {
  margin-top: 0;
}

.jfarticle blockquote p:first-child:before {
  content: '«';
}

.jfarticle blockquote p:last-child:after {
  content: '»';
}

@media (max-width: 767px) {
  .jfarticle blockquote {
    padding: 30px;
  }
}

.jfarticle__block {
  margin-top: 25px;
  padding: 35px 45px;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.jfarticle__block:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .jfarticle__block {
    padding: 15px;
  }
}

.jfarticle__meta {
  font-size: 0;
  margin: 25px -15px 0 0;
}

.jfarticle__meta:first-child {
  margin-top: 0;
}

.jfarticle__meta-grid {
  display: table;
  width: 100%;
}

.jfarticle__meta-item {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  padding-right: 15px;
  color: #666;
  text-decoration: none;
}

.jfarticle__meta-item:first-child {
  text-align: left;
}

.jfarticle__meta-item--compact {
  width: 1%;
  white-space: nowrap;
}

.jfarticle__meta-item-image {
  float: left;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #eee;
  margin: 0 10px 0 0;
}

.jfarticle__meta-item-image.img-to-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.jfarticle__meta-item-image.img-to-bg > img {
  display: none;
}

.jfarticle__meta-item-image + .jfarticle__meta-item-content {
  margin-left: 58px;
}

.jfarticle__meta-item-content {
  line-height: 48px;
}

.jfarticle__meta-item-content-inner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  line-height: 1.2857;
}

.jfarticle__meta-item a {
  color: #545d63;
}

.jfarticle__meta-item .icon {
  margin-right: 3px;
  font-size: 13px;
  vertical-align: -0.15em;
}

.jfarticle__image {
  position: relative;
  min-height: 300px;
  background: #f3f3f3 center center no-repeat;
  background-size: cover;
  border-radius: 4px;
  margin-top: 30px;
}

.jfarticle__image:first-child {
  margin-top: 0;
}

.jfarticle__image > img {
  display: block;
  width: 100%;
  height: auto;
  visibility: hidden;
}

.jfarticle__contents {
  position: absolute;
  top: 60px;
  left: 0;
  max-width: 100%;
  max-height: calc(100% - 60px);
  padding: 40px 50px 40px 80px;
  background-color: #fff;
  color: #666;
  font-size: 13px;
}

@media (max-width: 767px) {
  .jfarticle__contents {
    display: none;
  }
}

.jfarticle__contents h3 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  color: #3d5360;
  margin-top: 10px;
}

.jfarticle__contents h3:first-child {
  margin-top: 0;
}

.jfarticle__contents ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.jfarticle__contents ul:first-child {
  margin-top: 0;
}

.jfarticle__contents li {
  margin-top: 5px;
}

.jfarticle__contents li:first-child {
  margin-top: 0;
}

.jfarticle__contents a {
  color: #666;
  text-decoration: none;
}

.jfarticle__contents a:hover {
  color: #000;
  text-decoration: none;
}

.jfarticle__content {
  margin-top: 45px;
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.jfarticle__content:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .jfarticle__content {
    margin-top: 30px;
  }
}

.jfarticle__content img {
  height: auto;
  border-radius: 4px;
}

.jfarticle__scontent {
  -webkit-box-flex: 1;
      -ms-flex: auto 1 1;
          flex: auto 1 1;
  max-width: 800px;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .jfarticle__scontent {
    max-width: calc(100vw - 50px);
  }
}

@media (max-width: 767px) {
  .jfarticle__scontent {
    max-width: calc(100vw - 20px);
  }
}

.jfarticle__sidebar {
  -webkit-box-flex: 1;
      -ms-flex: auto 0 0px;
          flex: auto 0 0;
  width: 300px;
  margin-left: 40px;
}

.jfarticle__main {
  margin-top: 30px;
  margin-bottom: 40px;
}

.jfarticle__main:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .jfarticle__main {
    margin-top: -30px !important;
  }
}

@media (min-width: 1024px) {
  .jfarticle__main-tabs {
    display: none;
  }
}

.jfarticle__main-tabs li {
  letter-spacing: 0;
}

@media (max-width: 1023px) {
  .jfarticle__main-tabs li {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .jfarticle__main-tabs li {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.jfarticle__main-tabs li.active a {
  color: #3d5360;
}

@media (max-width: 1023px) {
  .jfarticle__main-tabs .tabs__inner {
    margin-left: -20px;
    margin-right: -20px;
  }
}

@media (max-width: 767px) {
  .jfarticle__main-tabs .tabs__inner {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.jfarticle__main-tabs a {
  color: #a0abb1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.jfarticle__main-inner {
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}

.jfarticle__main-inner:first-child {
  margin-top: 0;
}

.jfarticle__main-inner:after {
  content: "";
  display: table;
  clear: both;
}

.jfarticle__main-grid {
  float: left;
  width: 100%;
  margin: -15px 0 !important;
  font-size: 0;
}

.jfarticle__main-col {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 15px;
  font-size: 14px;
  color: #666;
}

@media (max-width: 1023px) {
  .jfarticle__main-col {
    width: 100%;
  }
}

@media (max-width: 374px) {
  .jfarticle__main-col {
    font-size: 13px;
  }
}

.jfarticle__main-col h3 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  color: #3d5360;
  margin-top: 35px;
}

.jfarticle__main-col h3:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .jfarticle__main-col h3:first-child {
    display: none;
  }
}

.jfarticle__main-col p {
  margin-top: 10px;
}

.jfarticle__main-col p:first-child {
  margin-top: 0;
}

.jfarticle__main-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.jfarticle__main-col ul:first-child {
  margin-top: 0;
}

.jfarticle__main-col li {
  margin-top: 5px;
}

.jfarticle__main-col li:first-child {
  margin-top: 0;
}

@media (min-width: 1024px) {
  .jfarticle__main-col {
    position: relative;
    top: 0;
  }
}

.jfarticle__text {
  max-width: 800px;
}

.jfarticle__text p:first-child {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .jfarticle__text p:first-child {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .jfarticle__text p:first-child {
    font-size: 16px;
  }
}

.jfarticle__gallery {
  margin-top: 50px;
}

.jfarticle__gallery:first-child {
  margin-top: 0;
}

.jfarticle__gallery-item {
  margin-top: 50px;
}

.jfarticle__gallery-item:first-child {
  margin-top: 0;
}

.jfarticle__related {
  margin-top: 50px;
}

.jfarticle__related:first-child {
  margin-top: 0;
}

.jfarticle__related .swiper-slider {
  margin-top: 25px;
  margin-left: -10px;
  margin-right: -10px;
}

.jfarticle__related .swiper-slider:first-child {
  margin-top: 0;
}

.jfarticle__footer {
  position: relative;
  margin-top: 30px;
  padding-top: 25px;
  margin-left: -10px;
  margin-right: -10px;
}

.jfarticle__footer:first-child {
  margin-top: 0;
}

.jfarticle__footer-inner {
  padding: 0 10px;
}

.jfarticle__footer-hr {
  border-top: 1px solid #e6e9ea;
  margin-top: 25px;
}

.jfarticle__footer-hr:first-child {
  margin-top: 0;
}

.jfarticle__footer-grid {
  display: table;
  width: 100%;
  margin-top: 25px;
}

.jfarticle__footer-grid:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .jfarticle__footer-grid {
    display: block;
  }
}

.jfarticle__footer-col {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px;
}

@media (max-width: 767px) {
  .jfarticle__footer-col {
    display: block;
    margin-top: 20px;
  }
  .jfarticle__footer-col:first-child {
    margin-top: 0;
  }
}

.jfarticle__footer-col--social {
  width: 1%;
}

@media (max-width: 767px) {
  .jfarticle__footer-col--social {
    width: auto;
  }
}

.jfarticle__footer-label {
  font-size: 13px;
  color: #666;
}

.jfarticle__footer-btns {
  margin-top: 10px;
  margin-right: -10px;
  margin-bottom: -10px;
  font-size: 0;
}

.jfarticle__footer-btns:first-child {
  margin-top: 0;
}

.jfarticle__footer-btns .btn {
  margin: 0 10px 10px 0;
}

.jfarticle__footer-social {
  margin-top: 10px;
  white-space: nowrap;
}

.jfarticle__footer-social:first-child {
  margin-top: 0;
}

.jfarticle__footer-social .ya-share2 {
  min-height: 40px;
}

.jfarticle__footer-social .ya-share2__badge {
  width: 40px;
  text-align: center;
}

.jfarticle__footer-social .ya-share2__badge .ya-share2__icon {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  margin: 4px 0;
}

.jfarticle__footer .btn {
  font-weight: 400;
  letter-spacing: 0;
}

.jfarticle__reviews {
  margin-top: 25px;
  padding: 35px;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.jfarticle__reviews:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .jfarticle__reviews {
    padding: 15px;
  }
}

.jfarticle .plitka {
  margin-top: 50px;
  margin-bottom: 50px;
}

.jfarticle .plitka:first-child {
  margin-top: 0;
}

.jfarticle .plitka:last-child {
  margin-bottom: 0;
}

.jfarticle .jarticles {
  margin-top: 40px;
}

.jfarticle .jarticles:first-child {
  margin-top: 0;
}

.jfarticle__featured {
  float: right;
  width: 320px;
  max-width: 400px;
  margin-top: 20px;
  margin-right: -380px;
}

@media (max-width: 1259px) {
  .jfarticle__featured {
    float: none;
    width: 100%;
    margin: 40px 0;
  }
  .jfarticle__featured:last-child {
    margin-bottom: 0;
  }
}

.jfarticle__featured:first-child {
  margin-top: 0;
}

.jfarticle__featured .jarticle {
  width: 100%;
}

figure.image {
  margin-top: 50px;
  margin-bottom: 40px;
}

figure.image img {
  border-radius: 4px 4px 0 0;
  min-width: 100%;
}

figure.image img:last-child {
  border-radius: 4px;
}

figure.image figcaption {
  background-color: #f7f7f7;
  color: #666;
  padding: 15px 20px;
  font-size: 14px;
  border-radius: 0 0 4px 4px;
}

.authors-wanted {
  padding: 70px 0 30px;
}

.my-review {
  margin-top: 20px;
}

.my-review:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .my-review h2, .my-review .h2 {
    font-size: 18px;
    font-weight: 600;
  }
}

.my-review-header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-review-header:first-child {
  margin-top: 0;
}

.my-review-header:last-child {
  margin-bottom: 0;
}

.reviews {
  margin-top: 50px;
}

.reviews:first-child {
  margin-top: 0;
}

.review {
  margin-top: 35px;
  font-size: 14px;
}

.review:first-child {
  margin-top: 0;
}

.review-title {
  text-transform: uppercase;
  letter-spacing: 0.07em;
  color: #888;
  margin-top: 5px;
}

.review-title:first-child {
  margin-top: 0;
}

.review-author {
  display: inline-block;
  margin-left: 10px;
}

.review-author:first-child {
  margin-left: 0;
}

.review-date {
  display: inline-block;
  margin-left: 10px;
}

.review-date:first-child {
  margin-left: 0;
}

.review-body-text {
  margin-top: 5px;
}

.review-body-text:first-child {
  margin-top: 0;
}

.mt-items {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  margin-bottom: -20px;
  margin-top: 50px;
}

.mt-items:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .mt-items {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
}

@media (max-width: 767px) {
  .mt-items {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
}

.mt-item {
  margin-bottom: 20px;
}

.mt-item__title {
  font-size: 17px;
  line-height: 1.29412;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.instagram-images {
  margin: 0 -10px;
  margin-top: 30px;
}

.instagram-images:first-child {
  margin-top: 0;
}

.instagram-images:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .instagram-images {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 5px;
  }
}

.instagram-images__inner {
  float: left;
  width: 100%;
  margin: -10px 0;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  height: 540px;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}

@media (max-width: 1259px) {
  .instagram-images__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 260px;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

@media (max-width: 1023px) {
  .instagram-images__inner {
    height: 190px;
    min-width: 720px;
  }
}

.instagram-image {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  padding: 10px;
  height: 100%;
}

.instagram-image img {
  display: block;
  width: auto;
  height: auto;
  max-height: 100%;
}

.instagram-image--1-2 {
  height: 50%;
}

@media (max-width: 1259px) {
  .instagram-image--1-2 {
    height: 100%;
  }
}

.t2t-line-table {
  width: 100%;
}

@media (max-width: 639px) {
  .t2t-line-table {
    display: block;
  }
}

@media (max-width: 639px) {
  .t2t-line-table tbody {
    display: block;
  }
}

@media (max-width: 639px) {
  .t2t-line-table tr {
    display: block;
    padding: 10px 0;
  }
}

.t2t-line-table th, .t2t-line-table td {
  padding: 10px 0;
  vertical-align: top;
  text-align: left;
}

@media (max-width: 639px) {
  .t2t-line-table th, .t2t-line-table td {
    display: block;
    padding: 0;
  }
}

.t2t-line-table th {
  color: #3d5360;
  font-weight: 700;
  white-space: nowrap;
  padding-right: 6px;
}

@media (max-width: 639px) {
  .t2t-line-table th {
    display: block;
    padding-right: 0;
    padding-bottom: 3px;
  }
}

.t2t-line-table__th-container {
  position: relative;
  display: block;
  padding-right: 30px;
}

@media (max-width: 639px) {
  .t2t-line-table__th-container {
    padding-right: 0;
  }
}

.t2t-line-table__th-container:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 2px;
  left: 0;
  border-bottom: 1px solid #dbdedc;
  content: '';
}

@media (max-width: 639px) {
  .t2t-line-table__th-container:before {
    display: none;
  }
}

.t2t-line-table__th-inner {
  position: relative;
  display: inline-block;
  background-color: #fff;
  padding: 0 6px 0 0;
}

@media (max-width: 639px) {
  .t2t-line-table__th-inner {
    background-color: transparent;
    padding: 0;
  }
}

.section--gallery {
  position: relative;
  color: #fff;
}

.section--gallery h2, .section--gallery .h2 {
  color: inherit;
}

.section--gallery:before {
  position: absolute;
  top: 0;
  right: -5000px;
  bottom: 0;
  left: -5000px;
  background-color: #3e3d42;
  content: '';
  z-index: -1;
}

.section--gallery .swiper-pagination-bullet {
  background: #fff;
}

.section--gallery .swiper-pagination-bullet-active {
  background: #3d5360;
}

.section--video {
  position: relative;
}

.section--video:before {
  position: absolute;
  top: 0;
  right: -5000px;
  bottom: 0;
  left: -5000px;
  background-color: #f4f4f4;
  content: '';
  z-index: -1;
}

.swiper-slider--gallery {
  margin-left: -5000px;
  margin-right: -5000px;
}

.swiper-slider--gallery .swiper-slider__inner {
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slider--gallery .swiper-button-prev {
  left: 40px;
  right: auto;
}

@media (max-width: 1259px) {
  .swiper-slider--gallery .swiper-button-prev {
    left: 25px;
  }
}

@media (max-width: 767px) {
  .swiper-slider--gallery .swiper-button-prev {
    left: 10px;
  }
}

.swiper-slider--gallery .swiper-button-next {
  right: 40px;
  left: auto;
}

@media (max-width: 1259px) {
  .swiper-slider--gallery .swiper-button-next {
    right: 25px;
  }
}

@media (max-width: 767px) {
  .swiper-slider--gallery .swiper-button-next {
    right: 10px;
  }
}

.swiper-slider--gallery .swiper-slide {
  max-width: 660px;
  min-width: 660px;
  padding: 0 30px;
}

@media (max-width: 1700px) {
  .swiper-slider--gallery .swiper-slide {
    max-width: 500px;
    min-width: 500px;
  }
}

@media (max-width: 1259px) {
  .swiper-slider--gallery .swiper-slide {
    max-width: 40vw;
    min-width: 40vw;
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .swiper-slider--gallery .swiper-slide {
    padding: 0 10px;
  }
}

.swiper-slider--gallery .swiper-slide img {
  width: 100%;
}

.swiper-slider--gallery-videos .swiper-slide {
  max-width: 1180px;
  min-width: 1180px;
}

@media (max-width: 1259px) {
  .swiper-slider--gallery-videos .swiper-slide {
    max-width: 80vw;
    min-width: 80vw;
  }
}

.swiper-button-prev--circle, .swiper-button-next--circle {
  margin-top: -37px;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.85);
  color: rgba(61, 83, 96, 0.5);
}

@media (max-width: 767px) {
  .swiper-button-prev--circle, .swiper-button-next--circle {
    margin-top: -20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.swiper-button-prev--circle .icon, .swiper-button-next--circle .icon {
  top: -1px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .swiper-button-prev--circle .icon, .swiper-button-next--circle .icon {
    width: 12px;
    height: 20px;
  }
}

.swiper-button-prev--circle .icon {
  left: -3px;
}

@media (max-width: 767px) {
  .swiper-button-prev--circle .icon {
    left: -2px;
  }
}

.swiper-button-next--circle .icon {
  left: 3px;
}

@media (max-width: 767px) {
  .swiper-button-next--circle .icon {
    left: 2px;
  }
}

.swiper-button-prev--icircle, .swiper-button-next--icircle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: rgba(243, 243, 243, 0.7);
  color: #8B989F;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: auto;
  right: auto;
  margin: -20px 0 0 !important;
}

@media (max-width: 767px) {
  .swiper-button-prev--icircle, .swiper-button-next--icircle {
    height: 24px;
    width: 24px;
    margin: -12px 0 0 !important;
  }
}

.swiper-button-prev--icircle:before, .swiper-button-next--icircle:before {
  display: none;
}

.swiper-button-prev--icircle .icon, .swiper-button-next--icircle .icon {
  width: 11px;
  height: 18px;
}

@media (max-width: 767px) {
  .swiper-button-prev--icircle .icon, .swiper-button-next--icircle .icon {
    width: 8px;
    height: 13px;
  }
}

.swiper-button-prev--icircle {
  left: 18px;
}

@media (max-width: 767px) {
  .swiper-button-prev--icircle {
    left: 8px;
  }
}

.swiper-button-prev--icircle .icon {
  left: -1px;
}

.swiper-button-next--icircle {
  right: 18px;
}

@media (max-width: 767px) {
  .swiper-button-next--icircle {
    right: 8px;
  }
}

.swiper-button-next--icircle .icon {
  left: 1px;
}

.swiper-pagination {
  margin-top: 18px;
  text-align: center;
  position: static;
  height: 8px;
}

.swiper-pagination:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .swiper-pagination {
    margin-top: 12px;
  }
}

.swiper-pagination-bullet {
  position: relative;
  vertical-align: top;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  background: #D9D9D9;
  opacity: 1;
}

@media (max-width: 767px) {
  .swiper-pagination-bullet {
    margin: 0 4px;
  }
}

.swiper-pagination-bullet:before {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: 50%;
  content: '';
}

.swiper-pagination-bullet-active {
  background: #8B989F;
}

.image-square {
  position: relative;
  display: block;
  background: center center no-repeat;
  background-size: cover;
  padding-bottom: 100%;
}

.image-square--contain {
  background-size: contain;
}

.image-square.img-to-bg {
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.image-square.img-to-bg-lazy-loaded {
  opacity: 1;
}

.image-square img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: -1;
}

.videoWrapper {
  position: relative;
  background-color: #000;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**/
.catalog-bar {
  /*margin-top: 60px;*/
  margin-left: 20px;
}

.cols-w:first-child {
  margin-top: 0;
}

.cols-w--catalog-bar {
  margin-left: -5px;
  margin-right: -5px;
}

.cols-w {
  margin-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
}

.cols--auto {
  table-layout: auto;
}

.cols {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cols-w--catalog-bar > .cols > .col {
  padding-left: 5px;
  padding-right: 5px;
}

.col--fit {
  width: 1px;
}

.col {
  display: table-cell;
  vertical-align: top;
  padding-left: 10px;
  padding-right: 10px;
}

.btn--shadowed {
  -webkit-box-shadow: 0 4px 12px rgba(70, 92, 184, 0.1);
  box-shadow: 0 4px 12px rgba(70, 92, 184, 0.1);
}

.btn--white {
  color: #60678B !important;
  background: #fff;
}

.btn--hsmall {
  min-width: 34px;
  min-height: 34px;
  font-size: 16px;
  line-height: 1.33333;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 8px;
  text-align: center;
  font-family: Montserrat, Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.btn .icon:first-child {
  margin-left: 0;
}

.btn--hsmall .icon {
  margin-left: 5px;
  margin-right: 5px;
}

.btn .icon--14 {
  font-size: 14px;
}

.btn .icon {
  -webkit-box-flex: 1;
  -ms-flex: auto 0 0px;
  flex: auto 0 0;
  font-size: 24px;
  vertical-align: middle;
  margin: -5px 10px;
}

svg:not(:root) {
  overflow: hidden;
}

svg:not(:root) {
  overflow: hidden;
}

.icon--14 {
  font-size: 14px;
}

/**/
.expand-it-container {
  position: relative;
  overflow: hidden;
  max-height: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.expand-it-container > .expand-it-inner {
  position: relative;
  /*top: -99999px;*/
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
     transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
     transition-delay: 0.4s;
}

.catalog-bar-exp-i {
  padding: 60px 0;
}

ol:first-child, ul:first-child {
  margin-top: 0;
}

.tcmenu {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  margin: 0;
}

.tcmenu__item:first-child {
  margin-top: 0;
}

ol li:first-child, ul li:first-child {
  margin-top: 0;
}

.tcmenu__item {
  position: relative;
  list-style: none;
  padding: 0 0 0 18px;
  margin: 12px 0 0;
  text-indent: 0;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

.tcmenu__item:before {
  position: absolute;
  top: 0.625em;
  left: 0;
  margin-top: -4px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #DF5030;
  content: "";
}

.tcmenu__link {
  color: inherit;
}

.tcmenu__icon {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin-left: 2px;
  line-height: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.tcmenu__icon:before {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: 50%;
  content: '';
}

.tcmenu__item.collapsed > * > .tcmenu__icon .icon {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.tcmenu__icon .icon {
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
  vertical-align: top;
  -webkit-transition: all 0.4s, color 0s;
  -o-transition: all 0.4s, color 0s;
  transition: all 0.4s, color 0s;
}

.tcmenu__icon:hover {
  color: #DF5030;
}

.tcmenu__icon .fa {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  opacity: 0;
  font-size: 0;
}

.tcmenu .tcmenu {
  margin: 0;
}

.tcmenu .tcmenu:before {
  display: block;
  height: 12px;
  content: '';
}

.tcmenu .tcmenu .tcmenu__item:before {
  background-color: #A3AFD1;
}

.tcmenu .tcmenu .tcmenu .tcmenu__item:before {
  background-color: #60678B;
}

/*

Expansible content

 */
.expand-it-container {
  position: relative;
  overflow: hidden;
  max-height: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.expand-it-container > .expand-it-inner {
  position: relative;
  /*top: -99999px;*/
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
     transition-timing-function: ease-in-out;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
     transition-delay: 0.4s;
}

.expand-it-container.overflow-visible {
  overflow: visible;
  max-height: 99999px !important;
}

.expand-it-container.active {
  max-height: 99999px;
  pointer-events: auto;
}

.expand-it-container.active > .expand-it-inner {
  top: 0;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
     transition-delay: 0s;
}

.expand-it-icon {
  top: -1px;
  -webkit-transition: all 0.3s, color 0s;
  -o-transition: all 0.3s, color 0s;
  transition: all 0.3s, color 0s;
}

.expand-it.active .expand-it-icon {
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  transform: scaleY(-1);
}

.sortbar + .bwrapper .filter {
  margin-top: -75px;
}

@media (max-width: 9999px) {
  .sortbar + .bwrapper .filter {
    margin-top: 0;
  }
}

@media (max-width: 9999px) {
  .filter {
    padding: 25px 20px;
    margin: 0 -20px;
    max-width: 340px;
  }
}

@media (max-width: 9999px) and (max-width: 1259px) {
  .filter {
    max-width: 280px;
  }
}

@media (max-width: 9999px) {
  .filter.active {
    background-color: #fff;
  }
  .filter.active .filter__content {
    display: block;
  }
  .filter.active .filter__footer {
    display: block;
  }
}

@media (max-width: 9999px) and (max-width: 767px) {
  .filter.active .filter__footer {
    display: none;
  }
}

@media (max-width: 9999px) {
  .filter.active .filter__selected-content {
    display: block;
  }
  .filter.active .filter__selected-footer {
    display: block;
  }
}

@media (max-width: 9999px) and (max-width: 767px) {
  .filter.active .filter__selected-footer {
    display: none;
  }
}

@media (max-width: 767px) {
  .filter {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    margin: 0;
    max-width: none;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    visibility: hidden;
    z-index: -1;
    -webkit-transition: all 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
    -o-transition: all 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
    transition: all 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  }
  .filter.active {
    visibility: visible;
    z-index: 20;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition-delay: 0s;
            -o-transition-delay: 0s;
       transition-delay: 0s;
  }
}

@media (max-width: 767px) {
  .filter__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px 10px;
  }
}

.filter__close {
  position: absolute;
  top: 25px;
  right: 10px;
  font-size: 18px;
  z-index: 1;
  color: #3d5360;
  display: none;
}

@media (max-width: 767px) {
  .filter__close {
    display: block;
  }
}

.filter__title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #3d5360;
  margin-top: 15px;
}

.filter__title:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .filter__title {
    font-size: 25px;
    font-weight: 700;
  }
  .filter__title--separate {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  .filter__title--separate .filter__toggle {
    display: inline-block;
  }
}

.filter__toggle {
  display: none;
  min-width: 85px;
  text-align: right;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  cursor: pointer;
}

@media (max-width: 9999px) {
  .filter__toggle {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .filter__toggle {
    display: none;
  }
}

.filter__content {
  margin-top: 25px;
}

.filter__content:first-child {
  margin-top: 0;
}

@media (max-width: 9999px) {
  .filter__content {
    display: none;
  }
}

@media (max-width: 767px) {
  .filter__content {
    display: block;
  }
}

.filter__selected {
  margin-bottom: 25px;
}

@media (max-width: 9999px) {
  .filter__selected {
    margin-bottom: 0;
  }
  .filter.active .filter__selected {
    margin-bottom: 25px;
  }
}

.filter__selected-content {
  margin-top: 25px;
}

.filter__selected-content:first-child {
  margin-top: 0;
}

@media (max-width: 9999px) {
  .filter__selected-content {
    display: none;
  }
}

.filter__selected-footer {
  margin-top: 25px;
}

.filter__selected-footer:first-child {
  margin-top: 0;
}

@media (max-width: 9999px) {
  .filter__selected-footer {
    display: none;
  }
}

.filter__selected + .filter__inner .filter__title {
  display: none;
}

@media (max-width: 767px) {
  .filter__selected + .filter__inner .filter__title {
    display: block;
  }
}

.filter__footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: none;
  padding: 15px 0;
}

.filter__footer-table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.filter__footer-cell {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px;
}

.filter__footer .btn {
  font-weight: 600;
  letter-spacing: 0.1em;
  width: 100%;
}

.filter label.labelcollapse {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #3d5360;
  width: 100%;
  padding: 3px 0;
  margin: 0;
  cursor: pointer;
}

@media (max-width: 767px) {
  .filter label.labelcollapse {
    padding-right: 25px;
  }
}

.filter label.labelcollapse.collapsed .labelcollapse__arrow .icon {
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.filter .labelcollapse__arrow {
  position: absolute;
  right: 0;
  top: 8px;
  line-height: 1;
  color: #b4b4b4;
  font-size: 13px;
  display: none;
}

.filter .labelcollapse__arrow .icon {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 767px) {
  .filter .labelcollapse__arrow {
    display: block;
  }
}

.filter .logicbox {
  border-bottom: 1px solid #eeeeee;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  width: 100%;
  padding: 16px 12px 10px 24px;
  cursor: pointer;
}

.filter .logicbox .tag-name {
  float: left;
  max-width: 155px;
  margin-left: 7px;
}

.filter .select-filter label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  width: 100%;
  padding: 10px 12px;
  cursor: pointer;
}

.filter .form-group {
  margin: 25px 0 0;
}

.filter .form-group:first-child {
  margin-top: 0;
}

.filter .form-group > i {
  display: none !important;
}

.filter .unavailable {
  color: #aaa !important;
  pointer-events: none;
}

@media (max-width: 767px) {
  .filter--selected {
    padding-bottom: 95px;
  }
}

@media (max-width: 767px) {
  .filter--selected.active .filter__footer {
    display: block;
  }
}

@media (max-width: 767px) {
  .filter--selected .filter__inner {
    bottom: 70px;
  }
}

@media (max-width: 767px) {
  .filter--selected .filter__inner > .filter__title {
    display: none;
  }
}

.filter-disabled .filterbox__inner {
  padding: 0;
}

.filterbox {
  margin-top: 15px;
}

.filterbox:first-child {
  margin-top: 0;
}

.filterbox__inner .items-collapse {
  max-height: 340px;
  overflow-y: auto;
}

.filter-disabled {
  opacity: 0.7;
}

.collection-group {
  border: none !important;
  margin-top: 8px;
}

.collection-group-name {
  font-weight: 500;
  margin-left: 0;
  text-indent: 0 !important;
}

.logicbox {
  margin-bottom: 8px !important;
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: 8px;
}

label.radio,
label.checkbox {
  position: relative;
  display: block;
  padding-left: 35px;
  margin: 8px 0 0;
  letter-spacing: 0.1em;
  min-height: 20px;
  font-size: 13px;
  line-height: 1.4;
  cursor: pointer;
}

label.radio:first-child,
label.checkbox:first-child {
  margin-top: 0;
}

label.radio span.checkbox,
label.radio span.radio,
label.checkbox span.checkbox,
label.checkbox span.radio {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #acacac;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #fff;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

label.radio span.checkbox:hover,
label.radio span.radio:hover,
label.checkbox span.checkbox:hover,
label.checkbox span.radio:hover {
  border-color: #888;
}

label.radio span.radio,
label.checkbox span.radio {
  border-radius: 50%;
}

label.radio input[type="checkbox"]:checked + span.checkbox,
label.checkbox input[type="checkbox"]:checked + span.checkbox {
  border-color: #3d5360;
}

label.radio input[type="checkbox"] + span.checkbox:after,
label.checkbox input[type="checkbox"] + span.checkbox:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #3d5360 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkJBNUIwNTYwQUE2NTExRTVCQjlDOTIzMzZDMjg5MEE5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkJBNUIwNTYxQUE2NTExRTVCQjlDOTIzMzZDMjg5MEE5Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QkE1QjA1NUVBQTY1MTFFNUJCOUM5MjMzNkMyODkwQTkiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QkE1QjA1NUZBQTY1MTFFNUJCOUM5MjMzNkMyODkwQTkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5F2mv9AAAAXUlEQVR42mL89+8fAwFQAMT+DCCFeHABEP8F4jwQJxGIlXAoAoE8sK1A4iQQP0RTXAhVlAsTAxH8QHwaiB9BFRdBFeUg2wBjgBSfAuJ32BQhK4QpPgbE2dg8BhBgAPdxxmCWhpLBAAAAAElFTkSuQmCC) center center no-repeat;
  opacity: 0;
  visibility: hidden;
  content: "";
  -webkit-transition: opacity 0.3s, visibility 0s 0.3s;
  -o-transition: opacity 0.3s, visibility 0s 0.3s;
  transition: opacity 0.3s, visibility 0s 0.3s;
}

label.radio input[type="checkbox"]:checked + span.checkbox:after,
label.checkbox input[type="checkbox"]:checked + span.checkbox:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
     transition-delay: 0s;
}

label.radio input[type="radio"] + span.radio:after,
label.checkbox input[type="radio"] + span.radio:after {
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  background-color: #3d5360;
  border-radius: 50%;
}

label.radio input[type="checkbox"],
label.radio input[type="radio"],
label.checkbox input[type="checkbox"],
label.checkbox input[type="radio"] {
  width: 0px;
  height: 0px;
  opacity: 0;
}

/*
Range Slider
*/
.irs {
  position: relative;
  display: block;
  top: 7px;
  margin-bottom: 7px;
  height: 37px;
  width: 200px;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  height: 6px;
  top: 10px;
  border: 1px solid #737373;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /*Firefox 1-3*/
  -webkit-box-sizing: border-box;
  /* Safari */
  background-image: none;
  background: #737373 !important;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  left: 0;
  width: 10%;
  height: 12px;
  background-position: 0 -30px;
}

.irs-line-mid {
  left: 10%;
  width: 80%;
  height: 12px;
  background-position: 0 0;
}

.irs-line-right {
  right: 0;
  width: 10%;
  height: 12px;
  background-position: 100% -30px;
}

.irs-diapason {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 6px;
  top: 10px;
  background-position: 0 -60px;
  background-image: none;
  /* background:#6db56d !important; */
  background: #a3e0a4 !important;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
  width: 10px;
  height: 14px;
  top: 9px;
  background-position: 0 -90px;
}

#irs-active-slider,
.irs-slider:hover {
  cursor: pointer;
}

.irs-slider.single {
  left: 10px;
}

.irs-slider.single:before {
  position: absolute;
  display: block;
  content: "";
  top: -30%;
  left: -30%;
  width: 160%;
  height: 160%;
  background: rgba(0, 0, 0, 0);
}

.irs-slider.from {
  left: 100px;
  background: url("../img/filter/mini.png") no-repeat top left;
  opacity: 1;
  margin-top: 7px;
  margin-left: -10px;
}

.irs-slider.from:hover {
  background: url("../img/filter/mini.png") no-repeat bottom left;
}

.irs-slider.from:before {
  position: absolute;
  display: block;
  content: "";
  top: -30%;
  left: -30%;
  width: 130%;
  height: 160%;
  background: rgba(0, 0, 0, 0);
}

.irs-slider.to {
  left: 300px;
  background: url("../img/filter/mini.png") no-repeat top right;
  opacity: 1;
  margin-top: 7px;
}

.irs-slider.to:hover {
  background: url("../img/filter/mini.png") no-repeat bottom right;
}

.irs-slider.to:before {
  position: absolute;
  display: block;
  content: "";
  top: -30%;
  left: 0;
  width: 130%;
  height: 160%;
  background: rgba(0, 0, 0, 0);
}

.irs-slider.last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-min,
.irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: none !important;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: #ed5565;
  border-radius: 4px;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0px;
  margin-top: -7px;
  left: 0;
  width: 1px;
  height: 6px;
  background: #d8dbdf;
}

.irs-grid-pol.small {
  height: 3px;
  margin-top: -3px;
}

.irs-grid-text {
  position: absolute;
  top: -16px;
  left: 0;
  width: 100px;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  color: #8f8e8e;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-from:after,
.irs-to:after,
.irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565;
}

.irs-diapason-green {
  height: 6px;
  top: 10px;
  background: #4cae4c !important;
  position: absolute;
  display: block;
}

.irs-diapason-silver-left,
.irs-diapason-silver-right {
  height: 6px;
  top: 10px;
  background-position: 0 -60px;
  background-image: none;
  background: #afaeae !important;
  position: absolute;
  display: block;
}

.filter-button {
  margin-top: 20px;
  text-align: center;
}

.filter-button:first-child {
  margin-top: 0;
}

.filter-button .btn {
  min-width: 200px;
}

@media (max-width: 374px) {
  .filter-button .btn {
    width: 100%;
  }
}

.selected-tags {
  margin-right: -10px;
  margin-bottom: -8px;
}

.tag {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px 8px 0;
  font-size: 13px;
  letter-spacing: 0.1em;
  font-weight: 600;
  background-color: #f4f4f4;
  color: #3d5360;
  padding: 9px 10px;
  border-radius: 5px;
}

.tag__remove {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  line-height: 21px;
  text-align: center;
  font-size: 9px;
  margin-left: 3px;
}

.tag__remove:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #3d5360;
  border-radius: 50%;
  content: '';
}

.tag__remove .icon {
  vertical-align: -0.15em;
}

.filter-float-panel {
  position: absolute;
  z-index: 10;
  padding: 12px 20px;
  font-size: 13px;
  margin-top: -25px;
  margin-left: 180px;
  text-align: center;
  background-color: #f4f4f4;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: none !important;
}

.filter-float-panel:before {
  position: absolute;
  right: 100%;
  top: 50%;
  margin-top: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 12px 0;
  border-color: transparent #f4f4f4 transparent transparent;
  content: '';
}

.filter-float-panel label {
  display: block;
  margin: 0;
}

/*

Common property classes

 */
.hidden-absolute {
  position: absolute;
  top: -99999px;
  left: 0;
  visibility: hidden;
  z-index: -1;
}

.relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}

.nounderline {
  text-decoration: none;
}

.nounderline-important {
  text-decoration: none !important;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mw250 {
  max-width: 250px;
}

.mw300 {
  max-width: 300px;
}

.mw350 {
  max-width: 350px;
}

.mw370 {
  max-width: 370px;
}

.mw400 {
  max-width: 400px;
}

.mw450 {
  max-width: 450px;
}

.mw500 {
  max-width: 500px;
}

.mw550 {
  max-width: 550px;
}

.minw180 {
  min-width: 180px;
}

.color2 {
  color: #3d5360;
}

.color5 {
  color: #79868d;
}

.color6 {
  color: #ef8fce;
}

.font-regular {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-11 {
  font-size: 11px;
}

.letter-spacing-more {
  letter-spacing: 0.1em;
}

.letter-spacing-normal {
  letter-spacing: 0.01em;
}

.cursor-pointer {
  cursor: pointer;
}
